var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6", lg: "6", sm: "12" } },
            [
              _c(
                "base-block",
                { attrs: { title: "Country properties", "header-bg": "" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-block text-left" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Name ",
                            "label-for": "block-form1-name",
                            "label-cols-md": "4",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "required", name: "name" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text", required: "" },
                                          model: {
                                            value: _vm.singleCountry.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singleCountry,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "singleCountry.name",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "ISO code",
                            "label-for": "block-form1-name",
                            "label-cols-md": "4",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "required", name: "name" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text", required: "" },
                                          model: {
                                            value: _vm.singleCountry.iso,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singleCountry,
                                                "iso",
                                                $$v
                                              )
                                            },
                                            expression: "singleCountry.iso",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "ISO3 code",
                            "label-for": "block-form1-name",
                            "label-cols-md": "4",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", required: "" },
                            model: {
                              value: _vm.singleCountry.iso3,
                              callback: function ($$v) {
                                _vm.$set(_vm.singleCountry, "iso3", $$v)
                              },
                              expression: "singleCountry.iso3",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Default Currency",
                            "label-for": "block-form-currency",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-1",
                                              name: "checkbox-1",
                                              checked:
                                                _vm.singleCountry
                                                  .productcategoryid == null
                                                  ? true
                                                  : false,
                                              "unchecked-value": "not_accepted",
                                            },
                                            on: {
                                              change: () =>
                                                (_vm.singleCountry.productcategoryid =
                                                  null),
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c(
                                "b-form-select",
                                {
                                  attrs: { id: "block-form-currency" },
                                  model: {
                                    value: _vm.singleCountry.currency,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.singleCountry,
                                        "currency",
                                        $$v
                                      )
                                    },
                                    expression: "singleCountry.currency",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null } },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.currency, function (curr, id) {
                                    return _c(
                                      "b-form-select-option",
                                      { key: id, attrs: { value: curr.id } },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(curr.id) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Region",
                            "label-for": "block-form-region",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-1",
                                              name: "checkbox-1",
                                              checked:
                                                _vm.singleCountry.regionid ==
                                                null
                                                  ? true
                                                  : false,
                                              "unchecked-value": "not_accepted",
                                            },
                                            on: {
                                              change: () =>
                                                (_vm.singleCountry.regionid =
                                                  null),
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c(
                                "b-form-select",
                                {
                                  attrs: { id: "block-form-region" },
                                  model: {
                                    value: _vm.singleCountry.regionid,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.singleCountry,
                                        "regionid",
                                        $$v
                                      )
                                    },
                                    expression: "singleCountry.regionid",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null } },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.region, function (reg, id) {
                                    return _c(
                                      "b-form-select-option",
                                      { key: id, attrs: { value: reg.id } },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(reg.name) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-class": "font-w600",
                            "label-cols-md": "4",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-row" },
                                    [
                                      _c("div", { staticClass: "mr-2" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\tStates\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "tip",
                                        attrs: { id: "states" },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "states",
                                triggers: "hover",
                                placement: "bottom",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tDoes this country need  a state or province in the postal address?\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("b-form-checkbox", {
                            attrs: {
                              switch: "",
                              size: "lg",
                              checked:
                                _vm.singleCountry.states === 1 ? true : false,
                            },
                            on: {
                              change: function ($event) {
                                _vm.singleCountry.states = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
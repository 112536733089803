var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6", lg: "6", sm: "12" } },
            [
              _c(
                "base-block",
                { attrs: { title: "Currency properties", "header-bg": "" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-block" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "ISO code ",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                            disabled: "",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "required", name: "ISO code" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value: _vm.singleCurrency.id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singleCurrency,
                                                "id",
                                                $$v
                                              )
                                            },
                                            expression: "singleCurrency.id",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-row" },
                                    [
                                      _c("div", { staticClass: "mr-2" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\tName\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "tip",
                                        attrs: { id: "name" },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "name",
                                triggers: "hover",
                                placement: "bottom",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tName of the currency as shown in the currency selector in the app\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-1",
                                              name: "checkbox-1",
                                              checked:
                                                _vm.singleCurrency.name == ""
                                                  ? true
                                                  : false,
                                              "unchecked-value": "not_accepted",
                                            },
                                            on: {
                                              change: () =>
                                                (_vm.singleCurrency.name = ""),
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: { rules: "required", name: "Name" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ classes, errors }) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "control",
                                            class: classes,
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.singleCurrency.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.singleCurrency,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "singleCurrency.name",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(errors[0])),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-row" },
                                    [
                                      _c("div", { staticClass: "mr-2" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\tSign\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "tip",
                                        attrs: { id: "sign" },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "sign",
                                triggers: "hover",
                                placement: "bottom",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tSign of the currency as shown in the currency selector in the app\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("b-form-input", {
                            attrs: { type: "text", required: "" },
                            model: {
                              value: _vm.singleCurrency.sign,
                              callback: function ($$v) {
                                _vm.$set(_vm.singleCurrency, "sign", $$v)
                              },
                              expression: "singleCurrency.sign",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-row" },
                                    [
                                      _c("div", { staticClass: "mr-2" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\tPrefix\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "tip",
                                        attrs: { id: "prefix" },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "prefix",
                                triggers: "hover",
                                placement: "bottom",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tThis will be place before any monetary data (such as product price) in the app\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-1",
                                              name: "checkbox-1",
                                              checked:
                                                _vm.singleCurrency.prefix == ""
                                                  ? true
                                                  : false,
                                              "unchecked-value": "not_accepted",
                                            },
                                            on: {
                                              change: () =>
                                                (_vm.singleCurrency.prefix =
                                                  ""),
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", required: "" },
                                model: {
                                  value: _vm.singleCurrency.prefix,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.singleCurrency, "prefix", $$v)
                                  },
                                  expression: "singleCurrency.prefix",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-row" },
                                    [
                                      _c("div", { staticClass: "mr-2" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\tSuffix\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "tip",
                                        attrs: { id: "suffix" },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "suffix",
                                triggers: "hover",
                                placement: "bottom",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tThis will be placed after any monetary data ( such as product price) in the app\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-1",
                                              name: "checkbox-1",
                                              checked:
                                                _vm.singleCurrency.suffix ==
                                                null
                                                  ? true
                                                  : false,
                                              "unchecked-value": "not_accepted",
                                            },
                                            on: {
                                              change: () =>
                                                (_vm.singleCurrency.suffix =
                                                  null),
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", required: "" },
                                model: {
                                  value: _vm.singleCurrency.suffix,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.singleCurrency, "suffix", $$v)
                                  },
                                  expression: "singleCurrency.suffix",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-row" },
                                    [
                                      _c("div", { staticClass: "mr-2" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\tSeperator\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "tip",
                                        attrs: { id: "seperator" },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "seperator",
                                triggers: "hover",
                                placement: "bottom",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tThe character used to seperate the decimals\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-1",
                                              name: "checkbox-1",
                                              checked:
                                                _vm.singleCurrency.separator ==
                                                  "." ||
                                                _vm.singleCurrency.separator ==
                                                  ","
                                                  ? true
                                                  : false,
                                              "unchecked-value": "not_accepted",
                                            },
                                            on: {
                                              change: () =>
                                                (_vm.singleCurrency.separator =
                                                  ""),
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text", required: "" },
                                model: {
                                  value: _vm.singleCurrency.separator,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.singleCurrency,
                                      "separator",
                                      $$v
                                    )
                                  },
                                  expression: "singleCurrency.separator",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "6", lg: "6", sm: "12" } },
            [
              _c(
                "base-block",
                { attrs: { title: "Region", "header-bg": "" } },
                [_c("div", { ref: "editCurrencyTable" })]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoaded, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "addregion-modal",
          attrs: { id: "region-modal", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [_vm._v("\n\t\t\tCreate New Region\n\t\t")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-block text-left" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: { label: "Name ", "label-for": "block-form1-name" },
                },
                [
                  _c("ValidationProvider", {
                    attrs: { rules: "required", name: "name" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ classes, errors }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "control", class: classes },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.regiondata.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.regiondata, "name", $$v)
                                    },
                                    expression: "regiondata.name",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(errors[0]))]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: {
                    label: "Default locale ",
                    "label-for": "block-form1-name",
                  },
                },
                [
                  _c("ValidationProvider", {
                    attrs: { rules: "alpha_dash", name: "Default Locale" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ classes, errors }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "control", class: classes },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.regiondata.locale,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.regiondata, "locale", $$v)
                                    },
                                    expression: "regiondata.locale",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(errors[0]))]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { block: "" },
              on: {
                click: function ($event) {
                  return _vm.addRegion()
                },
              },
            },
            [_vm._v("\n\t\t\tSave\n\t\t")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "base-block",
        { attrs: { title: "Existing regions", "header-bg": "" } },
        [_c("div", { ref: "regionTable" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { Vue, Component } from 'vue-property-decorator';
import BadgesTable from 'components/BadgesTable/template.vue';

@Component({
	components: {
		BadgesTable,
	},
})
export default class Badges extends Vue {
	showModal(): void {
		this.$root.$emit(
			'bv::show::modal',
			'bv-modal',
			'#btnShow',
		);
	}

	routeBadge(id: string): void {
		this.$router.push(`/badges/${id}`);
	}
}

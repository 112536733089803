import { Vue, Component } from 'vue-property-decorator';
import DiscountTable from 'components/Discounts/DiscountsTable';
import Template from './template.vue';

@Component({
	components: {
		DiscountTable,
	},
})
export default class Discount extends Vue.extend(Template) {
	protected createDiscount(): void {
		this.$router.push('/discountnew');
	}

	protected routeDiscount(id: number): void {
		this.$router.push(`/discount/${id}`);
	}
}

import {
	Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';
import Template from './template.vue';

@Component({})
export default class EditFlexGroupTable extends Vue.extend(Template) {
	@Prop({
		type: String,
		required: true,
	})
	private readonly routeId!: string;

	private singleFlexGroup = {} as DB.FlexGroupModel;

	private loaded = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected mounted(): void {
		this.getData();
	}

	@Watch('loggedIn')
	private async getData(): Promise<DB.FlexGroupModel | undefined> {
		if (this.loggedIn) {
			const parameter = new URLSearchParams({
				limit: '0',
			});
			this.isLoading = true;
			try {
				const flexGroupData = await httpClient.get<DB.FlexGroupModel>(`/api/flexgroup/${this.routeId}?${parameter}`);
				this.singleFlexGroup = flexGroupData.data;
				// eslint-disable-next-line consistent-return
				return this.singleFlexGroup;
			} catch (error: any) {
				this.$bvToast.toast(`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected editFlexGroup(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			httpClient.put<DB.FlexGroupModel>(`/api/flexgroup/${this.routeId}`,
				{ ...this.singleFlexGroup }).then(() => {
				this.$bvToast.toast('Flex Group Edited successfully',
					{
						solid: true,
						variant: 'success',
					});
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$bvToast.toast(`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
		}
		return undefined;
	}
}

<template>
	<b-tr>
		<b-td>
			<b-form-select
				id="example-select"
				v-model="data.currency"
				plain
			>
				<b-form-select-option :value="data.currency">
					{{ data.currency }}
				</b-form-select-option>
			</b-form-select>
		</b-td>
		<b-td>
			<b-form-input
				v-model="data.price"
				type="text"
				class="form-control-sm"
			/>
		</b-td>
		<b-td class="text-center">
			<b-form-select
				id="example-select"
				v-model="data.scope"
				plain
			>
				<b-form-select-option :value="data.scope">
					{{ data.scope }}
				</b-form-select-option>
			</b-form-select>
		</b-td>

		<b-td>
			<b-btn-group>
				<b-button
					size="sm"
					variant="alt-primary"
					@click="removeHandlingPrice()"
				>
					<i class="fa fa-fw fa-eraser" />
				</b-button>
			</b-btn-group>
		</b-td>
	</b-tr>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
	props: ['item'],
	data() {
		return {
			data: {
				id: this.item.id,
				currency: this.item.currency,
				price: this.item.price,
				scope: this.item.scope,
			},
		};
	},
	methods: {
		removeHandlingPrice() {
			this.$emit(
				'removeItem',
				this.data.id,
			);
		},
	},
});
</script>

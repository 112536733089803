var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6", lg: "6", sm: "12" } },
            [
              _c(
                "base-block",
                {
                  attrs: { title: "Pickup store properties", "header-bg": "" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-block" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Name",
                            "label-for": "block-form1-username",
                            "label-cols-lg": "5",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "required", name: "Name" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value: _vm.singlePickupStore.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singlePickupStore,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singlePickupStore.name",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Address 1",
                            "label-for": "block-form1-username",
                            "label-cols-lg": "5",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.singlePickupStore.address1,
                              callback: function ($$v) {
                                _vm.$set(_vm.singlePickupStore, "address1", $$v)
                              },
                              expression: "singlePickupStore.address1",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Address 2",
                            "label-for": "block-form1-username",
                            "label-cols-lg": "5",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.singlePickupStore.address2,
                              callback: function ($$v) {
                                _vm.$set(_vm.singlePickupStore, "address2", $$v)
                              },
                              expression: "singlePickupStore.address2",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Zipcode",
                            "label-for": "block-form1-username",
                            "label-cols-lg": "5",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "integer", name: "Zipcode" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singlePickupStore.zipcode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singlePickupStore,
                                                "zipcode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singlePickupStore.zipcode",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "City",
                            "label-for": "block-form1-username",
                            "label-cols-lg": "5",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "alpha", name: "city" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value: _vm.singlePickupStore.city,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singlePickupStore,
                                                "city",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singlePickupStore.city",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Country",
                            "label-for": "block-form1-username",
                            "label-cols-lg": "5",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "alpha", name: "Country" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singlePickupStore.country,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singlePickupStore,
                                                "country",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singlePickupStore.country",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "State",
                            "label-for": "block-form1-username",
                            "label-cols-lg": "5",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "alpha", name: "State" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value: _vm.singlePickupStore.state,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singlePickupStore,
                                                "state",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singlePickupStore.state",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "E-mail address",
                            "label-for": "block-form1-username",
                            "label-cols-lg": "5",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "email", name: "Email address" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value: _vm.singlePickupStore.email,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singlePickupStore,
                                                "email",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singlePickupStore.email",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Phone nr.",
                            "label-for": "block-form1-username",
                            "label-cols-lg": "5",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "integer", name: "Phone Number" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value: _vm.singlePickupStore.phone,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singlePickupStore,
                                                "phone",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singlePickupStore.phone",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Pay in store",
                            "label-class": "font-w600",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            attrs: {
                              switch: "",
                              size: "lg",
                              checked:
                                _vm.singlePickupStore.payinstore === 1
                                  ? true
                                  : false,
                            },
                            on: {
                              change: function ($event) {
                                _vm.singlePickupStore.payinstore = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
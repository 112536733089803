import Vue, { PluginObject } from 'vue';
import createAuth0Client, {
	Auth0ClientOptions,
	GetIdTokenClaimsOptions,
	GetTokenSilentlyOptions,
	GetTokenWithPopupOptions,
	LogoutOptions,
	PopupConfigOptions,
	PopupLoginOptions,
	RedirectLoginOptions,
} from '@auth0/auth0-spa-js';
import { AuthPlugin, AuthPluginData } from 'types/auth-plugin';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState(
	{},
	document.title,
	window.location.pathname,
);

let instance: AuthPlugin | Vue;

export const getInstance = (): AuthPlugin | Vue => instance;

export const useAuth0 = ({
	onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
	redirectUri = window.location.origin,
	...options
}: Auth0ClientOptions): Vue => {
	if (instance) return instance;

	instance = new Vue({
		data(): AuthPluginData {
			return {
				loading: true,
				isAuthenticated: false,
				user: {},
				auth0Client: null,
				popupOpen: false,
				error: null,
				modules: [],
			};
		},
		computed: {
			getModules() {
				return this.modules;
			},
		},
		async created() {
			this.auth0Client = await createAuth0Client({
				...options,
				client_id: options.clientId,
				redirect_uri: redirectUri,
			});

			try {
				if (
					window.location.search.includes('code=') && window.location.search.includes('state=')
				) {
					const { appState } = await this.auth0Client.handleRedirectCallback();
					this.error = null;
					onRedirectCallback(appState);
				}
			} catch (e: any) {
				this.error = e;
			}

			if (this.auth0Client) {
				this.isAuthenticated = await this.auth0Client.isAuthenticated();
				const getUser = await this.auth0Client.getUser();

				if (getUser?.app_metadata && getUser.app_metadata.modules !== undefined) {
					getUser.app_metadata.modules.forEach((module: string) => {
						this.modules.push(module);
					});

					delete getUser.app_metadata;
				}

				this.user = getUser;
			}
			this.loading = false;
		},
		methods: {
			async loginWithPopup(
				o: PopupLoginOptions | undefined,
				config: PopupConfigOptions | undefined,
			) {
				this.popupOpen = true;

				try {
					await this.auth0Client?.loginWithPopup(
						options,
						config,
					);
					this.user = await this.auth0Client?.getUser();
					this.isAuthenticated = (await this.auth0Client?.isAuthenticated()) || false;
					this.error = null;
				} catch (e: any) {
					console.error(e);
					this.error = e;
				} finally {
					this.popupOpen = false;
				}
			},
			async handleRedirectCallback() {
				this.loading = true;
				try {
					await this.auth0Client?.handleRedirectCallback();
					this.user = await this.auth0Client?.getUser();
					this.isAuthenticated = true;
					this.error = null;
				} catch (e: any) {
					this.error = e;
				} finally {
					this.loading = false;
				}
			},

			/** Authenticates the user using the redirect method */
			loginWithRedirect(o: RedirectLoginOptions | undefined) {
				return this.auth0Client
					? this.auth0Client.loginWithRedirect(o)
					: Promise.reject(new Error('Could not find auth0Client'));
			},
			/** Returns all the claims present in the ID token */
			getIdTokenClaims(o: GetIdTokenClaimsOptions | undefined) {
				return this.auth0Client
					? this.auth0Client.getIdTokenClaims(o)
					: Promise.reject(new Error('Could not find auth0Client'));
			},
			/** Returns the access token. If the token is invalid or missing, a new one is retrieved */
			getIdTokenRaw(o: GetTokenSilentlyOptions | undefined) {
				return this.auth0Client
					// eslint-disable-next-line no-underscore-dangle
					? this.auth0Client.getIdTokenClaims(o).then((claims) => claims?.__raw)
					: Promise.reject(new Error('Could not find auth0Client'));
			},
			/** Returns the access token. If the token is invalid or missing, a new one is retrieved */
			getTokenSilently(o: GetTokenSilentlyOptions | undefined) {
				return this.auth0Client
					? this.auth0Client.getTokenSilently(o)
					: Promise.reject(new Error('Could not find auth0Client'));
			},
			/** Gets the access token using a popup window */
			getTokenWithPopup(o: GetTokenWithPopupOptions | undefined) {
				return this.auth0Client
					? this.auth0Client.getTokenWithPopup(o)
					: Promise.reject(new Error('Could not find auth0Client'));
			},
			/** Logs the user out and removes their session on the authorization server */
			logout(o: LogoutOptions | undefined) {
				return this.auth0Client?.logout(o);
			},
		},
	});

	return instance;
};

export const Auth0Plugin: PluginObject<Auth0ClientOptions> = {
	install(vue, options): void {
		if (!options) {
			throw new Error('Missing required options');
		}
		Vue.prototype.$auth = useAuth0(options);
	},
};

import { Vue, Component } from 'vue-property-decorator';
import BulkDiscountTable from 'components/Discounts/BulkDiscount';
import Template from './template.vue';

@Component({
	components: {
		BulkDiscountTable,
	},
})

export default class BulkDiscount extends Vue.extend(Template) {
	protected showModal(): void {
		this.$root.$emit(
			'bv::show::modal',
			'bulkDiscount-modal',
			'#bulkDiscountShow',
		);
	}

	protected routeBulkDiscount(id: number): void {
		this.$router.push(`/discount-bulk/${id}`);
	}
}

import {
	Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { PlaceHolderModel } from 'interfaces/app';
import Template from './template.vue';

@Component({
	components: {},
})
export default class PageObjectEditTable extends Vue.extend(Template) {
	@Prop({
		type: String,
		required: true,
	})
	public readonly objectId!: string;

	private singlePageObjectEdit = {} as Record<string, DB.PageObjectModel | number | string | null>;

	private fontData = {} as DB.FontModel;

	private loaded = false;

	private placeHolders: Pick<PlaceHolderModel, 'icon'> = {
		icon: 'Choose a file',
	}

	private subObjectProperty: { source: string} = {
		source: '',
	}

	private options = ['Photo', 'text'];

	private fonts = {} as DB.FontModel;

	private alignment = ['left', 'center', 'right'];

	private visible = false;

	private spinner = false;

	private index = 0;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected mounted(): void {
		this.getSinglePageObjectAndFont();
	}

	@Watch('loggedIn')
	private async getSinglePageObjectAndFont(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const [fontResponse, singlePageObjectResponse] = await Promise.all([
					httpClient.get<DB.FontModel>(`/api/object/${this.objectId}/subobject?${parameter}`),
					httpClient.get<Record<string, DB.PageObjectModel>>(`/api/object/${this.objectId}`),
				]);
				this.fonts = fontResponse.data;
				this.singlePageObjectEdit = singlePageObjectResponse.data;
			} catch (error: any) {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected setPropertyNull(property: string): void {
		this.singlePageObjectEdit[property] = null;
	}

	protected checkPropertyNull(property: string): boolean {
		return this.singlePageObjectEdit[property] === null;
	}

	protected checkPropertyTrue(property: string): boolean {
		return this.singlePageObjectEdit[property] === 1;
	}

	protected updateProperty(property: string, event: number): void {
		this.singlePageObjectEdit[property] = event;
	}

	protected async editPageObject(): Promise<void> {
		this.isLoading = true;
		await this.editSubObject();
		return httpClient.put<DB.PageObjectModel>(
			`/api/object/${this.objectId}`,
			{ ...this.singlePageObjectEdit },
		).then(() => {
			this.$bvToast.toast(
				'Page Object Edited successfully',
				{
					solid: true,
					variant: 'success',
				},
			);
			return undefined;
		})
			.finally(() => {
				this.isLoading = false;
			})
			.catch((error) => {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});
	}

	protected editSubObject(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			return httpClient.put<DB.PageObjectPhotoModel>(
				`/api/objectphoto/${this.singlePageObjectEdit.id}`,
				{
					objectid: this.objectId,
					source: this.subObjectProperty.source || null,
				},
			).then(() => undefined)
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$bvToast.toast(
						`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
		return Promise.resolve(undefined);
	}

	protected updateIcon(): void {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.subObjectProperty.source = files.filesUploaded[0].url;
				this.placeHolders.icon = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	protected removeIcon(): void {
		this.subObjectProperty.source = '';
	}
}

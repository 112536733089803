var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%", height: "100%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12", lg: "12", sm: "12" } },
            [
              _c(
                "b-tabs",
                {
                  attrs: { card: "", "content-class": "mt-3", fill: "" },
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "All", active: "" } },
                    [
                      _c(
                        "base-block",
                        {
                          attrs: {
                            title: "Region properties",
                            "header-bg": "",
                          },
                        },
                        [
                          _vm.spinner
                            ? _c("div", { staticClass: "text-center" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spinner-grow text-primary m-5",
                                    attrs: { role: "status" },
                                  },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Loading..."),
                                    ]),
                                  ]
                                ),
                              ])
                            : _c(
                                "div",
                                { staticClass: "d-block text-center" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        label: "Name: ",
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "required",
                                          name: "name",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: { type: "text" },
                                                      model: {
                                                        value:
                                                          _vm.singleRegion.name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.singleRegion,
                                                            "name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "singleRegion.name",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        label: "Default locale ",
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "alpha_dash",
                                          name: "Default locale",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: { type: "text" },
                                                      model: {
                                                        value:
                                                          _vm.singleRegion
                                                            .locale,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.singleRegion,
                                                            "locale",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "singleRegion.locale",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Logo mobile",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("b-form-file", {
                                        ref: "logomobile",
                                        attrs: {
                                          id: "thumbnail",
                                          placeholder: _vm.placeHolders.mobile,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.updateLogoMobile.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.singleRegion.logo_mobile
                                        ? _c(
                                            "div",
                                            { staticClass: "thumbnail" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.singleRegion
                                                    .logo_mobile,
                                                  alt: "",
                                                  srcset: "",
                                                  height: "100",
                                                  width: "100",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: _vm.removeMobile,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Logo desktop",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("b-form-file", {
                                        ref: "logodesktop",
                                        attrs: {
                                          id: "thumbnail",
                                          placeholder: _vm.placeHolders.desktop,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.updateLogoDesktop.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.singleRegion.logo_desktop
                                        ? _c(
                                            "div",
                                            { staticClass: "thumbnail" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.singleRegion
                                                    .logo_desktop,
                                                  alt: "",
                                                  srcset: "",
                                                  height: "100",
                                                  width: "100",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: _vm.removeDesktop,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        label: "Creator URL: ",
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.singleRegion.url,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.singleRegion,
                                              "url",
                                              $$v
                                            )
                                          },
                                          expression: "singleRegion.url",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        label: "Homepage URL: ",
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-5",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.singleRegion
                                                              .home == null
                                                              ? true
                                                              : false,
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleRegion.home =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value: _vm.singleRegion.home,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.singleRegion,
                                                  "home",
                                                  $$v
                                                )
                                              },
                                              expression: "singleRegion.home",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Country" },
                      on: { click: () => _vm.getCountryData() },
                    },
                    [
                      _c(
                        "base-block",
                        { attrs: { title: "Country", "header-bg": "" } },
                        [_c("div", { ref: "countryTable" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Currencies" },
                      on: { click: () => _vm.getCurrencyData() },
                    },
                    [
                      _c(
                        "base-block",
                        { attrs: { title: "Currencies", "header-bg": "" } },
                        [_c("div", { ref: "currencyTable" })]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Offerings" },
                      on: { click: () => (_vm.offeringDisplay = true) },
                    },
                    [
                      _vm.offeringDisplay
                        ? _c("OfferingTable", {
                            attrs: { "route-id": _vm.routeId },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
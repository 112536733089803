var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c(
        "b-row",
        { staticClass: "d-flex justify-content-end mb-4" },
        [
          _c(
            "b-col",
            {
              staticClass: "my-1",
              attrs: { sm: "5", md: "3", "align-self": "center" },
            },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Show Records",
                    "label-for": "search-group-select",
                    "label-cols-sm": "6",
                    "label-cols-md": "4",
                    "label-cols-lg": "4",
                    "label-align-sm": "right",
                    "label-size": "sm",
                  },
                },
                [
                  _c(
                    "b-form-select",
                    {
                      attrs: { id: "search-group-select", size: "sm" },
                      on: { change: _vm.applyFilters },
                      model: {
                        value: _vm.searchFilters.priceType,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchFilters, "priceType", $$v)
                        },
                        expression: "searchFilters.priceType",
                      },
                    },
                    [
                      _c(
                        "b-form-select-option",
                        { attrs: { value: "all", selected: "" } },
                        [_vm._v("\n\t\t\t\t\t\tAll\n\t\t\t\t\t")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-select-option",
                        { attrs: { value: "discounted" } },
                        [_vm._v("\n\t\t\t\t\t\tDiscounted only\n\t\t\t\t\t")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-select-option",
                        { attrs: { value: "missing" } },
                        [_vm._v("\n\t\t\t\t\t\tMissing prices\n\t\t\t\t\t")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "my-1",
              attrs: { sm: "5", md: "3", "align-self": "center" },
            },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Product Group",
                    "label-for": "search-group-select",
                    "label-cols-sm": "6",
                    "label-cols-md": "4",
                    "label-cols-lg": "4",
                    "label-align-sm": "right",
                    "label-size": "sm",
                  },
                },
                [
                  _c(
                    "b-form-select",
                    {
                      attrs: { id: "search-group-select", size: "sm" },
                      on: { change: _vm.applyFilters },
                      model: {
                        value: _vm.searchFilters.productGroup,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchFilters, "productGroup", $$v)
                        },
                        expression: "searchFilters.productGroup",
                      },
                    },
                    [
                      _c("b-form-select-option", { attrs: { value: "0" } }, [
                        _vm._v("\n\t\t\t\t\t\tAll\n\t\t\t\t\t"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.productGroupModels, function (productGroup) {
                        return _c(
                          "b-form-select-option",
                          {
                            key: productGroup.id,
                            attrs: { value: productGroup.id },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(productGroup.name) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { md: "12" } },
        [
          _c("b-card", { attrs: { "no-body": "" } }, [
            _c("div", { ref: "tableContainer" }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { Vue, Component } from 'vue-property-decorator';
import PhotoFrameTable from 'components/Themes/PhotoframeTable';
import Template from './template.vue';

@Component({
	components: {
		PhotoFrameTable,
	},
})
export default class Photoframe extends Vue.extend(Template) {
	protected showModal(): void {
		this.$root.$emit(
			'bv::show::modal',
			'photoframe-modal',
			'#photoframeShow',
		);
	}

	protected routePhotoframe(id: string): void {
		this.$router.push(`/photoframe/${id}`);
	}

	protected openInNewTab(id: string): void {
		const route = this.$router.resolve({ name: 'editPhotoframe', params: { id } });
		window.open(
			route.href,
			'_blank',
		);
	}
}

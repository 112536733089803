var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.classContainer, attrs: { id: "page-container" } },
    [
      _c("div", {
        class: { show: _vm.$store.state.settings.pageLoader },
        attrs: { id: "page-loader" },
      }),
      _vm._v(" "),
      _vm.$store.state.layout.sideOverlay &&
      _vm.$store.state.settings.pageOverlay
        ? _c("div", {
            attrs: { id: "page-overlay" },
            on: {
              click: () => _vm.$store.commit("sideOverlay", { mode: "close" }),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.layout.sideOverlay
        ? _c(
            "base-side-overlay",
            { class: _vm.layoutClasses.sideOverlay },
            [_vm._t("side-overlay")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.layout.sidebar
        ? _c(
            "base-sidebar",
            { class: _vm.layoutClasses.sidebar },
            [_vm._t("sidebar")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.layout.header
        ? _c(
            "base-header",
            { class: _vm.layoutClasses.header },
            [_vm._t("header")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "main-container" } },
        [
          _vm._t("content"),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
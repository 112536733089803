import {
	Vue, Component, Prop,
} from 'vue-property-decorator';
import { httpClient } from 'utils/http';
import * as DB from 'interfaces/database';

@Component({})
export default class UpsellTableData extends Vue {
	private editing = false;

	@Prop()
	up!: DB.UpsellModel;

	private data = {
		id: this.up.id,
		offeringid: this.up.offeringid,
		description: this.up.description,
		optional: this.up.optional,
		peritem: this.up.peritem,
		autoinclude: this.up.autoinclude,
		externalid: this.up.externalid,
	};

	editUpsell(): void {
		this.editing = true;
	}

	cancelUpsell(): void {
		this.editing = false;
	}

	saveUpsell(): void {
		this.editing = false;
		httpClient.put<DB.UpsellModel>(
			`/api/upsell/${this.data.id}`,
			this.data,
		).then(() => null).catch((err) => {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}

	deleteUpsell(): void {
		// delete upsell using sweet alert
		this.$emit(
			'deleteUpsell',
			this.data.id,
		);
	}
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "priceInput" },
    [
      _vm.prefix
        ? _c("span", { staticClass: "currency" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.prefix) + "\n\t"),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.notDecimalNumber
        ? _c("b-form-input", {
            ref: "input",
            staticClass: "inputField",
            class: [
              (_vm.sameAsCatalogueBase || _vm.sameAsCataloguePage) &&
                "same-as-catalogue",
            ],
            attrs: {
              placeholder: "",
              value: _vm.loading ? "" : _vm.internalValue,
              readonly: _vm.readonly,
            },
            on: {
              blur: _vm.onBlur,
              change: _vm.onChange,
              focus: _vm.onFocus,
              input: _vm.onInput,
            },
          })
        : _c("b-form-input", {
            ref: "input",
            staticClass: "inputField",
            attrs: {
              placeholder: "",
              value: _vm.internalValue,
              readonly: _vm.readonly,
            },
            on: {
              blur: _vm.onBlur,
              change: _vm.onChange,
              focus: _vm.onFocus,
              input: _vm.onInput,
            },
          }),
      _vm._v(" "),
      _c("div", [
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "fa fa-spinner fa-spin",
        }),
        _vm._v(" "),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.success,
              expression: "success",
            },
          ],
          staticClass: "fa fa-check",
        }),
        _vm._v(" "),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.error,
              expression: "error",
            },
          ],
          staticClass: "fa fa-exclamation-triangle",
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "autofill", on: { mousedown: _vm.onMouseDown } },
        [_c("i", { staticClass: "fa fa-circle" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
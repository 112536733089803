var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data && _vm.offerings
    ? _c("div", [
        _vm._v(
          "\n\t" +
            _vm._s(_vm.offerings[_vm.data.offeringid].name) +
            " " +
            _vm._s(_vm.offerings[_vm.data.offeringid].description) +
            "\n"
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
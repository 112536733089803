import {
	Vue, Component, Ref, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';
import { ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import Template from './template.vue';

@Component({
	components: {},
})
export default class ExternalSources extends Vue.extend(Template) {
	@Ref('externalSourcesTable')
	private allTableReference!: HTMLDivElement;

	@Prop({
		type: String,
		required: true,
	})
	private readonly routeId!: string;

	private table?: Tabulator;

	private columnDefs: ColumnDefinitionExtended[] = []

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'source',
				title: 'Source',
			},
			{
				title: 'Data',
				field: 'data',
			},
		];
	}

	protected mounted(): void {
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeDestroy() {
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this?.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.allTableReference,
			{
				height: '40vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async getData(): Promise<void> {
		const id = this.routeId;
		const parameter = new URLSearchParams({
			limit: '0',
			orderby: 'id DESC',
			where: JSON.stringify({
				userid: parseInt(
					id,
					10,
				),
			}),
		});

		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.ExternalUserModel[]>(`/api/userexternal?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.table?.clearAlert();
		}
	}
}

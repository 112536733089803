var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-block",
    {
      ref: "salesPri",
      attrs: { rounded: "" },
      scopedSlots: _vm._u([
        {
          key: "options",
          fn: function () {
            return undefined
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _vm.getLoadingStatus
        ? _c("div", { staticClass: "text-center" }, [
            _c(
              "div",
              {
                staticClass: "spinner-grow text-primary m-5",
                attrs: { role: "status" },
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            ),
          ])
        : _c(
            "b-table-simple",
            {
              attrs: {
                responsive: "",
                bordered: "",
                striped: "",
                "table-class": "table-vcenter",
              },
            },
            [
              _c(
                "b-thead",
                [
                  _c(
                    "b-tr",
                    [
                      _c(
                        "b-th",
                        {
                          staticStyle: { width: "10%" },
                          attrs: { rowspan: "3" },
                        },
                        [_vm._v("\n\t\t\t\t\tCurrency\n\t\t\t\t")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-th",
                        {
                          staticClass: "text-center",
                          attrs: {
                            colspan:
                              _vm.editedOffering.maxpages ===
                              _vm.editedOffering.minpages
                                ? 2
                                : 4,
                          },
                        },
                        [_vm._v("\n\t\t\t\t\tPrice (in cents)\n\t\t\t\t")]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-th",
                        {
                          staticClass: "text-center",
                          staticStyle: { "min-width": "80px", width: "80px" },
                          attrs: { rowspan: "3" },
                        },
                        [_vm._v("\n\t\t\t\t\tEdit\n\t\t\t\t")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.editedOffering.maxpages !== _vm.editedOffering.minpages
                    ? _c(
                        "b-tr",
                        [
                          _c(
                            "b-td",
                            {
                              staticClass: "text-center",
                              attrs: { colspan: "2" },
                            },
                            [_vm._v("\n\t\t\t\t\tBase Price\n\t\t\t\t")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-td",
                            {
                              staticClass: "text-center",
                              attrs: { colspan: "2" },
                            },
                            [_vm._v("\n\t\t\t\t\tExtra Pages\n\t\t\t\t")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-tr",
                    [
                      _c("b-td", [_vm._v("From")]),
                      _vm._v(" "),
                      _c("b-td", [_vm._v("Current")]),
                      _vm._v(" "),
                      _vm.editedOffering.maxpages !==
                      _vm.editedOffering.minpages
                        ? _c("b-td", [_vm._v("\n\t\t\t\t\tFrom\n\t\t\t\t")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.editedOffering.maxpages !==
                      _vm.editedOffering.minpages
                        ? _c("b-td", [_vm._v("\n\t\t\t\t\tCurrent\n\t\t\t\t")])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.regionCurrencyForSalesGetter, function (salesP) {
                return _c(
                  "b-tbody",
                  { key: salesP.id },
                  [
                    _c("SalesTable", {
                      attrs: {
                        "sales-p": salesP,
                        "edited-offering": _vm.editedOffering,
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
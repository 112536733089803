var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-tr",
    [
      _c(
        "b-td",
        [
          _c(
            "b-form-select",
            {
              attrs: { id: "example-select", plain: "" },
              model: {
                value: _vm.data.currency,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "currency", $$v)
                },
                expression: "data.currency",
              },
            },
            [
              _c(
                "b-form-select-option",
                { attrs: { value: _vm.data.currency } },
                [_vm._v("\n\t\t\t\t" + _vm._s(_vm.data.currency) + "\n\t\t\t")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-td",
        [
          _c("b-form-input", {
            staticClass: "form-control-sm",
            attrs: { type: "text" },
            model: {
              value: _vm.data.price,
              callback: function ($$v) {
                _vm.$set(_vm.data, "price", $$v)
              },
              expression: "data.price",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-td",
        { staticClass: "text-center" },
        [
          _c(
            "b-form-select",
            {
              attrs: { id: "example-select", plain: "" },
              model: {
                value: _vm.data.scope,
                callback: function ($$v) {
                  _vm.$set(_vm.data, "scope", $$v)
                },
                expression: "data.scope",
              },
            },
            [
              _c("b-form-select-option", { attrs: { value: _vm.data.scope } }, [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.data.scope) + "\n\t\t\t"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-td",
        [
          _c(
            "b-btn-group",
            [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "alt-primary" },
                  on: {
                    click: function ($event) {
                      return _vm.removeHandlingPrice()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-fw fa-eraser" })]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {
	Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';
import Template from './template.vue';

@Component({})
export default class EditPickupStoresTable extends Vue.extend(Template) {
	@Prop({
		type: String,
		required: true,
	})
	public readonly routeId!: string;

	private singlePickupStore = {} as DB.AddressModel;

	private loaded = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected mounted(): void {
		this.getSinglePickupStore();
	}

	@Watch('loggedIn')
	private async getSinglePickupStore(): Promise<DB.AddressModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<DB.AddressModel>(`/api/branch/${this.routeId}`);
				this.singlePickupStore = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singlePickupStore;
			} catch (error: any) {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected editPickupStores(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			httpClient.put<DB.AddressModel>(
				`/api/branch/${this.routeId}`,
				{ ...this.singlePickupStore },
			).then(() => {
				this.$bvToast.toast(
					'Pickup Store Edited successfully',
					{
						solid: true,
						variant: 'success',
					},
				);
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$bvToast.toast(
						`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
		return undefined;
	}
}

import { Vue, Component, Ref } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { BModal } from 'bootstrap-vue';
import mitt from 'mitt';
import Swal from 'sweetalert2';
import {
	TabEvent, TranslationsResponse, LanguageOptionsModel, Translation, Translations,
} from 'interfaces/app';
import { httpClient } from 'utils/http';
import {
	CellComponent, CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';

const eventBus = mitt<TabEvent<DB.PDPModel>>();
@Component({
	components: {
		TabulatorBtn,
	},
})
export default class PdpTable extends Vue {
	@Ref('addpdp-modal') readonly modal!: BModal;

	@Ref('pdpTable')
	private readonly tableReference!: HTMLDivElement;

	private name = '';

	private columnDefs: ColumnDefinitionExtended[] = [];

	private data = [];

	private table?: Tabulator;

	private rowData: DB.PDPModel[] = [];

	private isLoaded = false;

	private translations = {} as Record<number, Translation>;

	private language = '';

	private languageOptions: LanguageOptionsModel[] = [];

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	private newLineFormatter = (cell: CellComponent) => {
		if (cell.getValue()) {
			return cell.getValue().split('\n').join('<br/>');
		}
		return '';
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'ID',
				headerFilter: true,
				width: 100,
			},
			{
				title: 'Details',
				field: 'name',
				headerFilter: true,
			},
			{
				title: 'Header*',
				field: 'header',
				editor: 'input',
				cellEdited: this.handleCellEdit,
			},
			{
				title: 'Description*',
				field: 'description',
				formatter: this.newLineFormatter,
				editor: 'textarea',
				cellEdited: this.handleCellEdit,
			},
			{
				title: 'About*',
				field: 'about',
				editor: 'textarea',
				cellEdited: this.handleCellEdit,
				formatter: this.newLineFormatter,
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.BadgeModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	protected mounted(): void {
		eventBus.on(
			'deleteItem',
			this.deletePdpItem,
		);
		eventBus.on(
			'editItem',
			this.editPdpItem,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.getLanguageData();
	}

	protected beforeDestroy(): void {
		eventBus.off(
			'deleteItem',
			this.deletePdpItem,
		);
		eventBus.off(
			'editItem',
			this.editPdpItem,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.destroy();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private handleCellEdit(cell: CellComponent): void {
		const row = cell.getRow().getData();
		const field = cell.getField();
		const newValue = cell.getValue();
		row[field] = newValue;
		//  Find and Update 'rowData' state
		const foundRow = this.rowData.find((r) => r.id === row.id);

		if (foundRow) {
			(foundRow as unknown as Record<string, string | number>)[field] = newValue;
		}
		this.saveUpdatedData([cell]).catch((error) => {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}

	// eslint-disable-next-line class-methods-use-this
	private formatCellsAsTranslations(cells: CellComponent[]): Record<number, Translation> {
		return cells.reduce((result, cell) => {
			const row = cell.getRow().getData();
			return {
				...result,
				[row.id]: {
					header: row.header ? row.header : '',
					about: row.about ? row.about : '',
					description: row.description ? row.description : '',
				},
			};
		},
		{});
	}

	private async saveUpdatedData(cells: CellComponent[]): Promise<void> {
		const bundle: Record<number, Translation> = this.formatCellsAsTranslations(cells);

		try {
			await httpClient.put('/api/translation',
				{
					namespace: 'pdps',
					language: this.language,
					bundle,
				});
			this.$bvToast.toast(
				'Successfully updated the data for the table',
				{
					solid: true,
					variant: 'success',
				},
			);
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		}
	}

	private async fetchTranslations(language: string): Promise<TranslationsResponse> {
		const parameter = new URLSearchParams({
			ns: 'pdps',
			lng: language,
			limit: '0',
		});

		try {
			const { data } = await httpClient.get<TranslationsResponse>(`/api/translation/multi?${parameter}`);
			return data;
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
			return Promise.reject(error);
		}
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.PDPModel[]>(`/api/pdp?${parameter}`);
			this.rowData = data;
			this.table?.setData(this.rowData);
		} catch (err: any) {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.table?.clearAlert();
		}
	}

	private async getLanguageData(): Promise<void> {
		try {
			const languageResponse = await httpClient.get<DB.LanguageModel[]>('/api/language?limit=0');
			this.languageOptions = languageResponse.data.map((language) => ({ value: language.id, text: language.name }));
			// get the default language
			const defaultLang = languageResponse.data.find((language: DB.LanguageModel) => language.default);
			// set the language to the default language or the first language in the list
			this.language = defaultLang ? defaultLang.id : languageResponse.data[0].id;
			// get the translations for the default language
			const translationsData = await this.fetchTranslations(this.language);

			this.translations = translationsData[this.language].pdps as Translations;

			// set the translations for the default language
			this.rowData = this.rowData.map((row) => {
				if (this.translations[row.id] !== undefined) {
					return {
						...row,
						header: this.translations[row.id].header,
						about: this.translations[row.id].about,
						description: this.translations[row.id].description,
					};
				}
				return row;
			});

			this.table?.updateData(this.rowData);
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		}
	}

	protected async translateTableData(data: string): Promise<void> {
		const translations = await this.fetchTranslations(data);
		this.translations = translations[this.language].pdps as Translations;

		this.rowData = this.rowData.map((row) => {
			if (this.translations[row.id] !== undefined) {
				return {
					...row,
					header: this.translations[row.id].header,
					about: this.translations[row.id].about,
					description: this.translations[row.id].description,
				};
			}
			return row;
		});

		this.table?.updateData(this.rowData);
	}

	protected addPdp(): void {
		// add a Pdp
		this.isLoaded = true;
		httpClient.post(
			'/api/pdp',
			{ name: this.name },
		).then((res) => {
			this.modal.hide();
			this.$emit(
				'routePdp',
				res.data.id,
			);
			this.name = '';

			return undefined;
		}).finally(() => {
			this.isLoaded = false;
		})
			.catch((err) => {
				this.$bvToast.toast(
					`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});
	}

	private editPdpItem(data: TabEvent<DB.PDPModel>['editItem']): void {
		this.$emit(
			'routePdp',
			data.id,
		);
	}

	private deletePdpItem(data: TabEvent<DB.PDPModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading');
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete(`/api/pdp/${data.id}`).then(() => {
					this.$bvToast.toast(
						'Item Deleted',
						{
							solid: true,
							variant: 'success',
						},
					);
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}
}

import {
	Vue, Component, Watch, Ref, Prop,
} from 'vue-property-decorator';
import Swal from 'sweetalert2';
import * as DB from 'interfaces/database';
import { BModal } from 'bootstrap-vue';
import { PickerOptions, PickerResponse } from 'filestack-js';
import mitt from 'mitt';
import { TabEvent } from 'interfaces/app';
import {
	TabulatorFull as Tabulator, CellComponentExtended, ColumnDefinitionExtended,
} from 'tabulator-tables';
import TabulatorImage from 'components/Tabulator/TabulatorImage';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import { httpClient } from 'utils/http';
import { createInstance } from 'utils/vue';
import OfferingTable from './OfferingTable';
import BadgePdpTable from './BadgePdp';
import BadgeProductCategoryTable from './BadgeProductCategory';

const eventBus = mitt<TabEvent<DB.BadgeImageModel>>();
@Component({
	components: {
		TabulatorImage,
		TabulatorBtn,
		OfferingTable,
		BadgePdpTable,
		BadgeProductCategoryTable,
	},
})
export default class EditBadgesTable extends Vue {
	@Ref('image-modal') private readonly imageModal!: BModal;

	@Ref('image-edit-modal') private readonly imageEditModal!: BModal;

	@Ref('badgeImage')
	private readonly tableReference!: HTMLDivElement;

	@Prop({
		type: String,
		required: true,
	})
	public readonly routeId!: string

	private badgeImage : DB.BadgeImageModel[] = [];

	private badge : DB.BadgeModel[] = [];

	private singleBadge: Record<string, DB.BadgeModel> = {};

	private language: DB.LanguageModel[] = [];

	private region: DB.RegionModel[] = [];

	private loaded = false;

	private table?: Tabulator;

	private id: DB.BadgeImageModel['id'] | null = null;

	private badgeid: DB.BadgeModel['id'] | null = null;

	private langid: DB.LanguageModel['id'] | null = null;

	private regionid: DB.RegionModel['id'] | null = null;

	private editBadgesDetails = {} as DB.BadgeImageModel

	private display = {
		offeringDisplay: false,
		badgePdpDisplay: false,
		productCategoryDisplay: false,
	}

	private name = '';

	private totalRows = 1;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private perPage = 20;

	private spinner = false;

	private imagePreview: string | null = null;

	private index = 0;

	private isLoading = false;

	private placeHolders = {
		thumbnailPlaceholder: 'No file chosen',
		editBadgesPlaceholder: 'No file chosen',
	}

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	private beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id', title: 'ID',
			},
			{
				title: 'Icon',
				field: 'url',
				formatter: (cell: CellComponentExtended<DB.BadgeImageModel>) => {
					const instance = createInstance({
						component: TabulatorImage,
						props: {
							size: 100,
							url: cell.getValue(),
						},
					});
					instance.$mount();
					return (instance.$el as HTMLElement);
				},
			},
			{
				title: 'Region',
				field: 'regionid',
			},
			{
				title: 'Language',
				field: 'languageid',
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.BadgeImageModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	private mounted(): void {
		this.getSingleBadge();
		eventBus.on(
			'deleteItem',
			this.deleteBadge,
		);
		eventBus.on(
			'editItem',
			this.editBadge,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			() => {
				this.getData();
			},
		);
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private beforeDestroy(): void {
		eventBus.off(
			'deleteItem',
			this.deleteBadge,
		);
		eventBus.off(
			'editItem',
			this.editBadge,
		);
		this.table?.destroy();
	}

	private async getData(): Promise<void> {
		const route = this.routeId;
		const parameter = new URLSearchParams({
			where: JSON.stringify({ badgeid: route }),
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.BadgeImageModel[]>(`/api/badgeimage?${parameter}`);
			// set table data
			this.table?.setData(data);
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.table?.clearAlert();
		}
	}

	private async getSingleBadge(): Promise<Record<string, DB.BadgeModel> | undefined> {
		if (!this.loggedIn) {
			return undefined;
		}

		// set the loading state
		this.isLoading = true;

		try {
			const resp = await httpClient.get<Record<string, DB.BadgeModel>>(`/api/badge/${this.routeId}`);
			this.singleBadge = resp.data;

			return this.singleBadge;
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.isLoading = false;
		}

		return undefined;
	}

	protected showImageModal(): void {
		this.fetchData();
		this.imageModal.show();
	}

	protected addBadgeImage(): Promise<void> | undefined {
		if (!this.loggedIn) {
			return undefined;
		}

		// set the loading state
		this.spinner = true;

		httpClient.post<DB.BadgeImageModel>(
			'/api/badgeimage',
			{
				badgeid: parseInt(
					this.routeId,
					10,
				),
				languageid: this.langid,
				regionid: this.regionid,
				url: this.imagePreview,
			},
		)
			.then((res) => {
				this.badgeid = null;
				this.langid = null;
				this.regionid = null;
				this.imagePreview = '';
				this.placeHolders.thumbnailPlaceholder = '';
				this.table?.redraw();
				this.table?.addData([res.data]);
				this.$bvToast.toast(
					'badge Image added successfully',
					{
						solid: true,
						variant: 'success',
					},
				);
				return undefined;
			})
			.finally(() => {
				this.spinner = false;
				this.imageModal.hide();
			})
			.catch((error) => {
				this.badgeid = null;
				this.langid = null;
				this.regionid = null;
				this.imagePreview = '';
				this.placeHolders.thumbnailPlaceholder = '';
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});

		return undefined;
	}

	private editBadge(data: TabEvent<DB.BadgeImageModel>['editItem']): void {
		this.fetchData();
		this.imageEditModal.show();
		if (data.id !== undefined) {
			this.editBadgesDetails.id = data.id;
		}
		this.editBadgesDetails.badgeid = data.params.badgeid;
		this.editBadgesDetails.languageid = data.params.languageid;
		this.editBadgesDetails.regionid = data.params.regionid;
		this.editBadgesDetails.url = data.params.url;
	}

	protected editBadgeImage(): Promise<void> {
		if (!this.loggedIn) {
			return Promise.resolve();
		}

		// set the loading state
		this.spinner = true;
		return httpClient.put<DB.BadgeImageModel>(
			`/api/badgeimage/${this.id}`,
			{
				badgeid: this.badgeid,
				languageid: this.langid,
				regionid: this.regionid,
				url: this.imagePreview,
			},
		)
			.then(() => {
				this.table?.redraw();
				this.editBadgesDetails.badgeid = null;
				this.editBadgesDetails.languageid = null;
				this.editBadgesDetails.regionid = null;
				this.editBadgesDetails.url = '';
				this.$bvToast.toast(
					'badge Edited successfully',
					{
						solid: true,
						variant: 'success',
					},
				);
				return undefined;
			})
			.finally(() => {
				this.spinner = false;
				this.imageEditModal.hide();
			})
			.catch((error) => {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});
	}

	private deleteBadge(data: TabEvent<DB.BadgeImageModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading');
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete(`/api/badgeimage/${data.params.id}`).then(() => {
					this.$bvToast.toast(
						'Item Deleted',
						{
							solid: true,
							variant: 'success',
						},
					);
					this.table?.replaceData();
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}

	@Watch('loggedIn')
	async fetchData(): Promise<void> {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				limit: '0',
			});
			// set the loading state
			this.spinner = true;
			Promise.all([
				httpClient.get<DB.LanguageModel[]>(`/api/language?${params}`),
				httpClient.get<DB.RegionModel[]>(`/api/region?${params}`),
			])
				.then(([languageResponse, regionResponse]) => {
					this.language = languageResponse.data;
					this.region = regionResponse.data;
					return null;
				}).finally(() => {
					this.spinner = false;
				})
				.catch((error) => {
					this.$bvToast.toast(
						`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
		return undefined;
	}

	//  update image preview
	async updateImagePreview(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.imagePreview = files.filesUploaded[0].url;
				this.placeHolders.thumbnailPlaceholder = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	//  update image preview
	async updateEditPreview(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.editBadgesDetails.url = files.filesUploaded[0].url;
				this.placeHolders.editBadgesPlaceholder = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	// remove the image preview
	protected removeImage(): void {
		this.imagePreview = '';
		this.placeHolders.thumbnailPlaceholder = '';
	}

	protected removeEditImage(): void {
		this.editBadgesDetails.url = '';
		this.placeHolders.editBadgesPlaceholder = '';
	}
}

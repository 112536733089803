import {
	Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { httpClient } from 'utils/http';
import Template from './template.vue';

@Component({})
export default class EditPhotoframeTable extends Vue.extend(Template) {
	@Prop({
		type: String,
		required: true,
	})
	public readonly routeId!: string;

	private singlePhotoframe: Record<string, DB.BorderImageModel | string | null> = {};

	private loaded = false;

	private placeHolders: { icon: string } = {
		icon: 'Choose a file',
	}

	private visible = false;

	private spinner = false;

	private index = 0;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected mounted(): void {
		this.getSingleCategory();
	}

	@Watch('loggedIn')
	private async getSingleCategory(): Promise<void> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get(`/api/borderimage/${this.routeId}`);
				this.singlePhotoframe = resp.data;
			} catch (error: any) {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			} finally {
				this.isLoading = false;
			}
		}
	}

	protected isPropertyNull(property: string): boolean {
		return this.singlePhotoframe[property] === null;
	}

	protected setPropertyNull(property: string): void {
		this.singlePhotoframe[property] = null;
	}

	protected async editPhotoframe(): Promise<void> {
		if (this.loggedIn) {
			this.isLoading = true;
			try {
				await httpClient.put<DB.BorderImageModel>(
					`/api/borderimage/${this.routeId}`,
					{ ...this.singlePhotoframe },
				);
				this.$bvToast.toast(
					'Photo frame Edited successfully',
					{
						solid: true,
						variant: 'success',
					},
				);
			} catch (error: any) {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			} finally {
				this.isLoading = false;
			}
		}
	}

	protected updateIcon(): void {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singlePhotoframe.source = files.filesUploaded[0].url;
				this.placeHolders.icon = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	protected removeIcon(): void {
		this.singlePhotoframe.source = '';
	}
}

import {
	Vue, Component, Watch, Ref,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import mitt from 'mitt';
import { TabEvent } from 'interfaces/app';
import { httpClient } from 'utils/http';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.RegionModel>>();
@Component({
	components: {
		TabulatorSwitchBox,
	},
})
export default class EditCurrencyTable extends Vue.extend(Template) {
	@Ref('editCurrencyTable')
	private readonly tableReference!: HTMLDivElement;

	private singleCurrency = {} as DB.CurrencyModel;

	private region: DB.RegionModel[] = [];

	private productCategory: DB.ProductCategoryModel[] = [];

	private language: DB.LanguageModel[] = [];

	private visible = false;

	private spinner = false;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private checkRegion = {} as Record<string, DB.RegionCurrencyModel>;

	private checkedValue = false;

	private index = 0;

	private table?: Tabulator;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	beforeMount(): void {
		this.columnDefs = [
			{
				field: 'name',
				title: 'Region',
			},
			{
				title: 'Available In region?',
				formatter: (cell: CellComponentExtended<DB.RegionModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							data,
							eventBus,
							checked: Boolean(data && this.checkRegion[data.id]),
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	protected mounted(): void {
		this.getSingleCurrency();
		this.getOfferingRegion();
		eventBus.on(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeDestroy(): void {
		eventBus.off(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '45vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.RegionModel[]>(`/api/region?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.table?.clearAlert();
		}
	}

	private switchBoxChanged(data: TabEvent<DB.RegionModel>['switchBoxChanged']): void {
		this.table?.alert('Loading...');
		if (data.event) {
			httpClient
				.post(
					'/api/regioncurrency',
					{
						regionid: data.params.id,
						currencyid: this.$route.params.id,
					},
				)
				.then((response) => {
					this.checkRegion[response.data.regionid] = response.data;
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		} else {
			httpClient
				.delete(`/api/regioncurrency/${this.checkRegion[data.params.id].id}`)
				.then(() => {
					delete this.checkRegion[data.params.id];
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
	}

	@Watch('loggedIn')
	private async getSingleCurrency(): Promise<DB.CurrencyModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<DB.CurrencyModel>(`/api/currency/${this.$route.params.id}`);
				this.singleCurrency = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singleCurrency;
			} catch (error: any) {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	@Watch('loggedIn')
	private async getOfferingRegion(): Promise<void> {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				where: JSON.stringify({ currencyid: this.$route.params.id }),
				limit: '0',
			});

			try {
				const resp = await httpClient.get<DB.RegionCurrencyModel[]>(`/api/regioncurrency?${params}`);
				this.checkRegion = resp.data.reduce(
					(obj, item) => ({
						...obj,
						[item.regionid]: item,
					}),
					{},
				);
			} catch (error: any) {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			}
		}
		return undefined;
	}

	protected editCurrency(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			httpClient.put<DB.Model3DModel>(
				`/api/currency/${this.$route.params.id}`,
				{ ...this.singleCurrency },
			).then(() => {
				this.$bvToast.toast(
					'Currency Edited successfully',
					{
						solid: true,
						variant: 'success',
					},
				);
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$bvToast.toast(
						`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
		return undefined;
	}
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-block",
    {
      attrs: { title: "Themes Pages Table", "header-bg": "" },
      scopedSlots: _vm._u([
        {
          key: "options",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  attrs: { type: "submit", size: "sm", variant: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showNewPage = !_vm.showNewPage
                    },
                  },
                },
                [_vm._v("\n\t\t\tCreate new page\n\t\t")]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { type: "submit", size: "sm", variant: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showExistingPage = !_vm.showExistingPage
                    },
                  },
                },
                [_vm._v("\n\t\t\tAdd existing Pages\n\t\t")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showNewPage,
                  expression: "showNewPage",
                },
              ],
              attrs: { lg: "12" },
            },
            [
              _c(
                "div",
                { staticClass: "d-block text-left" },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "form-group",
                      attrs: { label: "Name", "label-for": "block-form1-name" },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.layoutdata.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.layoutdata, "name", $$v)
                          },
                          expression: "layoutdata.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-3 mb-2",
                      on: {
                        click: function ($event) {
                          return _vm.addPage()
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\tAdd page\n\t\t\t\t")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showExistingPage,
                  expression: "showExistingPage",
                },
              ],
              attrs: { lg: "12" },
            },
            [
              _c(
                "div",
                { staticClass: "d-block text-left" },
                [
                  _c(
                    "b-form-group",
                    { attrs: { "label-for": "example-select" } },
                    [
                      _c(
                        "b-form-select",
                        {
                          staticClass: "mb-2",
                          attrs: { id: "example-select", plain: "" },
                          model: {
                            value: _vm.ExistingPagesData,
                            callback: function ($$v) {
                              _vm.ExistingPagesData = $$v
                            },
                            expression: "ExistingPagesData",
                          },
                        },
                        [
                          _c("b-form-select-option", { attrs: { value: {} } }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t"
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.layoutTemplate, function (layout, id) {
                            return _c(
                              "b-form-select-option",
                              { key: id, attrs: { value: layout } },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(layout.name) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-3 mb-2",
                      on: {
                        click: function ($event) {
                          return _vm.addPages()
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\tAdd Page(s)\n\t\t\t\t")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { ref: "layoutTemplateTable" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
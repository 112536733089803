import { Emitter } from 'mitt';
import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import {
	ModelWithId,
	TabEvent,
} from 'interfaces/app';
import DB from 'interfaces/database';
import Template from './template.vue';

export interface ButtonType {
	eventName: string,
	id: string,
	className: string,
}
@Component({})
export default class TabulatorBtn<DataType extends DB & ModelWithId> extends Vue.extend(Template) {
	@Prop({
		type: Object,
		required: true,
	})
	public readonly data!: DataType

	@Prop({
		type: Array,
		required: true,
	})
	public readonly buttons!: ButtonType[];

	@Prop({
		type: Object,
		required: true,
	})
	public readonly eventBus!: Emitter<TabEvent<any>>

	onButtonClick(eventName: string): void {
		this.eventBus.emit(
			eventName as keyof TabEvent<DataType>,
			{
				id: this.data.id,
				params: this.data,
			},
		);
	}
}

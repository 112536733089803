var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "5", lg: "5", sm: "12" } },
            [_c("UserPropertiesBox")],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "7", lg: "7", sm: "12" } },
            [_c("ExternalSources", { attrs: { "route-id": _vm.routeId } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12", lg: "12", sm: "12" } },
            [
              _c(
                "b-tabs",
                {
                  staticClass: "block",
                  attrs: {
                    "nav-class": "nav-tabs-alt",
                    "content-class": "block-content",
                  },
                },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "PAID ORDERS", active: "" } },
                    [_c("PaidOrders", { attrs: { "route-id": _vm.routeId } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "SHOPPING CART ITEMS" },
                      on: {
                        click: () => (_vm.sendUserRequest.shoppingCart = true),
                      },
                    },
                    [
                      _vm.sendUserRequest.shoppingCart
                        ? _c("ShoppingCartItems", {
                            attrs: {
                              shoppingcart: _vm.sendUserRequest.shoppingCart,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "PRODUCTS" },
                      on: {
                        click: () => (_vm.sendUserRequest.products = true),
                      },
                    },
                    [
                      _vm.sendUserRequest.products
                        ? _c("Products", {
                            attrs: {
                              "product-request": _vm.sendUserRequest.products,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "PHOTOS" },
                      on: { click: () => (_vm.sendUserRequest.photos = true) },
                    },
                    [
                      _vm.sendUserRequest.photos
                        ? _c("Photos", {
                            attrs: {
                              "photos-request": _vm.sendUserRequest.photos,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "USER CREDIT" },
                      on: {
                        click: () => (_vm.sendUserRequest.userCredit = true),
                      },
                    },
                    [
                      _vm.sendUserRequest.userCredit
                        ? _c("UserCredit", {
                            attrs: {
                              "route-id": _vm.routeId,
                              "user-credit-request":
                                _vm.sendUserRequest.userCredit,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "USER EMAIL LOGS" },
                      on: {
                        click: () => (_vm.sendUserRequest.userEmailLog = true),
                      },
                    },
                    [
                      _vm.sendUserRequest.userEmailLog
                        ? _c("UserEmailLog", {
                            attrs: {
                              "route-id": _vm.routeId,
                              "user-email-request":
                                _vm.sendUserRequest.userEmailLog,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "USER REFERRALS" },
                      on: {
                        click: () => (_vm.sendUserRequest.userReferrals = true),
                      },
                    },
                    [
                      _vm.sendUserRequest.userReferrals
                        ? _c("UserReferrals", {
                            attrs: {
                              "route-id": _vm.routeId,
                              "user-referral-request":
                                _vm.sendUserRequest.userReferrals,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoaded, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "base-block",
        { attrs: { title: "Existing Country", "header-bg": "" } },
        [_c("div", { ref: "countryTable" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
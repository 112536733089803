var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6", lg: "6", sm: "12" } },
            [
              _c(
                "base-block",
                { attrs: { title: "Cross Sell properties", "header-bg": "" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-block text-center" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Name: ",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "control" },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.singleCrossSell.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.singleCrossSell, "name", $$v)
                                  },
                                  expression: "singleCrossSell.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Preview Theme",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              rules: "alpha_dash",
                              name: "Preview Theme",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleCrossSell.previewTheme,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singleCrossSell,
                                                "previewTheme",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "singleCrossSell.previewTheme",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Cross Sell ID:",
                            "label-for": "example-select",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-4",
                                              name: "checkbox-2",
                                              checked:
                                                _vm.singleCrossSell
                                                  .crosssellid == null
                                                  ? true
                                                  : false,
                                              "unchecked-value": "not_accepted",
                                            },
                                            on: {
                                              change: () =>
                                                (_vm.singleCrossSell.crosssellid =
                                                  null),
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c(
                                "b-form-select",
                                {
                                  attrs: { id: "example-select", plain: "" },
                                  model: {
                                    value: _vm.singleCrossSell.crosssellid,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.singleCrossSell,
                                        "crosssellid",
                                        $$v
                                      )
                                    },
                                    expression: "singleCrossSell.crosssellid",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null } },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tPlease select an Option\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.offerings, function (off, id) {
                                    return _c(
                                      "b-form-select-option",
                                      { key: id, attrs: { value: off.id } },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(off.id) +
                                            " | " +
                                            _vm._s(off.size) +
                                            " - " +
                                            _vm._s(off.description) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Offering ID:",
                            "label-for": "example-select1",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-5",
                                              name: "checkbox-6",
                                              checked:
                                                _vm.singleCrossSell
                                                  .offeringid == null
                                                  ? true
                                                  : false,
                                              "unchecked-value": "not_accepted",
                                            },
                                            on: {
                                              change: () =>
                                                (_vm.singleCrossSell.offeringid =
                                                  null),
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c(
                                "b-form-select",
                                {
                                  attrs: { id: "example-select8", plain: "" },
                                  model: {
                                    value: _vm.singleCrossSell.offeringid,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.singleCrossSell,
                                        "offeringid",
                                        $$v
                                      )
                                    },
                                    expression: "singleCrossSell.offeringid",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null } },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tPlease select an Option\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.offerings, function (off, id) {
                                    return _c(
                                      "b-form-select-option",
                                      { key: id, attrs: { value: off.id } },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(off.id) +
                                            " | " +
                                            _vm._s(off.size) +
                                            " - " +
                                            _vm._s(off.description) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "show offering options",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              rules: "alpha_dash",
                              name: "offering options",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "b-input-group-text",
                                                        {
                                                          staticClass:
                                                            "input-group-text-alt",
                                                          staticStyle: {
                                                            cursor: "pointer",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              attrs: {
                                                                id: "checkbox-6",
                                                                name: "checkbox-7",
                                                                checked:
                                                                  _vm
                                                                    .singleCrossSell
                                                                    .showOfferingOption ==
                                                                  null
                                                                    ? true
                                                                    : false,
                                                                "unchecked-value":
                                                                  "not_accepted",
                                                              },
                                                              on: {
                                                                change: () =>
                                                                  (_vm.singleCrossSell.showOfferingOption =
                                                                    null),
                                                              },
                                                            }
                                                          ),
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: { type: "text" },
                                              model: {
                                                value:
                                                  _vm.singleCrossSell
                                                    .showOfferingOption,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.singleCrossSell,
                                                    "showOfferingOption",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "singleCrossSell.showOfferingOption",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Theme ID:",
                            "label-for": "example-select1",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-6",
                                              name: "checkbox-7",
                                              checked:
                                                _vm.singleCrossSell.themeid ==
                                                null
                                                  ? true
                                                  : false,
                                              "unchecked-value": "not_accepted",
                                            },
                                            on: {
                                              change: () =>
                                                (_vm.singleCrossSell.themeid =
                                                  null),
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c(
                                "b-form-select",
                                {
                                  attrs: { id: "example-select8", plain: "" },
                                  model: {
                                    value: _vm.singleCrossSell.themeid,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.singleCrossSell,
                                        "themeid",
                                        $$v
                                      )
                                    },
                                    expression: "singleCrossSell.themeid",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null } },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tPlease select an Option\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(_vm.theme, function (them, id) {
                                    return _c(
                                      "b-form-select-option",
                                      { key: id, attrs: { value: them.id } },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(them.id) +
                                            " | " +
                                            _vm._s(them.name) +
                                            " - " +
                                            _vm._s(them.description) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Serial number",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "integer", name: "Serial number" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleCrossSell.serialnumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singleCrossSell,
                                                "serialnumber",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "singleCrossSell.serialnumber",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Photo Quantity",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "integer", name: "Photo Quantity" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleCrossSell.photoQuantity,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singleCrossSell,
                                                "photoQuantity",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "singleCrossSell.photoQuantity",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Published",
                            "label-class": "font-w600",
                            "label-cols-lg": "3",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "pubCheckbox",
                            attrs: {
                              switch: "",
                              size: "lg",
                              checked:
                                _vm.singleCrossSell.published === 1
                                  ? true
                                  : false,
                            },
                            on: {
                              change: function ($event) {
                                _vm.singleCrossSell.published = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
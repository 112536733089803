import { Vue, Component } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { MatchedOffering } from 'interfaces/app';
import { httpClient } from 'utils/http';
import Template from './template.vue';

@Component({})
export default class CreateDiscountTable extends Vue.extend(Template) {
	private currencies: DB.CurrencyModel[] = [];

	private shippingOptions: string[] =
	[
		'No free shipping',
		'Free economy shipping',
		'Free shipping with tracking',
		'Free express shipping',
	];

	private shipping: string | null = null;

	private createDiscountData = {} as DB.DiscountModel;

	private tracker: DB.AffiliateModel[] = [];

	private selectedOfferings: number[] = [];

	private productGroup: DB.ProductGroupModel[] = [];

	private isLoading = false;

	private referralProgram: DB.ReferralProgramModel[] = [];

	private offering: DB.OfferingModel[] = [];

	private currentLastValue = 0;

	private relative = false;

	private absolute = true;

	private currency: DB.CurrencyModel[] = [];

	private currencyModel: string | null = null;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				limit: '0',
			});
			Promise.all([
				httpClient.get<DB.CurrencyModel[]>(`/api/currency?${params}`),
				httpClient.get<DB.AffiliateModel[]>('/api/affiliate'),
				httpClient.get<DB.ReferralProgramModel[]>(`/api/referralprogram?${params}&fields=id,name`),
				httpClient.get<DB.ProductGroupModel[]>(`/api/productgroup?${params}`),
				httpClient.get<DB.OfferingModel[]>(`/api/offering?${params}`),
			])
				.then(([currencyResponse, AffiateResponse, ReferrelResponse, ProductResponse, offeringResponse]) => {
					this.currency = currencyResponse.data;
					this.tracker = AffiateResponse.data;
					this.referralProgram = ReferrelResponse.data;
					this.productGroup = ProductResponse.data;
					this.offering = offeringResponse.data;
					return null;
				})
				.catch((error) => {
					this.$bvToast.toast(
						`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
	}

	protected ToggleDiscount(discount: string): void {
		if (discount === 'relative') {
			this.relative = true;
			this.absolute = false;
		} else if (discount === 'absolute') {
			this.absolute = true;
			this.relative = false;
		}
	}

	protected async createDiscount(): Promise<void> {
		this.isLoading = true;
		if (this.$auth.user) {
			try {
				await httpClient.post<DB.DiscountModel>(
					'/api/discount',
					{
						...this.createDiscountData,
						currency: this.currencyModel,
						created_by: this.$auth?.user.name,
					},
				);
			} catch (err: any) {
				this.$bvToast.toast(
					`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			} finally {
				this.isLoading = false;
			}
		}
	}

	protected toggleOfferings(_value: number[], event: InputEvent): void {
		const isChecked = (event.target as HTMLInputElement).checked;
		const currentValue = (event.target as HTMLInputElement).value;
		const matchedOffering: MatchedOffering[] = [];
		// get all the offering details that match the groupid's in selectedOfferings
		this.offering.forEach((offering) => {
			if (this.selectedOfferings.includes(offering.groupid)) {
				matchedOffering.push({
					offeringid: offering.id,
					groupid: offering.groupid,
					variantid: offering.variantid,
					id: offering.id,
					typeid: offering.typeid,
				});
			}
		});
		this.currentLastValue = Number(currentValue);
		if (isChecked) {
			httpClient.post(
				'/api/bulkoffering',
				matchedOffering,
			).then(() => undefined).catch((err) => {
				this.$bvToast.toast(
					`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});
		} else {
			const ids: number[] = [];
			this.offering.filter((offering) => {
				if (offering.groupid === this.currentLastValue) {
					ids.push(offering.id);
				}
				return offering.id;
			});
			const params = new URLSearchParams({
				where: JSON.stringify({
					id: ids,
				}),
			});
			httpClient.delete(`/api/bulkoffering?${params}`).then(() => undefined).catch((err) => {
				this.$bvToast.toast(
					`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});
		}
	}
}

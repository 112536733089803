import {
	Component,
	Ref,
	Vue,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import mitt from 'mitt';
import {
	TabEvent, TranslationsResponse, LanguageOptionsModel, Translation, Translations,
} from 'interfaces/app';
import {
	CellComponent, CellComponentExtended, CellEventCallback, ColumnComponent, ColumnDefinitionExtended, RowComponent, TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { httpClient } from 'utils/http';
import 'vue-loading-overlay/dist/vue-loading.css';
import PriceDetails from 'components/Tabulator/PriceDetails';
import { createInstance } from 'utils/vue';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import { PickerOptions, PickerResponse } from 'filestack-js';
import {
	drawAllBorders, multiSelectCell, removeAllBorders, singleSelectCell,
} from 'utils/tabulator-select';
import { formatCellsToClipboard, formatClipboardToCells } from 'utils/tabulator-copy-paste';
import debounce from 'utils/debounce';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.OfferingModel>>();
@Component({
	components: {
		PriceDetails,
	},
})
export default class OfferingsTable extends Vue.extend(Template) {
	@Ref('offeringTable')
	private allTableReference!: HTMLDivElement;

	protected isLoading = false;

	private table?: Tabulator;

	private offeringModels: DB.OfferingModel[] = [];

	private productGroupModels: DB.ProductGroupModel[] = [];

	private rowData: DB.OfferingModel[] = [];

	private selectedProductGroup: DB.ProductGroupModel | null = null;

	private translations = {} as Record<number, Translation>;

	private language = '';

	private languageOptions: LanguageOptionsModel[] = [];

	private selectedRows: Array<RowComponent> = [];

	private selectedColumns: Array<ColumnComponent> = [];

	private selectedCell: CellComponent | null = null;

	private editableColumns = new Set(['header', 'description', 'thumbnail']);

	private isSaving = false;

	private cellsToUpdate: Map<CellComponent, string> = new Map();

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected mounted(): void {
		eventBus.on(
			'editItem',
			this.editOffering,
		);
		document.addEventListener(
			'keydown',
			this.handleKeyDown,
		);
		document.addEventListener(
			'copy',
			this.handleCopyEvent,
		);
		document.addEventListener(
			'paste',
			this.handlePasteEvent,
		);

		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			() => {
				this.getAllData();
			},
		);
	}

	private async getAllData(): Promise<void> {
		this.isLoading = true;
		try {
			await Promise.all([this.fetchProductGroups(), this.getLanguageData()]);
			this.getData();
		} catch (err: any) {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.isLoading = false;
		}
	}

	private async onSelectProductGroup() {
		this.deSelectCell();
		this.getData();
	}

	private async fetchProductGroups() {
		const productGroupsResponse = await httpClient.get<DB.ProductGroupModel[]>(`/api/productgroup?${new URLSearchParams({
			limit: '0',
		})}`);
		this.productGroupModels = productGroupsResponse.data;
		[this.selectedProductGroup] = this.productGroupModels;
	}

	protected beforeDestroy() {
		eventBus.off(
			'editItem',
			this.editOffering,
		);
		this?.table?.destroy();
		document.removeEventListener(
			'keydown',
			this.handleKeyDown,
		);
		document.removeEventListener(
			'copy',
			this.handleCopyEvent,
		);
		document.removeEventListener(
			'paste',
			this.handlePasteEvent,
		);
	}

	private handleMultiSelectEvent(event: KeyboardEvent): void {
		if (!this.selectedCell) return;

		const cellToSelect = multiSelectCell(
			event.key,
			this.selectedCell,
			this.selectedRows,
			this.selectedColumns,
			this.editableColumns,
		);

		if (cellToSelect) this.selectedCell = cellToSelect;
	}

	private handleSingleSelectEvent(event: KeyboardEvent): void {
		if (!this.selectedCell) return;
		const cellToSelect = singleSelectCell(
			event.key,
			this.selectedCell,
			this.selectedRows,
			this.selectedColumns,
			this.editableColumns,
		);

		if (cellToSelect) {
			this.selectedCell = cellToSelect;
			this.selectedRows = [cellToSelect.getRow()];
			this.selectedColumns = [cellToSelect.getColumn()];
		}
	}

	private handlePasteEvent(event: ClipboardEvent): void {
		const text = event.clipboardData?.getData('text/plain');
		if (text) {
			this.updateSelectedCells(formatClipboardToCells(text));
		}
	}

	private handleCopyEvent(event: ClipboardEvent): void {
		event.preventDefault();
		const text = formatCellsToClipboard(
			this.selectedRows,
			this.selectedColumns,
		);
		event.clipboardData?.setData(
			'text/plain',
			text,
		);
	}

	private handleKeyDown(event: KeyboardEvent): void {
		if (event.shiftKey) {
			return this.handleMultiSelectEvent(event);
		}

		if (event.key === 'Delete') {
			// Make sure the user is not editing the text for one of the header filter values
			const inFocus = document.querySelector('textarea:focus') ?? document.querySelector('input:focus');
			if (!inFocus) {
				this.updateSelectedCells(
					this.selectedRows.map(
						() => this.selectedColumns.map(() => ''),
					),
				);
			}
		}

		return this.handleSingleSelectEvent(event);
	}

	private newLineFormatter = (cell: CellComponent) => {
		if (cell.getValue()) {
			return cell.getValue().split('\n').join('<br/>');
		}
		return '';
	}

	private tableInitialization() {
		this.table = new Tabulator(
			this.allTableReference,
			{
				height: '80vh',
				layout: 'fitColumns',
				// create a context menu to open row on a new tab
				rowContextMenu: [
					{
						label: 'Open in new tab',
						action: (e, row) => {
							const data = row.getData();
							this.$emit(
								'openTab',
								data.id,
							);
						},
					},
				],
				columns: [
					{
						title: 'offeringModel',
						field: 'offeringModel',
						visible: false,
						mutatorData: (val, data) => this.offeringModels.find(
							(model) => model.id === data.offeringid,
						),
					},
					{
						field: 'id',
						title: 'ID',
						headerFilter: true,
						width: 110,
					},
					{
						title: 'Details',
						field: 'info',
						headerFilter: 'input',
						headerFilterFunc(
							headerValue: string,
							rowValue,
							rowdata: DB.OfferingModel,
						) {
							const searchText = headerValue.toUpperCase();
							if (rowdata.externalid && rowdata.externalid.toUpperCase().indexOf(searchText) !== -1) {
								return true;
							}
							if (rowdata.name && rowdata.name.toUpperCase().indexOf(searchText) !== -1) {
								return true;
							}
							if (rowdata.description && rowdata.description.toUpperCase().indexOf(searchText) !== -1) {
								return true;
							}
							if (rowdata.size && rowdata.size.toUpperCase().indexOf(searchText) !== -1) {
								return true;
							}
							if (rowdata.variantname && rowdata.variantname.toUpperCase().indexOf(searchText) !== -1) {
								return true;
							}

							return false;
						},
						formatter(cell: CellComponentExtended<DB.OfferingModel>) {
							const instance = createInstance({
								component: PriceDetails,
								props: {
									data: cell.getData(),
								},
							});
							instance.$mount();
							return (instance.$el as HTMLElement);
						},
					},
					{
						title: 'Name*',
						field: 'header',
						editor: 'input',
						headerFilter: true,
						editable: () => false,
						cellClick: (_, cell) => this.selectCell(cell),
						cellDblClick: (_, cell) => this.editCell(cell),
						cellEdited: this.handleCellEdit,
					},
					{
						title: 'Description*',
						field: 'description',
						headerFilter: true,
						formatter: this.newLineFormatter,
						editor: 'textarea',
						editable: () => false,
						cellClick: (_, cell) => this.selectCell(cell),
						cellDblClick: (_, cell) => this.editCell(cell),
						cellEdited: this.handleCellEdit,
					},
					{
						title: 'Variant*',
						field: 'variantname',
						headerFilter: true,
						formatter: this.newLineFormatter,
						editor: 'textarea',
						editable: () => false,
						cellClick: (_, cell) => this.selectCell(cell),
						cellDblClick: (_, cell) => this.editCell(cell),
						cellEdited: this.handleCellEdit,
					},
					{

						title: 'Image*',
						field: 'thumbnail',
						formatter: 'image',
						formatterParams: {
							height: '50px',
						},
						editable: () => false,
						cellClick: (_, cell) => this.selectCell(cell),
						cellDblClick: this.updateImage,
					},
					{
						title: 'Upscaling',
						field: 'applyUpscaling',
						headerFilter: 'tickCross',
						headerFilterParams: {
							tristate: true,
						},
						formatter: 'tickCross',
						width: 100,
						headerHozAlign: 'center',
						hozAlign: 'center',
						mutatorData: (val, data) => data.applyUpscaling,
					},
					{
						title: 'Enhancement',
						field: 'applyEnhancement',
						headerFilter: 'tickCross',
						headerFilterParams: {
							tristate: true,
						},
						formatter: 'tickCross',
						width: 100,
						headerHozAlign: 'center',
						hozAlign: 'center',
						mutatorData: (val, data) => data.applyEnhancement,
					},
					{
						title: 'In stock',
						field: 'instock',
						headerFilter: 'tickCross',
						headerFilterParams: {
							tristate: true,
						},
						formatter: 'tickCross',
						width: 100,
						headerHozAlign: 'center',
						hozAlign: 'center',
						mutatorData: (val, data) => data.instock,
					},
					{
						title: 'Actions',
						width: 120,
						formatter: (cell: CellComponentExtended<DB.OfferingModel>) => {
							const instance = createInstance({
								component: TabulatorBtn,
								props: {
									data: cell.getData(),
									buttons: [
										{
											id: 'edit',
											eventName: 'editItem',
											className: 'fa-edit',
										},
									],
									eventBus,
								},
							});
							instance.$mount();
							return (instance.$el as HTMLElement);
						},
					},
				] as ColumnDefinitionExtended[],
			},
		);
	}

	private updateRowData(cell: CellComponent): void {
		const row = cell.getRow().getData();
		const field = cell.getField();
		const newValue = cell.getValue();

		row[field] = newValue;

		//  Find and Update 'rowData' state
		const foundRow = this.rowData.find((r) => r.id === row.id);

		if (foundRow) {
			(foundRow as unknown as Record<string, string | number>)[field] = newValue;
		}
	}

	private handleCellEdit(cell: CellComponent): void {
		this.updateRowData(cell);
		this.cellsToUpdate.set(cell,
			cell.getValue());
		this.saveUpdatedData().catch((error) => {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}

	// eslint-disable-next-line class-methods-use-this
	private formatCellsAsTranslations(cells: CellComponent[]): Record<number, Translation> {
		return cells.reduce((result, cell) => {
			const row = cell.getRow().getData();
			return {
				...result,
				[row.id]: {
					name: row.header ? row.header : '',
					lines: row.description ? row.description.split('\n') : null,
					variantname: row.variantname || '',
				},
			};
		},
		{});
	}

	private saveUpdatedDataDebounce = debounce((): Promise<void> => this.saveUpdatedData(),
		100);

	// eslint-disable-next-line consistent-return
	private async saveUpdatedData(): Promise<void> {
		if (this.isSaving) {
			return this.saveUpdatedDataDebounce();
		}

		this.isSaving = true;

		const currentCellsToUpdate = new Map(this.cellsToUpdate);
		this.cellsToUpdate = new Map();
		const bundle: Record<number, Translation> = this.formatCellsAsTranslations([...currentCellsToUpdate.keys()]);

		try {
			await httpClient.put('/api/translation',
				{
					namespace: 'offerings',
					language: this.language,
					bundle,
				});
			this.$bvToast.toast(
				'Successfully updated the data for the table',
				{
					solid: true,
					variant: 'success',
				},
			);
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.isSaving = false;
		}
	}

	private async fetchTranslations(language: string): Promise<TranslationsResponse> {
		const parameter = new URLSearchParams({
			ns: 'offerings',
			lng: language,
			limit: '0',
		});

		try {
			const { data } = await httpClient.get<TranslationsResponse>(`/api/translation/multi?${parameter}`);
			return data;
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
			return Promise.reject(error);
		}
	}

	private async getLanguageData(): Promise<void> {
		const languageResponse = await httpClient.get<DB.LanguageModel[]>(`/api/language?${new URLSearchParams({
			limit: '0',
		})}`);

		this.languageOptions = languageResponse.data.map((language) => ({ value: language.id, text: language.name }));
		// get the default language
		const defaultLang = languageResponse.data.find((language: DB.LanguageModel) => language.default);
		// set the language to the default language or the first language in the list
		this.language = defaultLang ? defaultLang.id : languageResponse.data[0].id;
	}

	private async getData(): Promise<void> {
		this.table?.alert('Loading');
		try {
			const offeringResponse = await httpClient.get<DB.OfferingModel[]>(`/api/offering?${new URLSearchParams({
				limit: '0',
				orderby: 'groupid,typeid,variantid',
				where: JSON.stringify({
					groupid: this.selectedProductGroup?.id ?? this.productGroupModels[0].id,
				}),
			})}`);
			this.offeringModels = offeringResponse.data;
			this.rowData = this.offeringModels;

			// get the translations for the default language
			const translationsData = await this.fetchTranslations(this.language);

			this.translations = translationsData[this.language]?.offerings as Translations;

			// set the translations for the default language
			this.rowData = this.rowData.map((row) => {
				if (this.translations?.[row.id] !== undefined) {
					return {
						...row,
						header: this.translations[row.id].name,
						description: this.translations[row.id].lines?.join('\n'),
						variantname: this.translations[row.id].variantname || '',
					};
				}
				return row;
			});
			this.table?.setData(this.rowData);
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.table?.clearAlert();
		}
	}

	protected async translateTableData(data: string): Promise<void> {
		this.isLoading = true;

		const translations = await this.fetchTranslations(data);
		this.translations = translations[this.language]?.offerings as Translations;

		this.rowData = this.rowData.map((row) => {
			if (this.translations?.[row.id] !== undefined) {
				return {
					...row,
					header: this.translations[row.id].name,
					description: this.translations[row.id].lines?.join('\n'),
					variantname: this.translations[row.id].variantname || '',
				};
			}
			return row;
		});

		this.table?.updateData(this.rowData);

		this.isLoading = false;
	}

	private editOffering(data: TabEvent<DB.OfferingModel>['editItem']): void {
		this.$emit(
			'routeOffering',
			data.id,
		);
	}

	async uploadCellImage(cell: CellComponent) {
		const data = cell.getData() as DB.OfferingModel;
		const image = cell.getValue();
		try {
			await httpClient.put(
				`/api/offering/${data.id}`,
				{
					thumbnail: image,
				},
			);
			this.$bvToast.toast(
				'Successfully updated the data for the table',
				{
					solid: true,
					variant: 'success',
				},
			);
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		}
	}

	private async updateImage(_: CellEventCallback, cell: CellComponent): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				const image = files.filesUploaded[0].url;
				cell.setValue(image);
				this.uploadCellImage(cell);
			},
		};
		this.$client.picker(options).open();
	}

	private selectCell(cell: CellComponent) {
		this.deSelectCell();
		this.selectedRows = [cell.getRow()];
		this.selectedColumns = [cell.getColumn()];

		drawAllBorders(cell);
		this.selectedCell = cell;
	}

	private deSelectCell() {
		this.selectedRows.forEach((row) => {
			this.selectedColumns.forEach((col) => {
				removeAllBorders(row.getCell(col));
			});
		});
		this.selectedRows = [];
		this.selectedColumns = [];
		this.selectedCell = null;
	}

	private editCell(cell: CellComponent) {
		this.deSelectCell();
		cell.edit(true);
	}

	private async updateSelectedCells(values: string[][]) {
		let i = 0;
		this.selectedRows.forEach((row) => {
			let j = 0;
			this.selectedColumns.forEach((col) => {
				const newValue = values[i][j];
				j += 1;
				const cell = row.getCell(col);
				cell.setValue(newValue);
				this.updateRowData(cell);
				if (col.getField() === 'thumbnail') {
					this.uploadCellImage(cell);
				} else {
					this.cellsToUpdate.set(
						cell,
						newValue,
					);
				}
			});
			i += 1;
		});

		this.saveUpdatedData().catch((error) => {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}
}

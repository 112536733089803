import { Vue, Component, Ref } from 'vue-property-decorator';
import EditTemplateStateEdit from 'components/Themes/TemplateTable/TemplateStateEdit';
import Template from './template.vue';

interface TemplateDataModel {
	data: {
		data: {
			id: number,
			type: string,
		},
		id: number
	}
	id: number,
}

@Component({
	components: {
		EditTemplateStateEdit,
	},
})
export default class EditTemplateState extends Vue.extend(Template) {
	@Ref('editTemplateStateEdit')
	private readonly template!: HTMLFormElement;

	private templateStateId = this.$route.params.id;

	protected updateTemplateStateEdit(): void {
		this.template.editTemplate();
	}

	protected routeTemplateState({ data }: TemplateDataModel): void {
		const query = data.data.type;
		this.$router.replace({
			path: `/template/${this.$route.params.templateid}/state/${this.$route.params.id}/position/${data.data.id}`,
			params: {
				id: this.$route.params.id,
				templateid: this.$route.params.templateid,
			},
			query: { type: `${query}` },
		});
	}
}

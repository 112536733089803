import { Vue, Component } from 'vue-property-decorator';
import DiscountVoucherTable from 'components/Discounts/DiscountVoucher';
import Template from './template.vue';

@Component({
	components: {
		DiscountVoucherTable,
	},
})

export default class BulkDiscount extends Vue.extend(Template) {}

import { Vue, Component } from 'vue-property-decorator';
import Model2dTable from 'components/Model2dTable/template.vue';

@Component({
	components: {
		Model2dTable,
	},
})
export default class Model2d extends Vue {
	showModal(): void {
		this.$root.$emit(
			'bv::show::modal',
			'model2d-modal',
			'#model2dShow',
		);
	}

	routeModel2d(id: string): void {
		this.$router.push(`/model2d/${id}`);
	}
}

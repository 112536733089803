import { Vue, Component, Ref } from 'vue-property-decorator';
import CreateDiscountTable from 'components/Discounts/DiscountsTable/CreateDiscountTable';
import Template from './template.vue';

@Component({
	components: {
		CreateDiscountTable,
	},
})
export default class CreateDiscount extends Vue.extend(Template) {
	@Ref('createDiscountTable')
	public readonly createDisc!: HTMLFormElement;

	protected createNewDiscount(): void {
		this.createDisc.createDiscount();
	}
}

import {
	Vue, Component, Watch, Ref, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { httpClient, creatorDomain } from '../../utils/http';

@Component({
	components: {
	},
})
export default class OrderItemTable extends Vue {
	@Ref('orderitemStatusTable')
	private allTableReference!: HTMLDivElement;

	@Prop({
		type: String,
		required: true,
	})
	private readonly orderItemId!: string;

	private spinner = false;

	private totalRows = 1;

	private currentPage = 1;

	private perPage = 10;

	private isLoading = false;

	private orderStatus: DB.OrderItemStatusModel[] = [];

	private orderItem = {} as DB.OrderItemModel

	private offeringData = {} as DB.OfferingModel;

	private table?: Tabulator;

	private domain = creatorDomain;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'status',
				title: 'Status',
				headerFilter: true,
			},
			{
				field: 'trackingcode',
				title: 'Tracking Code',
				headerFilter: true,
			},
			{
				field: 'trackinglink',
				title: 'Tracking Link',
				headerFilter: true,
			},
			{
				field: 'carrier',
				title: 'Carrier',
				headerFilter: true,
			},
			{
				field: 'shipdate',
				title: 'Date',
				headerFilter: true,
			},
			{
				title: 'Earliest deliverydate',
				field: 'deliverydate_earliest',
				headerFilter: true,
			},
			{
				field: 'deliverydate_latest',
				title: 'Latest deliverydate',
				headerFilter: true,
			},
		];
	}

	protected created(): void {
		this.fetchOrderItems();
	}

	protected mounted(): void {
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeDestroy() {
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this?.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.allTableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	@Watch('loggedIn')
	async fetchOrderItems(): Promise<void> {
		if (this.loggedIn) {
			this.spinner = true;
			try {
				const response = await httpClient.get<DB.OrderItemModel>(`/api/orderItem/${this.orderItemId}`);
				this.orderItem = response.data;
				const response2 = await httpClient.get<DB.OfferingModel>(`/api/offering/${this.orderItem.offeringid}`);
				this.offeringData = response2.data;
			} catch (error: any) {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			} finally {
				this.spinner = false;
			}
		}
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});

		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.OrderItemStatusModel[]>(`/api/orderitem/${this.orderItemId}/status?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.table?.clearAlert();
		}
	}
}

import { Vue, Component } from 'vue-property-decorator';
import CrossSellTable from 'components/CrossSellTable';
import Template from './template.vue';

@Component({
	components: {
		CrossSellTable,
	},
})
export default class CrossSell extends Vue.extend(Template) {
	protected showModal(): void {
		this.$root.$emit(
			'bv::show::modal',
			'crossSell-modal',
			'#crossSellShow',
		);
	}

	protected routeCrossSell(id: string): void {
		this.$router.push(`/crosssell/${id}`);
	}
}

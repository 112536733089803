import { Vue, Component, Ref } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { BModal } from 'bootstrap-vue';
import mitt from 'mitt';
import Swal from 'sweetalert2';
import {
	LanguageOptionsModel,
	ModelWithHeader,
	TabEvent, Translation, TranslationsResponse,
} from 'interfaces/app';
import { httpClient } from 'utils/http';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import TabulatorImage from 'components/Tabulator/TabulatorImage';
import {
	CellComponent,
	CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.OfferingOptionModel>>();
@Component({
	components: {
		TabulatorBtn,
		TabulatorImage,
	},
})
export default class OfferingOptionTable extends Vue.extend(Template) {
	@Ref('addOfferingOption-modal') readonly modal!: BModal;

	@Ref('offeringOptionTable')
	private readonly tableReference!: HTMLDivElement;

	private offeringOption : DB.OfferingOptionModel[] = [];

	private name?: DB.OfferingOptionModel['name'] = '';

	private perPage = 20;

	private isLoaded = false;

	private columnDefs: ColumnDefinitionExtended[] = []

	private rowData:(DB.OfferingOptionModel & ModelWithHeader)[] = [];

	private table?: Tabulator;

	private translations = {} as Record<number, string>;

	private language = '';

	private languageOptions: LanguageOptionsModel[] = [];

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'ID',
			},
			{
				title: 'Name',
				field: 'name',
			},
			{
				title: 'Header',
				field: 'header',
				editor: 'input',
				cellEdited: this.handleCellEdit,
			},
			{
				title: 'Description',
				field: 'description',
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.OfferingOptionModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	protected mounted(): void {
		eventBus.on('deleteItem',
			this.deleteOfferingOption);
		eventBus.on('editItem',
			this.editOfferingOption);
		this.tableInitialization();
		this.table?.on('tableBuilt',
			this.onTableBuilt);
		this.getLanguageData();
	}

	protected beforeDestroy(): void {
		eventBus.off('deleteItem',
			this.deleteOfferingOption);
		eventBus.off('editItem',
			this.editOfferingOption);
		this.table?.off('tableBuilt',
			this.onTableBuilt);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			});
	}

	private async fetchTranslations(language: string): Promise<TranslationsResponse> {
		const parameter = new URLSearchParams({
			ns: 'offeringoptions',
			lng: language,
			limit: '0',
		});

		try {
			const { data } = await httpClient.get<TranslationsResponse>(`/api/translation/multi?${parameter}`);
			return data;
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
			return Promise.reject(error);
		}
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const offeringOptionData = await httpClient.get<DB.OfferingOptionModel[]>(`/api/offeringoption?${parameter}`);
			this.rowData = offeringOptionData.data;
			this.table?.setData(this.rowData);
		} catch (err: any) {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		} finally {
			this.table?.clearAlert();
		}
	}

	private async getLanguageData(): Promise<void> {
		try {
			const languageResponse = await httpClient.get<DB.LanguageModel[]>('/api/language?limit=0');
			this.languageOptions = languageResponse.data.map((language) => ({ value: language.id, text: language.name }));
			// get the default language
			const defaultLang = languageResponse.data.find((language: DB.LanguageModel) => language.default);
			// set the language to the default language or the first language in the list
			this.language = defaultLang ? defaultLang.id : languageResponse.data[0].id;
			// get the translations for the default language
			const translationsData = await this.fetchTranslations(this.language);

			this.translations = translationsData[this.language].offeringoptions as string;

			// set the translations for the default language
			this.rowData = this.rowData.map((row) => {
				if (this.translations[row.id] !== undefined) {
					return {
						...row,
						header: this.translations[row.id],
					};
				}
				return row;
			});

			this.table?.updateData(this.rowData);
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		}
	}

	protected async translateTableData(data: string): Promise<void> {
		const translations = await this.fetchTranslations(data);
		this.translations = translations[this.language]?.offeringoptions as string;

		this.rowData = this.rowData.map((row) => {
			if (this.translations?.[row.id] !== undefined) {
				return {
					...row,
					header: this.translations[row.id],
				};
			}
			return row;
		});

		this.table?.updateData(this.rowData);
	}

	protected addOfferingOption(): void {
		this.isLoaded = true;
		httpClient.post('/api/offeringoption',
			{
				name: this.name,
			}).then((res) => {
			this.modal.hide();

			this.offeringOption.push(res.data);
			this.name = '';
			this.$emit('routeOfferingOption',
				res.data.id);
			return undefined;
		})
			.finally(() => {
				this.isLoaded = false;
			})
			.catch((err) => {
				this.$bvToast.toast(`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					});
			});
	}

	private editOfferingOption(data: TabEvent<DB.OfferingOptionModel>['editItem']): void {
		this.$emit('routeOfferingOption',
			data.id);
	}

	private deleteOfferingOption(data: TabEvent<DB.OfferingOptionModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading');
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete(`/api/offeringoption/${data.id}`).then(() => {
					this.$bvToast.toast('Item Deleted',
						{
							solid: true,
							variant: 'success',
						});
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						});
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				});
		});
	}

	private handleCellEdit(cell: CellComponent): void {
		const row = cell.getRow().getData();
		const field = cell.getField();
		const newValue = cell.getValue();

		row[field] = newValue;

		//  Find and Update 'rowData' state
		const foundRow = this.rowData.find((r) => r.id === row.id);

		if (foundRow) {
			(foundRow as unknown as Record<string, string | number>)[field] = newValue;
		}
		this.saveUpdatedData([cell]).catch((error) => {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}

	private async saveUpdatedData(cells: CellComponent[]): Promise<void> {
		const bundle: Record<number, Translation> = this.formatCellsAsTranslations(cells);
		try {
			await httpClient.put('/api/translation',
				{
					namespace: 'offeringoptions',
					language: this.language,
					bundle,
				});
			this.$bvToast.toast(
				'Successfully updated the data for the table',
				{
					solid: true,
					variant: 'success',
				},
			);
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		}
	}

	// eslint-disable-next-line class-methods-use-this
	private formatCellsAsTranslations(cells: CellComponent[]): Record<number, Translation> {
		return cells.reduce((result, cell) => {
			const row = cell.getRow().getData();
			return {
				...result,
				[row.id]: row.header ? row.header : '',
			};
		},
		{});
	}
}

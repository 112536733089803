var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2 mb-2" },
    _vm._l(_vm.buttons, function (button) {
      return _c(
        "button",
        {
          key: button.id,
          staticClass: "small ui button",
          on: {
            click: function ($event) {
              return _vm.onButtonClick(button.eventName)
            },
          },
        },
        [_c("i", { class: `fa fa-fw ${button.className}` })]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./EditCountry.vue?vue&type=template&id=5aac5b3a&scoped=true"
import script from "./EditCountry.ts?vue&type=script&lang=ts&external"
export * from "./EditCountry.ts?vue&type=script&lang=ts&external"
import style0 from "./EditCountry.vue?vue&type=style&index=0&id=5aac5b3a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aac5b3a",
  null
  
)

export default component.exports
import { Vue, Component, Ref } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { BModal } from 'bootstrap-vue';
import mitt from 'mitt';
import Swal from 'sweetalert2';
import { TabEvent } from 'interfaces/app';
import { httpClient } from 'utils/http';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import TabulatorImage from 'components/Tabulator/TabulatorImage';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';

const eventBus = mitt<TabEvent<DB.Model2DModel>>();
@Component({
	components: {
		TabulatorBtn,
		TabulatorImage,
	},
})
export default class Model2dTable extends Vue {
	@Ref('addmodel2d-modal') readonly modal!: BModal;

	@Ref('model2dTable')
	private readonly tableReference!: HTMLDivElement;

	private model2d : DB.Model2DModel[] = [];

	private name = '';

	private perPage = 20;

	private isLoaded = false;

	private columnDefs: ColumnDefinitionExtended[] = []

	private table?: Tabulator;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'ID',
			},
			{
				title: 'Image',
				field: 'imageUrl',
				formatter: (cell: CellComponentExtended<DB.ProductCategoryModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorImage,
						props: {
							size: 150,
							url: cell.getValue(),
							data,
							eventBus,
						},
					});
					instance.$mount();
					return (instance.$el as HTMLElement);
				},
			},
			{
				title: 'Name',
				field: 'name',
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.BadgeModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	protected mounted(): void {
		eventBus.on(
			'deleteItem',
			this.deleteModel2dItem,
		);
		eventBus.on(
			'editItem',
			this.editModel2dItem,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeDestroy(): void {
		eventBus.off(
			'deleteItem',
			this.deleteModel2dItem,
		);
		eventBus.off(
			'editItem',
			this.editModel2dItem,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.Model2DModel[]>(`/api/model2d?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.table?.clearAlert();
		}
	}

	protected addModel2d(): void {
		this.isLoaded = true;
		httpClient.post(
			'/api/model2d',
			{ name: this.name },
		).then((res) => {
			this.modal.hide();

			this.model2d.push(res.data);
			this.name = '';
			this.$emit(
				'routeModel2d',
				res.data.id,
			);
			return undefined;
		})
			.finally(() => {
				this.isLoaded = false;
			})
			.catch((err) => {
				this.$bvToast.toast(
					`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});
	}

	private editModel2dItem(data: TabEvent<DB.Model2DModel>['editItem']): void {
		this.$emit(
			'routeModel2d',
			data.id,
		);
	}

	private deleteModel2dItem(data: TabEvent<DB.Model2DModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading');
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete(`/api/model2d/${data.id}`).then(() => {
					this.$bvToast.toast(
						'Item Deleted',
						{
							solid: true,
							variant: 'success',
						},
					);
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}
}

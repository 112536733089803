var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-block",
    { attrs: { title: "Theme Categories", "header-bg": "" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mb-3" },
        [
          _c(
            "b-col",
            { attrs: { md: "5" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Available" } },
                [
                  _c(
                    "b-list-group",
                    { staticStyle: { "overflow-y": "auto", height: "200px" } },
                    _vm._l(_vm.availableCategories, function (category, index) {
                      return _c(
                        "b-list-group-item",
                        {
                          key: category.id,
                          attrs: {
                            active:
                              _vm.selectedCategoryActiveIndex === index
                                ? true
                                : false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.availableItem(category, index)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(`(${category.locale})`) +
                              " " +
                              _vm._s(category.name) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass:
                "d-flex flex-column justify-content-around align-items-center",
              attrs: { md: "2" },
            },
            [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "outline-dark" },
                  on: { click: _vm.addToSelected },
                },
                [_c("i", { staticClass: "fa fa-arrow-right mr-1" })]
              ),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "outline-dark" },
                  on: { click: _vm.removeFromSelected },
                },
                [_c("i", { staticClass: "fa fa-arrow-left mr-1" })]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "5" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Selected" } },
                [
                  _c(
                    "b-list-group",
                    { staticStyle: { "overflow-y": "auto", height: "200px" } },
                    _vm._l(_vm.selectedCategories, function (category, index) {
                      return _c(
                        "b-list-group-item",
                        {
                          key: category.id,
                          attrs: {
                            active:
                              _vm.availableCategoryActiveIndex === index
                                ? true
                                : false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.selectedItem(category, index)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(`(${category.locale})`) +
                              " " +
                              _vm._s(category.name) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
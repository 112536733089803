var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "productOrdered-modal",
          attrs: { id: "bv-modal", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [_vm._v("\n\t\t\tOrder Item Details\n\t\t")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._l(_vm.printUrls, function (item) {
            return _c(
              "div",
              { key: item.id },
              [
                _c("b-alert", { attrs: { show: "" } }, [
                  _c("a", { attrs: { href: item, target: "_blank" } }, [
                    _vm._v(_vm._s(item)),
                  ]),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { ref: "productOrderedTable" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/*
 * Toggle Class fuctionality
 */

export default {
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	bind(el, binding) {
		const targetId = binding.value.targetId || false;
		const classList = binding.value.class
			? binding.value.class.split(' ')
			: false;

		// Setup toggle class functionality
		// eslint-disable-next-line no-param-reassign
		el.toggleClass = () => {
			const target = document.getElementById(targetId);

			if (target && classList) {
				classList.forEach((item) => {
					target.classList.toggle(item);
				});
			}
		};

		// Attach the click event to the element
		el.addEventListener(
			'click',
			() => {
				el.toggleClass();
			},
		);
	},
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	unbind(el) {
		el.removeEventListener(
			'click',
			el.toggleClass,
		);
	},
};

import {
	Vue, Component, Prop, Ref,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import Swal from 'sweetalert2';
import mitt from 'mitt';
import { PlaceHolderModel, TabEvent } from 'interfaces/app';
import { httpClient } from 'utils/http';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.ThemePageModel>>();
@Component({
	components: {
		TabulatorBtn,
		TabulatorSwitchBox,
	},
})
export default class ThemesPagesTable extends Vue.extend(Template) {
	@Ref('ThemesPagesTable')
	private readonly tableReference!: HTMLDivElement;

	private loaded = false;

	private placeHolders: Pick<PlaceHolderModel, 'mobile' | 'desktop'> = {
		mobile: 'Choose a file',
		desktop: 'Choose a file',
	}

	private columnDefs: ColumnDefinitionExtended[] = [];

	private showExistingPage = false;

	private showNewPage = false;

	private themedata = {} as DB.ThemePageModel;

	private ExistingPagesData = {} as DB.ThemePageModel;

	private themepageDate: DB.ThemePageModel[] = [];

	private table?: Tabulator;

	@Prop({
		type: String,
		required: true,
	})
	public readonly routeId!: string;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'name',
				title: 'Name',
			},
			{
				title: 'Serialnumber',
				field: 'serialnumber',
			},
			{
				title: 'Page repeat',
				formatter: (cell: CellComponentExtended<DB.ThemePageModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							data,
							eventBus,
							checked: Boolean(data && data.repeat),
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.ThemePageModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	private switchBoxChanged(data: TabEvent<DB.ThemePageModel>['switchBoxChanged']): void {
		this.table?.alert('Loading...');
		if (data.event) {
			httpClient
				.put(
					`/api/themepage/${data.params.id}`,
					{
						repeat: 1,
					},
				)
				.then(() => undefined)
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		} else {
			httpClient
				.put(
					`/api/themepage/${data.params.id}`,
					{
						repeat: 0,
					},
				)
				.then(() => undefined)
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
	}

	protected toggleNewPage(): void {
		this.showNewPage = !this.showNewPage;
	}

	protected toggleExistingPage(): void {
		this.showExistingPage = !this.showExistingPage;
	}

	protected mounted(): void {
		eventBus.on(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		eventBus.on(
			'editItem',
			this.editThemePages,
		);
		eventBus.on(
			'deleteItem',
			this.deleteThemePages,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeDestroy(): void {
		eventBus.off(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		eventBus.off(
			'editItem',
			this.editThemePages,
		);
		eventBus.off(
			'deleteItem',
			this.deleteThemePages,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.ThemePageModel[]>(`/api/theme/${this.routeId}/pages?${parameter}`);
			this.themepageDate = data;
			this.themedata.serialnumber = data.length + 1;
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.table?.clearAlert();
		}
	}

	protected async addPage(): Promise<void> {
		this.table?.alert('Loading...');
		try {
			const { data } = await httpClient
				.post(
					'/api/page',
					{
						name: this.themedata.name,
						serialnumber: this.themedata.serialnumber,
					},
				);
			this.table?.addData(data);
			await httpClient
				.post(
					'/api/themepage',
					{
						themeid: parseInt(
							this.routeId,
							10,
						),
						pageid: data.id,
					},
				);
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.table?.clearAlert();
		}
	}

	protected async addPages(): Promise<void> {
		this.table?.alert('Loading...');
		try {
			const { data } = await httpClient
				.post(
					'/api/themepage',
					{
						themeid: parseInt(
							this.$route.params.id,
							10,
						),
						pageid: this.ExistingPagesData.id,
					},
				);
			this.table?.addData(data);
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.table?.clearAlert();
		}
	}

	// route to Edit Themes page
	private editThemePages(data: TabEvent<DB.ThemePageModel>['editItem']): void {
		this.$emit(
			'routeThemePages',
			data.params.pageid,
		);
	}

	private deleteThemePages(data: TabEvent<DB.ThemePageModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading...');
				// eslint-disable-next-line promise/no-nesting
				return httpClient.delete(`/api/themevariant/${data.id}`).then(() => {
					this.$bvToast.toast(
						'Item Deleted',
						{
							solid: true,
							variant: 'success',
						},
					);
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoaded, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "addpdp-modal",
          attrs: { id: "pdp-modal", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [_vm._v("\n\t\t\tCreate New Pdp\n\t\t")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-block text-center" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: { label: "Name: ", "label-for": "block-form1-name" },
                },
                [
                  _c("ValidationProvider", {
                    attrs: { rules: "required", name: "name" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ classes, errors }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "control", class: classes },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.name,
                                    callback: function ($$v) {
                                      _vm.name = $$v
                                    },
                                    expression: "name",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(errors[0]))]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { block: "" },
              on: {
                click: function ($event) {
                  return _vm.addPdp()
                },
              },
            },
            [_vm._v("\n\t\t\tSave\n\t\t")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "d-flex justify-content-end mb-4" },
        [
          _c(
            "b-col",
            {
              staticClass: "my-1",
              attrs: { sm: "5", md: "3", "align-self": "center" },
            },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Language:",
                    "label-for": "search-group-select",
                    "label-cols-sm": "6",
                    "label-cols-md": "4",
                    "label-cols-lg": "4",
                    "label-align-sm": "right",
                    "label-size": "sm",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "search-group-select",
                      size: "sm",
                      options: _vm.languageOptions,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.translateTableData(_vm.language)
                      },
                    },
                    model: {
                      value: _vm.language,
                      callback: function ($$v) {
                        _vm.language = $$v
                      },
                      expression: "language",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("base-block", { attrs: { title: "Existing Pdps", "header-bg": "" } }, [
        _c("div", { ref: "pdpTable" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { Vue, Component, Ref } from 'vue-property-decorator';
import PricingTable from 'components/PricingTable/template.vue';

@Component({
	components: {
		PricingTable,
	},
})
export default class Pricing extends Vue {
	@Ref('updatePrice') readonly updatePrices!: HTMLFormElement;

	private name = 'Pricing'
}

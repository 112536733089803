import { Vue, Component } from 'vue-property-decorator';
import FlexGroupTable from 'components/FlexGroupTable';
import Template from './template.vue';

@Component({
	components: {
		FlexGroupTable,
	},
})
export default class FlexGroup extends Vue.extend(Template) {
	protected showModal(): void {
		this.$root.$emit('bv::show::modal',
			'flexGroup-modal',
			'#flexGroupShow');
	}

	protected routeFlexGroup(id: string): void {
		this.$router.push(`/flexgroup/${id}`);
	}
}

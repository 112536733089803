import { Vue, Component, Ref } from 'vue-property-decorator';
import PriceInput from 'components/Tabulator/PriceInput/';
import PriceDetails from 'components/Tabulator/PriceDetails/';
import mitt from 'mitt';
import { httpClient } from 'utils/http';
import { createInstance } from 'utils/vue';
import * as DB from 'interfaces/database';
import {
	CellComponent,
	CellComponentExtended,
	ColumnComponent,
	ColumnDefinitionExtended,
	RowComponent,
	TabulatorFull as Tabulator,
} from 'tabulator-tables';
import Swal from 'sweetalert2';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import { TabEvent } from 'interfaces/app';
import CountryFlagGlob from 'components/Tabulator/CountryFlag';
import TabulatorDropdown from 'components/Tabulator/TabulatorDropdown';
import { BModal } from 'bootstrap-vue';
import {
	drawAllBorders, multiSelectCell, removeAllBorders, singleSelectCell,
} from 'utils/tabulator-select';
import { formatCellsToClipboard, formatClipboardToCells } from 'utils/tabulator-copy-paste';
import debounce from 'utils/debounce';
import { autofillStart, autofillStop } from 'utils/tabulator-autofill';
import Template from './template.vue';

interface RowData extends DB.ShippingModel {
	currencyModel: DB.CurrencyModel;
	countryModel: DB.CountryModel;
	error: Record<keyof DB.ShippingModel, boolean>;
	loading: Record<keyof DB.ShippingModel, boolean>;
	success: Record<keyof DB.ShippingModel, boolean>;
}

const eventBus = mitt<TabEvent<DB.ShippingModel>>();
@Component({
	components: {
		TabulatorBtn,
		TabulatorDropdown,
		PriceInput,
		PriceDetails,
	},
})

export default class ShippingTable extends Vue.extend(Template) {
	@Ref('addshipping-modal') readonly modal!: BModal;

	@Ref('tableContainer')
	private tableContainer!: HTMLDivElement;

	private table?: Tabulator;

	private isLoading = false;

	private addShippingData = {} as DB.ShippingModel;

	private rowData: Omit<RowData, 'shippingModel' | 'currencyModel' | 'countryModel'>[] = [];

	private currencyModels: DB.CurrencyModel[] = [];

	private shippingModels: DB.ShippingModel[] = [];

	private options: string[] = ['quantity', 'item', 'variant', 'type', 'group', 'order'];

	private countryModels: DB.CountryModel[] = [];

	private selectedRows: Array<RowComponent> = [];

	private autofillRows: Array<RowComponent> = [];

	private selectedColumns: Array<ColumnComponent> = [];

	private autofillColumns: Array<ColumnComponent> = [];

	private selectedCell: CellComponent | null = null;

	private editingCell: CellComponent | null = null;

	private isUpdatingCells = false;

	private isDragging = false;

	private cellsToUpdate: Map<CellComponent, number> = new Map();

	private editableColumns = new Set(['sale', 'scope', 'days_min', 'days_max']);

	private mounted(): void {
		// this.table?.alert('Loading');

		this.getData().then(() => {
			this.buildTable();
			return true;
		}).catch(() => {
			// this.table?.clearAlert();
		});
		document.addEventListener('keydown',
			this.handleKeyDown);
		document.addEventListener('copy',
			this.handleCopyEvent);
		document.addEventListener('paste',
			this.handlePasteEvent);

		eventBus.on(
			'deleteItem',
			this.deleteShipping,
		);
		eventBus.on(
			'switchBoxChanged',
			this.checkBoxChanged,
		);
	}

	private handlePasteEvent(event: ClipboardEvent): void {
		const text = event.clipboardData?.getData('text/plain');
		if (text) {
			this.updateSelectedCells(formatClipboardToCells(text));
		}
	}

	private handleCopyEvent(event: ClipboardEvent): void {
		event.preventDefault();
		const text = formatCellsToClipboard(this.selectedRows,
			this.selectedColumns);
		event.clipboardData?.setData('text/plain',
			text);
	}

	private handleMultiSelectEvent(event: KeyboardEvent): void {
		if (!this.selectedCell) return;

		const cellToSelect = multiSelectCell(event.key,
			this.selectedCell,
			this.selectedRows,
			this.selectedColumns,
			this.editableColumns);

		if (cellToSelect) this.selectedCell = cellToSelect;
	}

	private handleSingleSelectEvent(event: KeyboardEvent): void {
		if (!this.selectedCell) return;
		const cellToSelect = singleSelectCell(event.key,
			this.selectedCell,
			this.selectedRows,
			this.selectedColumns,
			this.editableColumns);

		if (cellToSelect) {
			this.selectedCell = cellToSelect;
			this.selectedRows = [cellToSelect.getRow()];
			this.selectedColumns = [cellToSelect.getColumn()];
		}
	}

	private handleKeyDown(event: KeyboardEvent): void {
		if (event.shiftKey) {
			return this.handleMultiSelectEvent(event);
		}

		switch (event.key) {
			case 'Delete':
				this.updateSelectedCells(this.selectedRows.map(() => this.selectedColumns.map(() => '0')));
				break;
			default:
				break;
		}
		return this.handleSingleSelectEvent(event);
	}

	private buildTable() {
		this.table = new Tabulator(
			this.tableContainer,
			{
				data: this.rowData,
				height: '80vh',
				rowHeight: 60,
				layout: 'fitColumns',
				keybindings: {
					// using right arrow key for navRight
					navRight: '39',
					// using left arrow key for navLeft
					navLeft: '37',
					// using up arrow key for navUp
					navUp: '38',
					// using down arrow key for navDown
					navDown: '40',
				},
				columns: [
					{
						title: 'currencyModel',
						field: 'currencyModel',
						visible: false,
						mutatorData: (val, data) => {
							if (data && data.countrycode) {
								return this.countryModels.find(
									(model) => model.iso === data.countrycode,
								);
							}
							return null;
						},
					},
					{
						title: 'countryModel',
						field: 'countryModel',
						visible: false,
						mutatorData: (val, data) => this.countryModels.find(
							(model) => model.iso === data.countrycode,
						),
					},
					{
						title: 'offering',
						field: 'offeringid',
						cssClass: 'excelStyle',
						formatter: 'plaintext',
						headerFilter: 'input',
						width: 110,
					},
					{
						title: 'Country name',
						formatter: (cell: CellComponent) => {
							const data = cell.getData() as RowData;
							if (data.countryModel && data.countryModel.iso) {
								const instance = createInstance({
									component: CountryFlagGlob,
									props: {
										data: data.countryModel,
									},
								});
								instance.$mount();

								return (instance.$el as HTMLElement);
							}
							return 'All countries';
						},
						headerFilter: 'input',
					},
					{
						title: 'Description',
						field: 'currencyName',
						cssClass: 'excelColumn',
						formatter: 'plaintext',
						mutatorData: (val, data) => data.currencyModel?.name,
						headerFilter: 'input',
						width: 150,
					},
					{
						title: 'ISO',
						field: 'currencyIso',
						cssClass: 'excelColumn',
						formatter: 'plaintext',
						mutatorData: (val, data) => data.currencyModel?.iso,
						headerFilter: 'input',
						width: 80,
					},
					{
						title: 'Tracking',
						field: 'tracking',
						formatter: (cell: CellComponentExtended<DB.ShippingModel>) => {
							const data = cell.getData();
							const instance = createInstance({
								component: TabulatorSwitchBox,
								props: {
									data,
									eventBus,
									field: 'tracking',
									checked: Boolean(data && data.tracking),
								},
							});
							instance.$mount();

							return (instance.$el as HTMLElement);
						},
					},
					{
						title: 'Express',
						field: 'express',
						formatter: (cell: CellComponentExtended<DB.ShippingModel>) => {
							const data = cell.getData();
							const instance = createInstance({
								component: TabulatorSwitchBox,
								props: {
									data,
									eventBus,
									field: 'express',
									checked: Boolean(data && data.express),
								},
							});
							instance.$mount();

							return (instance.$el as HTMLElement);
						},
					},
					{
						title: 'Sale',
						field: 'sale',
						width: 150,
						resizable: false,
						cssClass: 'excelColumn',
						headerFilter: 'input',
						editable: () => !!this.editingCell,
						cellClick: (e, cell) => this.selectCell(cell),
						cellDblClick: (e, cell) => this.editCell(cell),
						cellMouseEnter: this.onCellMouseEnter,
						cellMouseUp: this.onCellMouseUp,
						formatter: this.priceInputFormatter,
						editor: (
							cell,
							onRendered,
							success,
						) => {
							const rowValue = cell.getValue();
							let caretPosition = 0;
							let saving = false;

							const instance = createInstance({
								component: PriceInput,
								props: {
									value: rowValue,
									notDecimalNumber: true,
								},
								parent: this,
							});

							instance.$on(
								'change',
								(value: number) => {
									saving = true;
									return this
										.updateRowDataValue(
											cell,
											'sale',
											value,
										)
										.then((succeedValue) => success(succeedValue));
								},
							);
							instance.$on(
								'blur',
								() => {
									cell.cancelEdit();
									this.editingCell = null;
									if (!saving) {
										success(instance.value);
									}
								},
							);
							instance.$on(
								'keydown',
								(event: KeyboardEvent) => {
									const input = instance.inputElement as HTMLInputElement;

									// eslint-disable-next-line default-case
									switch (event.key) {
										case 'ArrowLeft':
											if (caretPosition > 0) {
												caretPosition -= 1;
												event.stopPropagation();
											}
											break;
										case 'ArrowRight':
											if (caretPosition < input.value.length) {
												caretPosition += 1;
												event.stopPropagation();
											}
											break;
									}
								},
							);
							instance.$mount();
							onRendered(() => {
								if (this.editingCell === cell) {
									instance.inputElement.focus();
									// set cursor position to the beginning of the input field
									instance.inputElement.setSelectionRange(
										0,
										0,
									);
								}
							});

							return (instance.$el as HTMLElement);
						},
					},
					{
						title: 'scope',
						field: 'scope',
						width: 150,
						resizable: false,
						cssClass: 'excelColumn',
						headerFilter: 'select',
						headerFilterParams: {
							values: this.options,
						},
						headerFilterFunc: this.customScopeHeaderFilter,
						editable: () => !!this.editingCell,
						cellClick: (e, cell) => this.selectCell(cell),
						cellDblClick: (e, cell) => this.editCell(cell),
						cellMouseEnter: this.onCellMouseEnter,
						cellMouseUp: this.onCellMouseUp,
						formatter: (cell: CellComponent) => {
							const rowData = cell.getData() as RowData;
							const field = cell.getField() as keyof DB.ShippingModel;

							const instance = createInstance({
								component: TabulatorDropdown,
								props: {
									value: cell.getValue(),
									options: this.options,
									readonly: true,
									error: rowData.error[field],
									loading: rowData.loading[field],
									success: rowData.success[field],
								},
								parent: this,
							});
							instance.$on(
								'focus',
								() => {
									this.selectCell(cell);
								},
							);
							instance.$on(
								'mousedown',
								this.onMouseDown,
							);
							instance.$mount();

							return (instance.$el as HTMLElement);
						},
						editor: (
							cell,
							onRendered,
							success,
						) => {
							const rowValue = cell.getValue();
							// let caretPosition = 0;
							let saving = false;

							const instance = createInstance({
								component: TabulatorDropdown,
								props: {
									value: rowValue,
									options: this.options,
								},
								parent: this,
							});

							instance.$on(
								'change',
								(value: DB.ShippingModel['scope']) => {
									saving = true;
									return this
										.updateRowDataValue(
											cell,
											'scope',
											value,
										)
										.then((succeedValue) => success(succeedValue));
								},
							);
							instance.$on(
								'blur',
								() => {
									cell.cancelEdit();
									this.editingCell = null;
									if (!saving) {
										success(instance.value);
									}
								},
							);
							instance.$mount();
							onRendered(() => {
								if (this.editingCell === cell) {
									instance.selectInput.focus();
								}
							});

							return (instance.$el as HTMLElement);
						},
					},
					{
						title: 'Days min',
						field: 'days_min',
						width: 150,
						resizable: false,
						cssClass: 'excelColumn',
						headerFilter: 'input',
						editable: () => !!this.editingCell,
						cellClick: (e, cell) => this.selectCell(cell),
						cellDblClick: (e, cell) => this.editCell(cell),
						cellMouseEnter: this.onCellMouseEnter,
						cellMouseUp: this.onCellMouseUp,
						formatter: this.priceInputFormatter,
						editor: (
							cell,
							onRendered,
							success,
						) => {
							const rowValue = cell.getValue();
							let caretPosition = 0;
							let saving = false;

							const instance = createInstance({
								component: PriceInput,
								props: {
									value: rowValue,
									notDecimalNumber: true,
								},
								parent: this,
							});

							instance.$on(
								'change',
								(value: number) => {
									saving = true;
									return this
										.updateRowDataValue(
											cell,
											'days_min',
											value,
										)
										.then((succeedValue) => success(succeedValue));
								},
							);
							instance.$on(
								'blur',
								() => {
									cell.cancelEdit();
									this.editingCell = null;
									if (!saving) {
										success(instance.value);
									}
								},
							);
							instance.$on(
								'keydown',
								(event: KeyboardEvent) => {
									const input = instance.inputElement as HTMLInputElement;

									// eslint-disable-next-line default-case
									switch (event.key) {
										case 'ArrowLeft':
											if (caretPosition > 0) {
												caretPosition -= 1;
												event.stopPropagation();
											}
											break;
										case 'ArrowRight':
											if (caretPosition < input.value.length) {
												caretPosition += 1;
												event.stopPropagation();
											}
											break;
									}
								},
							);
							instance.$mount();
							onRendered(() => {
								if (this.editingCell === cell) {
									instance.inputElement.focus();
									// set cursor position to the beginning of the input field
									instance.inputElement.setSelectionRange(
										0,
										0,
									);
								}
							});

							return (instance.$el as HTMLElement);
						},
					},
					{
						title: 'Days max',
						field: 'days_max',
						width: 150,
						resizable: false,
						cssClass: 'excelColumn',
						headerFilter: 'input',
						editable: () => !!this.editingCell,
						cellClick: (e, cell) => this.selectCell(cell),
						cellDblClick: (e, cell) => this.editCell(cell),
						cellMouseEnter: this.onCellMouseEnter,
						cellMouseUp: this.onCellMouseUp,
						formatter: this.priceInputFormatter,
						editor: (
							cell,
							onRendered,
							success,
						) => {
							const rowValue = cell.getValue();
							let caretPosition = 0;
							let saving = false;

							const instance = createInstance({
								component: PriceInput,
								props: {
									value: rowValue,
									notDecimalNumber: true,
								},
								parent: this,
							});

							instance.$on(
								'change',
								(value: number) => {
									saving = true;
									return this
										.updateRowDataValue(
											cell,
											'days_max',
											value,
										)
										.then((succeedValue) => success(succeedValue));
								},
							);
							instance.$on(
								'blur',
								() => {
									cell.cancelEdit();
									this.editingCell = null;
									if (!saving) {
										success(instance.value);
									}
								},
							);
							instance.$on(
								'keydown',
								(event: KeyboardEvent) => {
									const input = instance.inputElement as HTMLInputElement;

									// eslint-disable-next-line default-case
									switch (event.key) {
										case 'ArrowLeft':
											if (caretPosition > 0) {
												caretPosition -= 1;
												event.stopPropagation();
											}
											break;
										case 'ArrowRight':
											if (caretPosition < input.value.length) {
												caretPosition += 1;
												event.stopPropagation();
											}
											break;
									}
								},
							);
							instance.$mount();
							onRendered(() => {
								if (this.editingCell === cell) {
									instance.inputElement.focus();
									// set cursor position to the beginning of the input field
									instance.inputElement.setSelectionRange(
										0,
										0,
									);
								}
							});

							return (instance.$el as HTMLElement);
						},
					},
					{
						title: 'Actions',
						formatter: (cell: CellComponentExtended<DB.ShippingModel>) => {
							const instance = createInstance({
								component: TabulatorBtn,
								props: {
									data: cell.getData(),
									buttons: [
										{
											id: 'delete',
											eventName: 'deleteItem',
											className: 'fa-times',
										},
									],
									eventBus,
								},
							});
							instance.$mount();

							return (instance.$el as HTMLElement);
						},
					},
					{
						title: 'loading',
						field: 'loading',
						visible: false,
					},
					{
						title: 'success',
						field: 'success',
						visible: false,
					},
					{
						title: 'error',
						field: 'error',
						visible: false,
					},
				] as ColumnDefinitionExtended[],
			},
		);
	}

	private updateCellsDebounce = debounce((): Promise<void> => this.updateCells(),
		100);

	private async updateCells() {
		if (this.isUpdatingCells) {
			return this.updateCellsDebounce();
		}
		this.isUpdatingCells = true;

		const currentCellsToUpdate = new Map(this.cellsToUpdate);
		this.cellsToUpdate = new Map();

		const rowDataToUpdate: Array<any> = [];

		currentCellsToUpdate.forEach((newValue, cell) => {
			const rowData = cell.getData() as RowData;
			const oldValue = cell.getValue();
			const field = cell.getField();

			if (oldValue !== newValue) {
				rowDataToUpdate.push(
					{
						[field]: newValue,
						offeringid: rowData.offeringid,
						currency: rowData.currency,
						countrycode: rowData.countrycode,
						tracking: rowData.tracking,
						express: rowData.express,
						instore: rowData.instore,
					},
				);

				if (this.table) {
					this.table.updateData([
						{
							id: rowData.id,
							loading: {
								...rowData.loading,
								[field]: true,
							},
							success: {
								...rowData.success,
								[field]: false,
							},
							error: {
								...rowData.error,
								[field]: false,
							},
						},
					]);
				}
			}
		});

		/* eslint-disable @typescript-eslint/indent */
		return httpClient
			.post('api/shipping/import',
				rowDataToUpdate)
			.then(() => currentCellsToUpdate.forEach((newValue, cell) => {
				const rowData = cell.getData() as RowData;
				const field = cell.getField();

				return this.table?.updateData([{
					id: rowData.id,
					[field]: newValue,
					loading: {
						...rowData.loading,
						[field]: false,
					},
					success: {
						...rowData.success,
						[field]: true,
					},
				}]);
			}))
			.catch(() => {
				currentCellsToUpdate.forEach((_, cell) => {
					const rowData = cell.getData() as RowData;
					const field = cell.getField();

					// set the error prop to true if the API call fails
					return this.table?.updateData([{
						id: rowData.id,
						loading: {
							...rowData.loading,
							[field]: false,
						},
						success: {
							...rowData.success,
							[field]: false,
						},
						error: {
							...rowData.error,
							[field]: true,
						},
					}]);
				});
			})
			.finally(() => {
				this.isUpdatingCells = false;
			});
	}

	private async updateRowDataValue<
		K extends keyof DB.ShippingModel,
		T extends DB.ShippingModel[K]
	>(
		cell: CellComponent,
		field: K,
		value: T,
	): Promise<T> {
		let rowData = cell.getData() as RowData;
		const oldValue = cell.getValue();
		let succeedValue: T;

		if (this.table) {
			await this.table.updateData([
				{
					id: rowData.id,
					loading: {
						...rowData.loading,
						[field]: true,
					},
					success: {
						...rowData.success,
						[field]: false,
					},
					error: {
						...rowData.error,
						[field]: false,
					},
				},
			]);
		}

		/* eslint-disable @typescript-eslint/indent */
		return httpClient
			.put<DB.ShippingModel>(
				`api/shipping/${rowData.id}`,
				{
					[field]: value,
				},
			)
			.then((res) => {
				rowData = cell.getData() as RowData;
				succeedValue = value;

				return this.table?.updateData([{
					id: res.data.id,
					[field]: res.data[field],
					loading: {
						...rowData.loading,
						[field]: false,
					},
					success: {
						...rowData.success,
						[field]: true,
					},
				}]);
			})
			.catch(() => {
				rowData = cell.getData() as RowData;
				succeedValue = oldValue;

				// set the error prop to true if the API call fails
				return this.table?.updateData([{
					id: rowData.id,
					loading: {
						...rowData.loading,
						[field]: false,
					},
					success: {
						...rowData.success,
						[field]: false,
					},
					error: {
						...rowData.error,
						[field]: true,
					},
				}]);
			})
			.then(() => succeedValue);
	}

	private getData(): Promise<DB.ShippingModel[]> {
		const params = new URLSearchParams({
			limit: '0',
		});

		return Promise.all([
			httpClient.get<DB.ShippingModel[]>(`/api/shipping?${params}`),
			httpClient.get<DB.CurrencyModel[]>(`/api/currency?${params}`),
			httpClient.get<DB.CountryModel[]>(`/api/country?${params}`),
		]).then(([
			shippingResponse,
			currencyResponse,
			countryResponse,
		]) => {
			this.currencyModels = currencyResponse.data;
			this.shippingModels = shippingResponse.data;
			this.countryModels = countryResponse.data;
			this.rowData = shippingResponse.data
				.map((data) => ({
					...data,
					error: {} as Record<keyof DB.ShippingModel, boolean>,
					loading: {} as Record<keyof DB.ShippingModel, boolean>,
					success: {} as Record<keyof DB.ShippingModel, boolean>,
				}));

			return this.rowData;
		}).catch((error: any) => {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);

			return [];
		});
	}

	protected addShipping(): void {
		this.isLoading = true;
		httpClient.post(
			'/api/shipping',
			{ ...this.addShippingData },
		).then((res) => {
			this.modal.hide();

			this.table?.addData([res.data]);
			this.addShippingData = {} as DB.ShippingModel;
			return undefined;
		})
			.finally(() => {
				this.isLoading = false;
			})
			.catch((err) => {
				this.$bvToast.toast(
					`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});
	}

	// Destroy the table when the component is destroyed
	private beforeDestroy() {
		document.removeEventListener('keydown',
			this.handleKeyDown);
		document.removeEventListener('copy',
			this.handleCopyEvent);
		document.removeEventListener('paste',
			this.handlePasteEvent);
		eventBus.off('deleteItem',
			this.deleteShipping);
		eventBus.off('switchBoxChanged',
			this.checkBoxChanged);
		this?.table?.destroy();
	}

	// eslint-disable-next-line class-methods-use-this
	private customScopeHeaderFilter(
		headerValue: string,
		rowValue: string,
		rowData: DB.ShippingModel,
	) {
		// headerValue - the value of the header filter element
		// rowValue - the value of the column in this row
		return rowData.scope.toLowerCase() === headerValue.toLowerCase();
	}

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	private checkBoxChanged(data: TabEvent<DB.ShippingModel>['switchBoxChanged']): void {
		this.table?.alert('Loading..');
		if (data.field) {
			httpClient.put(
				`/api/shipping/${data.params.id}`,
				{
					[data.field]: data.event ? 1 : 0,
				},
			)
				.then(() => {
					this.$bvToast.toast(
						'Shipping has been updated',
						{
							solid: true,
							variant: 'success',
						},
					);
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				})
				.catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
	}

	private deleteShipping(data: TabEvent<DB.ShippingModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading');
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete(`/api/shipping/${data.id}`).then(() => {
					this.$bvToast.toast(
						'Item Deleted',
						{
							solid: true,
							variant: 'success',
						},
					);
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}

	private priceInputFormatter(cell: CellComponent): HTMLElement {
		const rowData = cell.getData() as RowData;
		const field = cell.getField() as keyof DB.ShippingModel;

		const instance = createInstance({
			component: PriceInput,
			props: {
				value: cell.getValue(),
				notDecimalNumber: true,
				readonly: true,
				error: rowData.error[field],
				loading: rowData.loading[field],
				success: rowData.success[field],
			},
			parent: this,
		});
		instance.$on(
			'focus',
			() => {
				this.selectCell(cell);
			},
		);
		instance.$on(
			'mousedown',
			this.onMouseDown,
		);
		instance.$mount();

		return (instance.$el as HTMLElement);
	}

	private selectCell(cell: CellComponent) {
		this.deSelectCell();
		this.selectedRows = [cell.getRow()];
		this.selectedColumns = [cell.getColumn()];

		drawAllBorders(cell);
		this.selectedCell = cell;
	}

	private deSelectCell() {
		this.selectedRows.forEach((row) => {
			this.selectedColumns.forEach((col) => {
				removeAllBorders(row.getCell(col));
			});
		});
		this.selectedRows = [];
		this.selectedColumns = [];
		this.selectedCell = null;
	}

	private editCell(cell: CellComponent) {
		this.deSelectCell();
		this.editingCell = cell;
		cell.edit(true);
	}

	private async updateSelectedCells(values: string[][]) {
		let i = 0;
		this.selectedRows.forEach((row) => {
			let j = 0;
			this.selectedColumns.forEach((col) => {
				const newValue = Number(values[i][j]);
				if (Number.isNaN(newValue)) {
					return;
				}
				j += 1;
				this.cellsToUpdate.set(row.getCell(col),
					newValue);
			});
			i += 1;
		});

		this.updateCells();
	}

	private onMouseDown() {
		this.isDragging = true;
	}

	private onCellMouseEnter(event: UIEvent, cell: CellComponent) {
		if (!this.isDragging || !this.selectedCell) return;
		autofillStart(cell,
			this.selectedCell,
			this.selectedColumns,
			this.autofillRows);
	}

	private onCellMouseUp() {
		if (!this.isDragging || !this.selectedCell) return;
		this.isDragging = false;

		const values = this.selectedColumns.map((col) => this.selectedRows[0].getCell(col).getValue());

		this.selectedRows = autofillStop(this.selectedRows,
			this.selectedColumns,
			this.autofillRows);

		this.autofillRows = [];
		this.selectedCell = this.selectedRows[this.selectedRows.length - 1].getCell(
			this.selectedColumns[this.selectedColumns.length - 1],
		);

		this.updateSelectedCells(this.selectedRows.map(() => [...values]));
	}
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-block",
    { attrs: { title: "User Credit Balance", "header-bg": "" } },
    [
      _c("div", { ref: "creditRequestTable" }),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.displayTable,
            expression: "displayTable",
          },
        ],
        ref: "creditHistoryTable",
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
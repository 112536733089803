import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import Template from './template.vue';

@Component({})
export default class TabulatorDropdown extends Vue.extend(Template) {
	@Prop({
		type: String,
		required: true,
	})
	public readonly value!: string;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly readonly!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly loading!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly success!: boolean;

	@Prop({
		default: false,
		type: Array,
	})
	public readonly options!: string[]

	public internalValue?: string = '';

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly error!: boolean;

	protected created() {
		this.internalValue = this.value;
	}

	public get selectInput(): HTMLInputElement {
		return (this.$refs.select as Vue).$el as HTMLInputElement;
	}

	protected onBlur(event: Event): void {
		this.$emit(
			'blur',
			event,
		);
	}

	protected onChange(value: string) {
		this.$emit(
			'change',
			value,
			this.value,
		);
	}
}

import {
	Vue, Component, Ref, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import CurrencySalesTextLink from 'components/Tabulator/CurrencySalesTextLink';
import { httpClient } from 'utils/http';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import Template from './template.vue';

@Component({
	components: {
		CurrencySalesTextLink,
	},
})
export default class PaidOrders extends Vue.extend(Template) {
	@Ref('paidOrdersTable')
	private allTableReference!: HTMLDivElement;

	@Prop({
		type: String,
		required: true,
	})
	private readonly routeId!: string;

	private table?: Tabulator;

	private isLoading = false;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'Order ID',
				formatter: (cell) => {
					const route = {
						name: 'showOrder',
						params: { orderid: cell.getValue() },
					};
					const link = document.createElement('a');
					link.href = this.$router.resolve(route).href;
					link.innerText = cell.getValue();
					link.addEventListener(
						'click',
						(e) => {
							e.preventDefault();
							this.$router.push(route);
						},
					);
					return link;
				},
			},
			{
				title: 'Date',
				field: 'dateadded',
			},
			{
				title: 'Sales Value',
				field: 'salesvalue',
				formatter: (cell: CellComponentExtended<DB.OrderItemModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: CurrencySalesTextLink,
						props: {
							data,
							salesvalue: true,
						},
					});
					instance.$mount();
					return (instance.$el as HTMLElement);
				},
			},
			{
				title: 'Discount',
				field: 'discount_voucher',
				formatter: (cell: CellComponentExtended<DB.OrderItemModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: CurrencySalesTextLink,
						props: {
							data,
							discount_voucher: true,
						},
					});
					instance.$mount();
					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	protected mounted(): void {
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeDestroy() {
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this?.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.allTableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async getData(): Promise<void> {
		const id = this.routeId;
		const parameter = new URLSearchParams({
			limit: '0',
			orderby: 'id DESC',
			where: JSON.stringify({
				userid: parseInt(
					id,
					10,
				),
				paid: 1,
			}),
		});

		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.OrderModel[]>(`/api/order?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.table?.clearAlert();
		}
	}
}

import { Vue, Component } from 'vue-property-decorator';
import ProductCategoriesTable from 'components/ProductCategoriesTable/';
import Template from './template.vue';

@Component({
	components: {
		ProductCategoriesTable,
	},
})
export default class ProductCategories extends Vue.extend(Template) {
	protected showModal(): void {
		this.$root.$emit(
			'bv::show::modal',
			'productcategory-modal',
			'#productcategoryShow',
		);
	}

	protected routeProductCategory(id: string): void {
		this.$router.push(`/productcategory/${id}`);
	}
}

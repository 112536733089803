import { Vue, Component, Ref } from 'vue-property-decorator';
import EditBadgesTable from 'components/BadgesTable/EditBadgesTable.vue';
import { httpClient } from '../../../utils/http';

@Component({
	components: {
		EditBadgesTable,
	},
})
export default class EditBadges extends Vue {
	@Ref('editbadge') readonly editbadgeChild!: HTMLFormElement;

	private routeId = this.$route.params.id;

	private isLoading = false;

	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	updateName(): Promise<void> | undefined {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			return httpClient.put(
				`/api/badge/${this.$route.params.id}`,
				{
					name: this.editbadgeChild.refs.badgeImageName.vModelValue,
				},
			)
				.then(() => {
					this.$bvToast.toast(
						'Badge name updated',
						{
							solid: true,
							variant: 'success',
						},
					);
					return undefined;
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				}).finally(() => {
					this.isLoading = false;
				});
		}
		return undefined;
	}
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _c("base-page-heading", {
        staticClass: "d-print-none",
        attrs: { title: "Offering", subtitle: "" },
        scopedSlots: _vm._u([
          {
            key: "extra",
            fn: function () {
              return [
                _c(
                  "b-button",
                  {
                    attrs: {
                      id: "submit",
                      type: "submit",
                      size: "md",
                      variant: "secondary",
                    },
                    on: { click: _vm.editOff },
                  },
                  [_vm._v("\n\t\t\t\tSave changes\n\t\t\t")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-form",
                    [
                      _c(
                        "base-block",
                        {
                          attrs: {
                            rounded: "",
                            title: "Offering properties",
                            "header-bg": "",
                          },
                        },
                        [
                          _c(
                            "b-row",
                            { staticClass: "py-sm-3 py-md-5" },
                            [
                              _c(
                                "b-col",
                                { attrs: { sm: "10", md: "10" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Offering Id",
                                        "label-for": "block-form1-username",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        staticClass: "form-control-alt",
                                        attrs: {
                                          id: "offeringid",
                                          placeholder: "Offering Id",
                                          disabled: "",
                                        },
                                        model: {
                                          value: _vm.editedOffering.id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedOffering,
                                              "id",
                                              $$v
                                            )
                                          },
                                          expression: "editedOffering.id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tIn Stock?\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "instock" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "instock",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tThis can be used to indicate out-of-stock status\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.editedOffering.instock === 1
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editedOffering.instock = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-username",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tExternal Id\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "externalid" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "externalid",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tThe ID of the offering in the external production facility\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-1",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.editedOffering
                                                              .externalid ==
                                                            null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted",
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.editedOffering.externalid =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm.editedOffering.externalid,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedOffering,
                                                  "externalid",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedOffering.externalid",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "example-select",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tPDP ID\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "pdpid" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "pdpid",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tIf you want to show a Product Detail Page for this offering, select the corresponding page\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-2",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.editedOffering
                                                              .pdpid == null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted",
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.editedOffering.pdpid =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "b-form-select",
                                            {
                                              attrs: {
                                                id: "example-select",
                                                plain: "",
                                              },
                                              model: {
                                                value: _vm.editedOffering.pdpid,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedOffering,
                                                    "pdpid",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedOffering.pdpid",
                                              },
                                            },
                                            [
                                              _c("b-form-select-option", {
                                                attrs: { value: "" },
                                              }),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.pdpid,
                                                function (pdp, id) {
                                                  return _c(
                                                    "b-form-select-option",
                                                    {
                                                      key: id,
                                                      attrs: {
                                                        id: "pdp-data",
                                                        value: pdp.id,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(pdp.id) +
                                                          " | " +
                                                          _vm._s(pdp.name) +
                                                          " - " +
                                                          _vm._s(
                                                            pdp.description
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "example-select",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tTHEME ID\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "themeid" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "themeid",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tThe theme ID that will be selected by default if user create product of this offering\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-3",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.editedOffering
                                                              .themeid == null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted",
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.editedOffering.themeid =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "b-form-select",
                                            {
                                              attrs: {
                                                id: "example-select",
                                                plain: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.editedOffering.themeid,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedOffering,
                                                    "themeid",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedOffering.themeid",
                                              },
                                            },
                                            [
                                              _c("b-form-select-option", {
                                                attrs: { value: "" },
                                              }),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.themeid,
                                                function (theme) {
                                                  return _c(
                                                    "b-form-select-option",
                                                    {
                                                      key: theme.id,
                                                      attrs: {
                                                        value: theme.id,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(theme.id) +
                                                          " | " +
                                                          _vm._s(theme.name) +
                                                          " - " +
                                                          _vm._s(
                                                            theme.description
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "example-select",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t2D Model ID\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "2dmodelid" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "2dmodelid",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tReferences the 2d model of this product that can be used for showing the end result to the user.\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "model2d-checkbox",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.editedOffering
                                                              .model2did == null
                                                              ? true
                                                              : false,
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.editedOffering.model2did =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "b-form-select",
                                            {
                                              attrs: {
                                                id: "example-select",
                                                plain: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.editedOffering.model2did,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedOffering,
                                                    "model2did",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedOffering.model2did",
                                              },
                                            },
                                            [
                                              _c("b-form-select-option", {
                                                attrs: { value: "" },
                                              }),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.model2did,
                                                function (model, id) {
                                                  return _c(
                                                    "b-form-select-option",
                                                    {
                                                      key: id,
                                                      attrs: {
                                                        value: model.id,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(model.id) +
                                                          " | " +
                                                          _vm._s(model.name) +
                                                          " - " +
                                                          _vm._s(
                                                            model.description
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "example-select",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t3D Model ID\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "3dmodelid" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "3dmodelid",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tReferences the 3d model of this product that can be used for showing the end result to the user.\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "model3d-checkbox",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.editedOffering
                                                              .model3did == null
                                                              ? true
                                                              : false,
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.editedOffering.model3did =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "b-form-select",
                                            {
                                              attrs: {
                                                id: "example-select",
                                                plain: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.editedOffering.model3did,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedOffering,
                                                    "model3did",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedOffering.model3did",
                                              },
                                            },
                                            [
                                              _c("b-form-select-option", {
                                                attrs: { value: "" },
                                              }),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.model3did,
                                                function (model, id) {
                                                  return _c(
                                                    "b-form-select-option",
                                                    {
                                                      key: id,
                                                      attrs: {
                                                        value: model.id,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(model.id) +
                                                          " | " +
                                                          _vm._s(model.name) +
                                                          " - " +
                                                          _vm._s(
                                                            model.description
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: { "label-cols-lg": "5" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tProduct icon\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "producticon",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "producticon",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tThis icon is shown to the user in the product selector. Should be 220x160 pixels in size.\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-file", {
                                        attrs: {
                                          placeholder:
                                            _vm.placeHolders
                                              .thumbnailPlaceholder,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.updatePreview()
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.imagePreview
                                        ? _c(
                                            "div",
                                            { staticClass: "thumbnail" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.imagePreview,
                                                  alt: "",
                                                  height: "100",
                                                  width: "100",
                                                },
                                                on: { click: _vm.showSingle },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: _vm.removeThumbnail,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Mask",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("b-form-file", {
                                        attrs: {
                                          placeholder:
                                            _vm.placeHolders.maskPlaceholder,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.updateMaskPreview.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.maskPreview
                                        ? _c(
                                            "div",
                                            { staticClass: "thumbnail" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.maskPreview,
                                                  alt: "",
                                                  srcset: "",
                                                  height: "100",
                                                  width: "100",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: { click: _vm.removeMask },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Product name",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "alpha_dash",
                                          name: "Product name",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Product Name",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedOffering
                                                            .name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedOffering,
                                                            "name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedOffering.name",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Product description",
                                        "label-for": "example-text-input",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        model: {
                                          value: _vm.editedOffering.description,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedOffering,
                                              "description",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editedOffering.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        label: "Variant Name",
                                        "label-for": "block-form1-username",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-5",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.editedOffering
                                                              .variantname ==
                                                            null
                                                              ? true
                                                              : false,
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.editedOffering.variantname =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              rules: "alpha_dash",
                                              name: "Variant Name",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({
                                                  classes,
                                                  errors,
                                                }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "control",
                                                        class: classes,
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editedOffering
                                                                .variantname,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editedOffering,
                                                                "variantname",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "editedOffering.variantname",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: { "label-cols-lg": "5" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tVariant thumbnail\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "variantthumbnail",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "variantthumbnail",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tThis icon is shown to the user in the variant selector when adding the product to the shopping cart\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-file", {
                                        attrs: {
                                          placeholder:
                                            _vm.placeHolders.variantPlaceHolder,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.updateVariantPreview.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.variantPreview
                                        ? _c(
                                            "div",
                                            { staticClass: "thumbnail" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.variantPreview,
                                                  alt: "",
                                                  height: "100",
                                                  width: "100",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: _vm.removeVariant,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-username",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tSize tag\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "sizetag" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "sizetag",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tThis is the size shown to the customer in the app e.g 13 x 13 cm\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-6",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.editedOffering
                                                              .size == null
                                                              ? true
                                                              : false,
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.editedOffering.size =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value: _vm.editedOffering.size,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedOffering,
                                                  "size",
                                                  $$v
                                                )
                                              },
                                              expression: "editedOffering.size",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "block-form1-username",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tProduction Days\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "productionDays",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "productionDays",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tThe amount of working days that production of the offering will take (max)\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "productionDay",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.editedOffering
                                                              .productionDays ==
                                                            null
                                                              ? true
                                                              : false,
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.editedOffering.productionDays =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              rules: "integer",
                                              name: "Production Days",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({
                                                  classes,
                                                  errors,
                                                }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "control",
                                                        class: classes,
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "Production Days",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editedOffering
                                                                .productionDays,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editedOffering,
                                                                "productionDays",
                                                                _vm._n($$v)
                                                              )
                                                            },
                                                            expression:
                                                              "editedOffering.productionDays",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "block-form1-username",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tMinimum Quantity\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "minimumQuantity",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "minimumQuantity",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tWhat is the minimum quantity a customer can order?\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "quantityMin",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.editedOffering
                                                              .quantityMin ==
                                                            null
                                                              ? true
                                                              : false,
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.editedOffering.quantityMin =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              rules: "integer",
                                              name: "minimumQuantity",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({
                                                  classes,
                                                  errors,
                                                }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "control",
                                                        class: classes,
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "Minimum Quanitity",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editedOffering
                                                                .quantityMin,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editedOffering,
                                                                "quantityMin",
                                                                _vm._n($$v)
                                                              )
                                                            },
                                                            expression:
                                                              "editedOffering.quantityMin",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "block-form1-username",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tMaximum Quantity\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "maximumQuantity",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "maximumQuantity",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tWhat is the maximum quantity a customer can order?\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "quantityMax",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.editedOffering
                                                              .quantityMax ==
                                                            null
                                                              ? true
                                                              : false,
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.editedOffering.quantityMax =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              rules: "integer",
                                              name: "maximumQuantity",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({
                                                  classes,
                                                  errors,
                                                }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "control",
                                                        class: classes,
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "Maximum Quantity",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editedOffering
                                                                .quantityMax,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editedOffering,
                                                                "quantityMax",
                                                                _vm._n($$v)
                                                              )
                                                            },
                                                            expression:
                                                              "editedOffering.quantityMax",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "block-form1-username",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tQuality Interval\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "quanityInterval",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "quanityInterval",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tAt what quantity interval can a customer order?\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "qualityInterval",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.editedOffering
                                                              .quantityInterval ==
                                                            null
                                                              ? true
                                                              : false,
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.editedOffering.quantityInterval =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              rules: "integer",
                                              name: "quantityInterval",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({
                                                  classes,
                                                  errors,
                                                }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "control",
                                                        class: classes,
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            placeholder:
                                                              "quantity Interval",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editedOffering
                                                                .quantityInterval,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editedOffering,
                                                                "quantityInterval",
                                                                _vm._n($$v)
                                                              )
                                                            },
                                                            expression:
                                                              "editedOffering.quantityInterval",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "block-form1-username",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tPage width\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "pagewidth" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "pagewidth",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tCalculated @configdpi and without bleed margin\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "integer",
                                          name: "Page Width",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder: "width",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedOffering
                                                            .width,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedOffering,
                                                            "width",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "editedOffering.width",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Page height",
                                        "label-for": "block-form1-username",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "integer",
                                          name: "Page Height",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder: "Height",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedOffering
                                                            .height,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedOffering,
                                                            "height",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "editedOffering.height",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "block-form1-username",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tProduct depth\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "productdepth",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "productdepth",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tDepth of this product in pixels (@configdpi)\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "integer",
                                          name: "Product Depth",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder: "Depth",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedOffering
                                                            .depth,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedOffering,
                                                            "depth",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "editedOffering.depth",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-username",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tBleed Margin\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "bleedmargin",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "bleedmargin",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tSize of the bleed area in pixels ( at @configdpi)\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-7",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.editedOffering
                                                              .bleedmargin ==
                                                            null
                                                              ? true
                                                              : false,
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.editedOffering.bleedmargin =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              rules: "integer",
                                              name: "Bleed Margin",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({
                                                  classes,
                                                  errors,
                                                }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "control",
                                                        class: classes,
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editedOffering
                                                                .bleedmargin,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editedOffering,
                                                                "bleedmargin",
                                                                _vm._n($$v)
                                                              )
                                                            },
                                                            expression:
                                                              "editedOffering.bleedmargin",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "block-form1-username",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tConfig PPI\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "configppi" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "configppi",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tAt what pixels-per-inch value is the highest configured in the editor\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "integer",
                                          name: "Config PPI",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Config DPI",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedOffering
                                                            .configdpi,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedOffering,
                                                            "configdpi",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "editedOffering.configdpi",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "example-text-input",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tQuality PPI\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "qualityppi" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "qualityppi",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tBelow what pixels-per-inch should we display a warning about the image quality?\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "integer",
                                          name: "Quality PPI",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Quality DPI",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedOffering
                                                            .qualitydpi,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedOffering,
                                                            "qualitydpi",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "editedOffering.qualitydpi",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "example-text-input",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tMinimum PPI\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "minimumppi" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "minimumppi",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tWhat is the minimum allowable pixels-per-inch for a photo? User can not resize photo bigger than that.\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "integer",
                                          name: "Minimun PPI",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Minimun DPI",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedOffering
                                                            .minimumdpi,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedOffering,
                                                            "minimumdpi",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "editedOffering.minimumdpi",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "example-text-input",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tMinimum pages\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "minimumpages",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "minimumpages",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tThe minimum number of pages for the product. Includes the front and back cover.\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "integer",
                                          name: "Minimun Pages",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Minimum pages",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedOffering
                                                            .minpages,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedOffering,
                                                            "minpages",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "editedOffering.minpages",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Minimum print pages",
                                        "label-for": "example-text-input",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tMinimum print pages\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "minimumprintpages",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "minimumprintpages",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tThe minimum number of print pages for the product. Includes the front and back cover.\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "integer",
                                          name: "Minimun Print Pages",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Minimum print Pages",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedOffering
                                                            .minprintpages,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedOffering,
                                                            "minprintpages",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "editedOffering.minprintpages",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "example-text-input",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tMaximum pages\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "maximumpages",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "maximumpages",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tThe maximum number of print pages for the product. Includes the front and back cover.\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "integer",
                                          name: "Maximum Pages",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Maximum pages",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedOffering
                                                            .maxpages,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedOffering,
                                                            "maxpages",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "editedOffering.maxpages",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "example-text-input",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tSetup pages\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "setuppages" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "setuppages",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tNumber of pages that will be added to the product in the editor on default\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "integer",
                                          name: "Setup Pages",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Setup pages",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedOffering
                                                            .setuppages,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedOffering,
                                                            "setuppages",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "editedOffering.setuppages",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Page interval",
                                        "label-for": "example-text-input",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tPage Interval\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "pageinterval",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "pageinterval",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tIn what multiple should extra pages be charged?\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "integer",
                                          name: "Page Interval",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Page interval",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedOffering
                                                            .pageinterval,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedOffering,
                                                            "pageinterval",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "editedOffering.pageinterval",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tAdd Blank Pages\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "addblankpages",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "addblankpages",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tIf the number of pages created by the user is not multiple of a 'page intervale', do you want to add blank pages to the print files(s)?\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.editedOffering.addblankpages ===
                                            1
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editedOffering.addblankpages =
                                              $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tPage Quantity\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "pagequality",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "pagequality",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tCan the user change the quantity property of a page in this product?\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.editedOffering.pagequantity ===
                                            1
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editedOffering.pagequantity =
                                              $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "example-text-input",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tShow Bleed\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "showbleed" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "showbleed",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tAdd bleed margin to user interface? (0) No (1) Optional with toggle button, default hidden (2) Yes, always shown (3) Optional with toggle button, default shown\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "integer",
                                          name: "Show Bleed",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Show Bleed",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedOffering
                                                            .showbleed,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedOffering,
                                                            "showbleed",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedOffering.showbleed",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: { "label-cols-lg": "5" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tOverlay Image\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "overlayimage",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "overlayimage",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tUpload an image if you want to show the product in the app with a sem-transparent overlay\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-file", {
                                        attrs: {
                                          placeholder:
                                            _vm.placeHolders.overlayPlaceholder,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.updateOverlayPreview.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.overlayPreview
                                        ? _c(
                                            "div",
                                            { staticClass: "thumbnail" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.overlayPreview,
                                                  alt: "",
                                                  srcset: "",
                                                  height: "100",
                                                  width: "100",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: _vm.removeOverlay,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tColors Supported?\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "colorsupported",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "colorsupported",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tDoes the product support color printing? (i.e not for engraved products)\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.editedOffering.color === 1
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editedOffering.color = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "example-text-input",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tMinimuM fontsize (pt)\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "minimumfontsize",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "minimumfontsize",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tWhat is the minimum fontsize that can be used in this product? (i.e. for engraved products)\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          rules: "integer",
                                          name: "Minimum fontsize",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({ classes, errors }) {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control",
                                                    class: classes,
                                                  },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        placeholder:
                                                          "Minimun fontsize (pt)",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedOffering
                                                            .minfontsize,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedOffering,
                                                            "minfontsize",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedOffering.minfontsize",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(_vm._s(errors[0])),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tPage fill required?\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "pagefillrequired",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "pagefillrequired",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tIs a page fill required? Meaning a photo cannot be sized smaller than the page\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.editedOffering.pagefill === 1
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editedOffering.pagefill = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tStart with right page\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "startwithrightpage",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "startwithrightpage",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tInside of front and back cover is a blank page.\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.editedOffering.startright === 1
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editedOffering.startright =
                                              $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tFixed cover\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "fixedcover" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "fixedcover",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tBoth inside and outside of the book cover is fixed (no editable)\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.editedOffering.fixedcover === 1
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editedOffering.fixedcover =
                                              $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tSpread\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "spread" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "spread",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tDo pages need to be added and removed in pairs (spreads)?\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.editedOffering.spread === 1
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editedOffering.spread = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tPanel Cards\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "panelcards" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "panelcards",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tPanel cards are folded cards\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.editedOffering.panel === 1
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editedOffering.panel = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-for": "example-select",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tFold direction\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "folddirection",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "folddirection",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tIn what direction is the panel card folded? Ignore if the card is not a panel card\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-select",
                                        {
                                          attrs: {
                                            id: "example-select",
                                            plain: "",
                                          },
                                          model: {
                                            value: _vm.editedOffering.panelfold,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editedOffering,
                                                "panelfold",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editedOffering.panelfold",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-select-option",
                                            {
                                              attrs: {
                                                value:
                                                  _vm.editedOffering.panelfold,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.editedOffering.panelfold
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-username",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tSize of Set\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "sizeofset" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "sizeofset",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tIs the card sold as a set to the creator? Then enter the size of each set. Otherwise set 0.\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-8",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.editedOffering
                                                              .set == null
                                                              ? true
                                                              : false,
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.editedOffering.set =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              rules: "integer",
                                              name: "Size of set",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({
                                                  classes,
                                                  errors,
                                                }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "control",
                                                        class: classes,
                                                      },
                                                      [
                                                        _c("b-form-input", {
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editedOffering
                                                                .set,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editedOffering,
                                                                "set",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "editedOffering.set",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tHas back\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "hasback" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "hasback",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tDoes the product have a printed back side for each photo\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.editedOffering.hasback === 1
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editedOffering.hasback = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tVirtual\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "virtual" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "virtual",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tIs this a virtual offering? (consists of multiple sub offerings)\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.editedOffering.virtual === 1
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editedOffering.virtual = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tApply upScaling\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "applyupscaling",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "applyupscaling",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tDo we use upscaling algorithms on photos(when necessary) for this offering\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.editedOffering
                                              .applyUpscaling === 1
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editedOffering.applyUpscaling =
                                              $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\tApply enhancement\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "applyenhancement",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "applyenhancement",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tDo we use enhancement software on photos for this offering?\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.editedOffering
                                              .applyEnhancement === 1
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editedOffering.applyEnhancement =
                                              $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Editor Verision",
                                        "label-for": "block-form-username",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        staticClass: "form-control-altt",
                                        attrs: {
                                          id: "offeringid",
                                          placeholder: "Editor Version",
                                        },
                                        model: {
                                          value:
                                            _vm.editedOffering.editorVersion,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedOffering,
                                              "editorVersion",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "editedOffering.editorVersion ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Type",
                                        "label-for": "example-select",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-select",
                                        {
                                          attrs: {
                                            id: "example-select",
                                            plain: "",
                                          },
                                          model: {
                                            value: _vm.editedOffering.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editedOffering,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression: "editedOffering.type",
                                          },
                                        },
                                        _vm._l(_vm.type, function (ty, index) {
                                          return _c(
                                            "b-form-select-option",
                                            {
                                              key: index,
                                              attrs: {
                                                id: "pdp-data",
                                                value: ty,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(ty) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "show pricing",
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.editedOffering.showPricing === 1
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.editedOffering.showPricing =
                                              $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-tabs",
                    {
                      staticClass: "block",
                      attrs: {
                        "nav-class": "nav-tabs-alt",
                        "content-class": "block-content",
                      },
                      model: {
                        value: _vm.tabIndex1,
                        callback: function ($$v) {
                          _vm.tabIndex1 = $$v
                        },
                        expression: "tabIndex1",
                      },
                    },
                    [
                      _c(
                        "b-tab",
                        { attrs: { title: "Sales Price", active: "" } },
                        [
                          _c("SalesPrice", {
                            attrs: { "edited-offering": _vm.editedOffering },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        { attrs: { title: "Regions" } },
                        [
                          _c("Region", {
                            attrs: { edited: _vm.editedOffering },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        { attrs: { title: "Handling Price" } },
                        [_vm.tabIndex1 == 2 ? _c("Handling") : _vm._e()],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tabs",
                    {
                      staticClass: "block",
                      attrs: {
                        "nav-class": "nav-tabs-alt",
                        "content-class": "block-content",
                      },
                      model: {
                        value: _vm.tabIndex,
                        callback: function ($$v) {
                          _vm.tabIndex = $$v
                        },
                        expression: "tabIndex",
                      },
                    },
                    [
                      _c(
                        "b-tab",
                        { attrs: { title: "Upsell", active: "" } },
                        [_c("Upsell")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        { attrs: { title: "Cross Sell" } },
                        [_vm.tabIndex == 1 ? _c("CrossSell") : _vm._e()],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        { attrs: { title: "Badges" } },
                        [_vm.tabIndex == 2 ? _c("Badges") : _vm._e()],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tab",
                        { attrs: { title: "Display Frame" } },
                        [
                          _vm.tabIndex == 3
                            ? _c("Frame", {
                                attrs: { "route-id": _vm.routeId },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%", height: "100%" } },
    [
      _c(
        "b-modal",
        {
          ref: "item-modal",
          attrs: { id: "bv-modal", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [_vm._v("\n\t\t\tAdd Sub Item\n\t\t")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm.spinner
            ? _c("div", { staticClass: "text-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "spinner-grow text-primary m-5",
                    attrs: { role: "status" },
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                ),
              ])
            : _c(
                "div",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "form-group",
                      attrs: {
                        label: "name ",
                        "label-for": "block-form1-name",
                        "label-cols-lg": "3",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.itemName,
                              callback: function ($$v) {
                                _vm.itemName = $$v
                              },
                              expression: "itemName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-3",
                      attrs: { block: "" },
                      on: { click: _vm.addSubItem },
                    },
                    [_vm._v("\n\t\t\t\tSave\n\t\t\t")]
                  ),
                ],
                1
              ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12", lg: "12", sm: "12" } },
            [
              _c(
                "b-tabs",
                { attrs: { card: "", "content-class": "mt-3", fill: "" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Properties", active: "" } },
                    [
                      _c(
                        "base-block",
                        {
                          attrs: {
                            title: "Lister Item properties",
                            "header-bg": "",
                          },
                        },
                        [
                          _vm.spinner
                            ? _c("div", { staticClass: "text-center" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spinner-grow text-primary m-5",
                                    attrs: { role: "status" },
                                  },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Loading..."),
                                    ]),
                                  ]
                                ),
                              ])
                            : _c(
                                "div",
                                { staticClass: "d-block text-center" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Parent ID:",
                                        "label-for": "example-select1",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-5",
                                                          name: "checkbox-6",
                                                          checked:
                                                            _vm
                                                              .singleProductCategory
                                                              .parentid == null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted",
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleProductCategory.parentid =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "b-form-select",
                                            {
                                              attrs: {
                                                id: "example-select8",
                                                plain: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.singleProductCategory
                                                    .parentid,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.singleProductCategory,
                                                    "parentid",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "singleProductCategory.parentid",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-select-option",
                                                { attrs: { value: null } },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tPlease select an Option\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.productcategory,
                                                function (pdcat, id) {
                                                  return _c(
                                                    "b-form-select-option",
                                                    {
                                                      key: id,
                                                      attrs: {
                                                        value: pdcat.id,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(pdcat.id) +
                                                          " | " +
                                                          _vm._s(pdcat.name) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "PDP ID:",
                                        "label-for": "example-select",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-4",
                                                          name: "checkbox-2",
                                                          checked:
                                                            _vm
                                                              .singleProductCategory
                                                              .pdpid == null
                                                              ? true
                                                              : false,
                                                          "unchecked-value":
                                                            "not_accepted",
                                                        },
                                                        on: {
                                                          change: () =>
                                                            (_vm.singleProductCategory.pdpid =
                                                              null),
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "b-form-select",
                                            {
                                              attrs: {
                                                id: "example-select",
                                                plain: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.singleProductCategory
                                                    .pdpid,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.singleProductCategory,
                                                    "pdpid",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "singleProductCategory.pdpid",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-select-option",
                                                { attrs: { value: null } },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tPlease select an Option\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.pdp,
                                                function (pd, id) {
                                                  return _c(
                                                    "b-form-select-option",
                                                    {
                                                      key: id,
                                                      attrs: { value: pd.id },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(pd.id) +
                                                          " | " +
                                                          _vm._s(pd.name) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        label: "Name: ",
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.singleProductCategory.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.singleProductCategory,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "singleProductCategory.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Auto forward?",
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("b-form-checkbox", {
                                        staticClass: "cameraCheckbox",
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.singleProductCategory
                                              .autoforward === 1
                                              ? true
                                              : false,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.singleProductCategory.autoforward =
                                              $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Image:",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("b-form-file", {
                                        ref: "thumbnail",
                                        attrs: {
                                          id: "thumbnail",
                                          placeholder:
                                            _vm.placeHolders
                                              .thumbnailPlaceholder,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.updateThumbnailPreview.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.singleProductCategory.thumbnail
                                        ? _c(
                                            "div",
                                            { staticClass: "thumbnail" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.singleProductCategory
                                                    .thumbnail,
                                                  alt: "",
                                                  srcset: "",
                                                  height: "100",
                                                  width: "100",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: _vm.removeThumbnail,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Sub Items" },
                      on: { click: () => (_vm.subcategoryDisplay = true) },
                    },
                    [
                      _c(
                        "base-block",
                        { attrs: { title: "Sub Items", "header-bg": "" } },
                        [
                          _c(
                            "b-row",
                            { staticClass: "d-flex justify-content-end" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "d-flex justify-content-end mb-2",
                                  attrs: { md: "6" },
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: { to: "#" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.showItemModal.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tAdd Sub Item\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.subcategoryDisplay
                            ? _c("ProductCategoriesTable", {
                                attrs: { subcategory: _vm.subcategoryDisplay },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Offerings" },
                      on: { click: () => (_vm.offeringDisplay = true) },
                    },
                    [
                      _c(
                        "base-block",
                        { attrs: { title: "Offerings", "header-bg": "" } },
                        [_c("div", { ref: "offeringTable" })]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { Vue, Component, Ref } from 'vue-property-decorator';
import EditOfferingOptionTable from 'components/OfferingOptionTable/EditOfferingOptionTable';
import Template from './template.vue';

@Component({
	components: {
		EditOfferingOptionTable,
	},
})
export default class EditOfferingOption extends Vue.extend(Template) {
	@Ref('editofferingoption') readonly offeringOptionModal!: HTMLFormElement;

	private routeId = this.$route.params.id;

	private isLoading = false;

	protected updateOfferingOptionProperties(): void {
		this.offeringOptionModal.editOfferingOption();
	}
}

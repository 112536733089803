import { Vue, Component } from 'vue-property-decorator';
import RegionTable from 'components/RegionTable';
import Template from './template.vue';

@Component({
	components: {
		RegionTable,
	},
})
export default class Region extends Vue.extend(Template) {
	protected showModal(): void {
		this.$root.$emit(
			'bv::show::modal',
			'region-modal',
			'#regionShow',
		);
	}

	protected routeRegion(id: string): void {
		this.$router.push(`/region/${id}`);
	}
}

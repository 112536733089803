var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data
    ? _c(
        "div",
        { staticClass: "mt-3" },
        [
          _vm.checkOffering[_vm.data.id]
            ? _c("b-form-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.checkOffering[_vm.data.id].vat,
                  callback: function ($$v) {
                    _vm.$set(_vm.checkOffering[_vm.data.id], "vat", $$v)
                  },
                  expression: "checkOffering[data.id].vat",
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { Vue, Component } from 'vue-property-decorator';
import OfferingOptionTable from 'components/OfferingOptionTable';
import Template from './template.vue';

@Component({
	components: {
		OfferingOptionTable,
	},
})
export default class OfferingOption extends Vue.extend(Template) {
	private showModal(): void {
		this.$root.$emit('bv::show::modal',
			'offeringOption-modal',
			'#offeringOptionShow');
	}

	private routeOfferingOption(id: string): void {
		this.$router.push(`/offeringoption/${id}`);
	}
}

import {
	Component,
	Ref,
	Vue,
} from 'vue-property-decorator';
import { saveAs } from 'file-saver';
import * as DB from 'interfaces/database';
import { AddCategoryModel, TabEvent, ImportCatalogModel } from 'interfaces/app';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { BModal } from 'bootstrap-vue';
import Swal from 'sweetalert2';
import mitt from 'mitt';
import { httpClient } from 'utils/http';
import 'vue-loading-overlay/dist/vue-loading.css';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.ThemeCategoryModel>>();
@Component({
	components: {
		TabulatorBtn,
	},
})
export default class CategoryTable extends Vue.extend(Template) {
	@Ref('category-modal')
	private readonly modal!: BModal;

	@Ref('import-modal')
	private readonly importModal!: BModal;

	@Ref('categoryTable')
	private readonly tableReference!: HTMLDivElement;

	protected isLoading = false;

	private offering: DB.OfferingModel[] = [];

	private language: DB.LanguageModel[] = [];

	private selectedRows: DB.ThemeCategoryModel[] = [];

	private table?: Tabulator;

	private placeHolders: { icon: string } = {
		icon: 'Choose a file',
	}

	private search = '';

	private id = '';

	private languageData = null;

	private offeringData = {} as DB.OfferingModel;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private importCatalogData: ImportCatalogModel = {
		id: [],
		offering: {} as DB.OfferingModel,
	}

	private addCategoryData: AddCategoryModel = {
		name: '',
		languageid: null,
		offering: {} as DB.OfferingModel,
	}

	private isLoaded = false;

	private externalId = '';

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				title: 'Icon',
				field: 'icon',
				formatter: 'image',
				formatterParams: {
					height: 300,
					width: 300,
				},
			},
			{
				field: 'name',
				title: 'Name',
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.ThemeCategoryModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	protected mounted(): void {
		eventBus.on(
			'deleteItem',
			this.deleteCategory,
		);
		eventBus.on(
			'editItem',
			this.editCategory,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeDestroy(): void {
		eventBus.off(
			'deleteItem',
			this.deleteCategory,
		);
		eventBus.off(
			'editItem',
			this.editCategory,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.off(
			'rowSelectionChanged',
			this.onSelectionChanged,
		);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData().then(() => this.table?.on(
			'rowSelectionChanged',
			this.onSelectionChanged,
		)).catch((err) => {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
				selectable: true,
				// create a context menu to open row on a new tab
				rowContextMenu: [
					{
						label: 'Open in new tab',
						action: (e, row) => {
							const data = row.getData();
							this.$emit(
								'openInNewTab',
								data.id,
							);
						},
					},
				],
			},
		);
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			orderby: 'serialnumber ASC',
			limit: '0',
		});

		const data = {} as Record<string, string | number>;
		// if the Language data is not empty, set the locale to the selected language
		if (this.languageData && this.languageData !== null) {
			data.locale = this.languageData;
		}
		// if the offering data is not empty, set the groupid and typeid to the selected language
		if (Object.keys(this.offeringData).length !== 0) {
			data.groupid = this.offeringData.groupid;
			data.typeid = this.offeringData.typeid;
		}
		// append data to search params
		parameter.append(
			'where',
			JSON.stringify(data),
		);

		if (this.importCatalogData.id.length > 0) {
			parameter.append(
				'where',
				JSON.stringify({ id: this.importCatalogData.id }),
			);
		}
		this.table?.alert('Loading');
		try {
			const [templateData, offeringData, languageData] = await Promise.all([
				httpClient.get<DB.ThemeCategoryModel[]>(`/api/themecategory?${parameter}`),
				httpClient.get<DB.OfferingModel[]>('/api/offering?limit=0'),
				httpClient.get<DB.LanguageModel[]>('/api/language?limit=0'),
			]);
			this.offering = offeringData.data;
			this.language = languageData.data;
			this.table?.setData(templateData.data);
		} catch (err: any) {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.importModal.hide();
			this.table?.clearAlert();
		}
	}

	protected onSelectionChanged(): void {
		if (this.table) {
			this.selectedRows = this.table.getSelectedData();
		}
	}

	protected exportSelected(): void {
		const jsonString = JSON.stringify(this.selectedRows);
		const blob = new Blob(
			[jsonString],
			{ type: 'application/json' },
		);
		saveAs(
			blob,
			'Data.json',
		);
	}

	protected async exportAll(): Promise<void> {
		this.table?.alert('Loading...');
		try {
			const { data } = await httpClient.get('/api/themecategory?limit=0');
			const jsonString = JSON.stringify(data);
			const blob = new Blob(
				[jsonString],
				{ type: 'application/json' },
			);
			saveAs(
				blob,
				'Data.json',
			);
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.table?.clearAlert();
		}
	}

	protected addCategory(): void {
		this.isLoaded = true;
		httpClient.post(
			'/api/themecategory',
			{
				name: this.addCategoryData.name,
				locale: this.addCategoryData.languageid,
				typeid: this.addCategoryData.offering.typeid,
				groupid: this.addCategoryData.offering.groupid,
			},
		).then((res) => {
			this.$bvToast.toast(
				'Category Added',
				{
					solid: true,
					variant: 'success',
				},
			);
			this.$emit(
				'routeCategory',
				res.data.id,
			);

			return undefined;
		})
			.finally(() => {
				this.isLoaded = false;
			})
			.catch((err) => {
				this.$bvToast.toast(
					`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});
	}

	protected async uploadFile(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.placeHolders.icon = files.filesUploaded[0].filename;
				const { data } = await httpClient.post<DB.ThemeCategoryModel[]>(
					'/api/themecategory/import',
					{
						url: files.filesUploaded[0].url,
						typeid: this.importCatalogData.offering.typeid,
						groupid: this.importCatalogData.offering.groupid,
					},
				);
				this.importCatalogData.id = data.map((item: DB.ThemeCategoryModel) => item.id);
				this.getData();
			},
		};
		this.$client.picker(options).open();
	}

	private editCategory(data: TabEvent<DB.ThemeCategoryModel>['editItem']): void {
		this.$emit(
			'routeCategory',
			data.id,
		);
	}

	private deleteCategory(data: TabEvent<DB.ThemeCategoryModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading...');
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete(`/api/themecategory/${data.id}`).then(() => {
					this.$bvToast.toast(
						'Item Deleted',
						{
							solid: true,
							variant: 'success',
						},
					);
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}
}

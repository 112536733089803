var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%", height: "100%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12", lg: "12", sm: "12" } },
            [
              _c(
                "b-tabs",
                { attrs: { card: "", "content-class": "mt-3", fill: "" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Properties", active: "" } },
                    [
                      _c(
                        "base-block",
                        {
                          attrs: {
                            title: "Bulk Discount properties",
                            "header-bg": "",
                          },
                        },
                        [
                          _vm.spinner
                            ? _c("div", { staticClass: "text-center" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spinner-grow text-primary m-5",
                                    attrs: { role: "status" },
                                  },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Loading..."),
                                    ]),
                                  ]
                                ),
                              ])
                            : _c(
                                "div",
                                { staticClass: "d-block text-center" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "ID:",
                                        "label-for": "example-select1",
                                        "label-cols-lg": "5",
                                        disabled: "",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.singleBulkDiscount.id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.singleBulkDiscount,
                                              "id",
                                              $$v
                                            )
                                          },
                                          expression: "singleBulkDiscount.id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Name:",
                                        "label-for": "example-select1",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "text" },
                                        model: {
                                          value: _vm.singleBulkDiscount.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.singleBulkDiscount,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "singleBulkDiscount.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Pages:",
                                        "label-for": "example-select",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c(
                                            "b-form-select",
                                            {
                                              attrs: {
                                                id: "example-select",
                                                plain: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.singleBulkDiscount.count,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.singleBulkDiscount,
                                                    "count",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "singleBulkDiscount.count",
                                              },
                                            },
                                            _vm._l(
                                              _vm.count,
                                              function (con, id) {
                                                return _c(
                                                  "b-form-select-option",
                                                  {
                                                    key: id,
                                                    attrs: { value: con },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(con) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Active:",
                                        "label-class": "font-w600",
                                        "label-cols-lg": "5",
                                      },
                                    },
                                    [
                                      _c("b-form-checkbox", {
                                        staticClass: "activeCheckbox",
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.singleBulkDiscount.active === 1,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.singleBulkDiscount.active =
                                              $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    { attrs: { title: "Bulk Discount Quantities" } },
                    [
                      _c(
                        "base-block",
                        {
                          attrs: {
                            title: "Bulk Discount Quantities",
                            "header-bg": "",
                          },
                        },
                        [
                          _c(
                            "b-table-simple",
                            {
                              attrs: {
                                responsive: "",
                                "table-class": "table-vcenter",
                              },
                            },
                            [
                              _c(
                                "b-thead",
                                { attrs: { "head-variant": "dark" } },
                                [
                                  _c(
                                    "b-tr",
                                    [
                                      _c("b-th", [_vm._v("From")]),
                                      _vm._v(" "),
                                      _c("b-th", [_vm._v("To")]),
                                      _vm._v(" "),
                                      _c("b-th", [_vm._v("Relative")]),
                                      _vm._v(" "),
                                      _c("b-th", [_vm._v("Absolute")]),
                                      _vm._v(" "),
                                      _c("b-th", [_vm._v("Absolute pp")]),
                                      _vm._v(" "),
                                      _c("b-th", [_vm._v("Actions")]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tbody",
                                _vm._l(
                                  _vm.BulkDiscountQuantities,
                                  function (item, index) {
                                    return _c(
                                      "b-tr",
                                      { key: index },
                                      [
                                        _c(
                                          "b-td",
                                          [
                                            _c("b-form-input", {
                                              staticClass: "form-control-sm",
                                              attrs: { type: "number" },
                                              model: {
                                                value: item.from,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "from",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "item.from",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-td",
                                          [
                                            _c("b-form-input", {
                                              staticClass: "form-control-sm",
                                              attrs: { type: "number" },
                                              model: {
                                                value: item.to,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "to",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "item.to",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-td",
                                          [
                                            _c("b-form-input", {
                                              staticClass: "form-control-sm",
                                              attrs: { type: "number" },
                                              model: {
                                                value: item.relative,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "relative",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "item.relative",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-td",
                                          [
                                            _c("b-form-input", {
                                              staticClass: "form-control-sm",
                                              attrs: { type: "number" },
                                              model: {
                                                value: item.absolute,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "absolute",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "item.absolute",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-td",
                                          [
                                            _c("b-form-input", {
                                              staticClass: "form-control-sm",
                                              attrs: { type: "number" },
                                              model: {
                                                value: item.absolute_q,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "absolute_q",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "item.absolute_q",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-td",
                                          [
                                            _c(
                                              "b-btn-group",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      size: "sm",
                                                      variant: "alt-primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.saveBulkDiscountQuantity(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-fw fa-save",
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      size: "sm",
                                                      variant: "alt-primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeBulkDiscountQuanitity(
                                                          item.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-fw fa-eraser",
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-tfoot",
                                [
                                  _c(
                                    "b-tr",
                                    [
                                      _c(
                                        "b-td",
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    "label-for":
                                                      "example-text-input1",
                                                  },
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      type: "number",
                                                      placeholder: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.BulkQuantityData
                                                          .from,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.BulkQuantityData,
                                                          "from",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "BulkQuantityData.from",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-td",
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                "label-for":
                                                  "example-text-input2",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  type: "number",
                                                  placeholder: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.BulkQuantityData.to,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.BulkQuantityData,
                                                      "to",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "BulkQuantityData.to",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-td",
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                "label-for":
                                                  "example-text-input",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  type: "number",
                                                  placeholder: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.BulkQuantityData
                                                      .relative,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.BulkQuantityData,
                                                      "relative",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "BulkQuantityData.relative",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-td",
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                "label-for":
                                                  "example-text-input",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  type: "number",
                                                  placeholder: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.BulkQuantityData
                                                      .absolute,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.BulkQuantityData,
                                                      "absolute",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "BulkQuantityData.absolute",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-td",
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                "label-for":
                                                  "example-text-input",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  type: "number",
                                                  placeholder: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.BulkQuantityData
                                                      .absolute_q,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.BulkQuantityData,
                                                      "absolute_q",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "BulkQuantityData.absolute_q",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-td",
                                        [
                                          _c(
                                            "b-btn-group",
                                            { staticClass: "mb-4" },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    size: "sm",
                                                    variant: "alt-primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.addBulkQuantity()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa fa-fw fa-save",
                                                  }),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Valid Offerings" },
                      on: { click: () => (_vm.offeringDisplay = true) },
                    },
                    [
                      _c(
                        "base-block",
                        {
                          attrs: { title: "Valid Offerings", "header-bg": "" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("b-form-group", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ ariaDescribedby }) {
                                      return [
                                        _c("b-form-checkbox-group", {
                                          ref: "checkboxRef",
                                          attrs: {
                                            id: "checkbox-group-1",
                                            options: _vm.productGroup,
                                            "value-field": "id",
                                            "text-field": "name",
                                            "aria-describedby": ariaDescribedby,
                                            name: "flavour-1",
                                          },
                                          nativeOn: {
                                            change: function ($event) {
                                              return _vm.toggleOfferings(
                                                _vm.selectedOfferings,
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.selectedOfferings,
                                            callback: function ($$v) {
                                              _vm.selectedOfferings = $$v
                                            },
                                            expression: "selectedOfferings",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.offering, function (off, index) {
                            return _c(
                              "b-row",
                              { key: index },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { md: "4" } },
                                  [
                                    _c("b-form-checkbox", {
                                      attrs: {
                                        switch: "",
                                        size: "lg",
                                        checked: _vm.selectedOfferings.includes(
                                          off.groupid
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("b-col", { attrs: { md: "8" } }, [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(off.id) +
                                        " - " +
                                        _vm._s(off.name) +
                                        " - " +
                                        _vm._s(off.description)
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
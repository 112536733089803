var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6", lg: "6", sm: "12" } },
            [
              _c(
                "base-block",
                { attrs: { title: "Hyperlink properties", "header-bg": "" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-block text-left" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Language",
                            "label-for": "example-select",
                            "label-cols-md": "4",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "required", name: "Language" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c(
                                          "b-form-select",
                                          {
                                            staticClass: "mb-2",
                                            attrs: {
                                              id: "example-select",
                                              plain: "",
                                            },
                                            model: {
                                              value:
                                                _vm.singleHyperlink.languageid,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.singleHyperlink,
                                                  "languageid",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singleHyperlink.languageid",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-form-select-option",
                                              { attrs: { value: "" } },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.language,
                                              function (lang, id) {
                                                return _c(
                                                  "b-form-select-option",
                                                  {
                                                    key: id,
                                                    attrs: { value: lang.id },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(lang.name) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "PDP",
                            "label-for": "example-select",
                            "label-cols-md": "4",
                          },
                        },
                        [
                          _c(
                            "b-form-select",
                            {
                              staticClass: "mb-2",
                              attrs: { id: "example-select", plain: "" },
                              model: {
                                value: _vm.singleHyperlink.pdpid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.singleHyperlink, "pdpid", $$v)
                                },
                                expression: "singleHyperlink.pdpid",
                              },
                            },
                            [
                              _c(
                                "b-form-select-option",
                                { attrs: { value: null } },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.pdp, function (pd, id) {
                                return _c(
                                  "b-form-select-option",
                                  { key: id, attrs: { value: pd.id } },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(pd.name) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Region",
                            "label-for": "example-select",
                            "label-cols-md": "4",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "required", name: "Region" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c(
                                          "b-form-select",
                                          {
                                            staticClass: "mb-2",
                                            attrs: {
                                              id: "example-select",
                                              plain: "",
                                            },
                                            model: {
                                              value:
                                                _vm.singleHyperlink.regionid,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.singleHyperlink,
                                                  "regionid",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singleHyperlink.regionid",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-form-select-option",
                                              { attrs: { value: null } },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.region,
                                              function (reg, id) {
                                                return _c(
                                                  "b-form-select-option",
                                                  {
                                                    key: id,
                                                    attrs: { value: reg.id },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(reg.name) +
                                                        "\n\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Product Category",
                            "label-for": "example-select",
                            "label-cols-md": "4",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-1",
                                              name: "checkbox-1",
                                              checked:
                                                _vm.singleHyperlink
                                                  .productcategoryid == null
                                                  ? true
                                                  : false,
                                              "unchecked-value": "not_accepted",
                                            },
                                            on: {
                                              change: () =>
                                                (_vm.singleHyperlink.productcategoryid =
                                                  null),
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c(
                                "b-form-select",
                                {
                                  staticClass: "mb-2",
                                  attrs: { id: "example-select", plain: "" },
                                  model: {
                                    value:
                                      _vm.singleHyperlink.productcategoryid,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.singleHyperlink,
                                        "productcategoryid",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "singleHyperlink.productcategoryid",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null } },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.productCategory,
                                    function (prodcat, id) {
                                      return _c(
                                        "b-form-select-option",
                                        {
                                          key: id,
                                          attrs: { value: prodcat.id },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(prodcat.name) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            "label-for": "block-form1-name",
                            "label-cols-md": "4",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-row" },
                                    [
                                      _c("div", { staticClass: "mr-2" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\tTag\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "tip",
                                        attrs: { id: "tag" },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "tag",
                                triggers: "hover",
                                placement: "bottom",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tWith What tag is this hyperlink identified in the Creator? (optional)\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-1",
                                              name: "checkbox-1",
                                              checked:
                                                _vm.singleHyperlink.tag == ""
                                                  ? true
                                                  : false,
                                              "unchecked-value": "not_accepted",
                                            },
                                            on: {
                                              change: () =>
                                                (_vm.singleHyperlink.tag = ""),
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: { rules: "alpha_dash", name: "Tag" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ classes, errors }) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "control",
                                            class: classes,
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.singleHyperlink.tag,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.singleHyperlink,
                                                    "tag",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "singleHyperlink.tag",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(errors[0])),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "URL: ",
                            "label-for": "block-form1-name",
                            "label-cols-md": "4",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { type: "text", required: "" },
                            model: {
                              value: _vm.singleHyperlink.url,
                              callback: function ($$v) {
                                _vm.$set(_vm.singleHyperlink, "url", $$v)
                              },
                              expression: "singleHyperlink.url",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-class": "font-w600",
                            "label-cols-md": "4",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-row" },
                                    [
                                      _c("div", { staticClass: "mr-2" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\tDelegate\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "tip",
                                        attrs: { id: "delegate" },
                                      }),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "delegate",
                                triggers: "hover",
                                placement: "bottom",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tSend user to this hyperlink through delegate request?\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("b-form-checkbox", {
                            attrs: {
                              switch: "",
                              size: "lg",
                              checked:
                                _vm.singleHyperlink.delegate === 1
                                  ? true
                                  : false,
                            },
                            on: {
                              change: function ($event) {
                                _vm.singleHyperlink.delegate = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
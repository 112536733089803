var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-block",
    { attrs: { "header-bg": "" } },
    [
      _vm.spinner
        ? _c("div", { staticClass: "text-center" }, [
            _c(
              "div",
              {
                staticClass: "spinner-grow text-primary m-5",
                attrs: { role: "status" },
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            ),
          ])
        : _c(
            "b-table-simple",
            { attrs: { responsive: "", "table-class": "table-vcenter" } },
            [
              _c(
                "b-thead",
                { attrs: { "head-variant": "dark" } },
                [
                  _c(
                    "b-tr",
                    [
                      _c("b-th", [_vm._v(" Id")]),
                      _vm._v(" "),
                      _c("b-th", [_vm._v("Name")]),
                      _vm._v(" "),
                      _c("b-th", [_vm._v("Use")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.badges, function (badg) {
                return _c(
                  "b-tbody",
                  { key: badg.id },
                  [
                    _c("BadgeRow", {
                      attrs: { badg: badg, checkbadge: _vm.checkbadge },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
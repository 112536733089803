export default function debounce(exec: (...args: any[]) => Promise<any>, interval: number): (...args: any[]) => ReturnType<typeof exec> {
	let resolves: Array<(value?: unknown) => void> = [];
	let timer: ReturnType<typeof setTimeout>;

	return async (...args: unknown[]) => {
		clearTimeout(timer);
		timer = setTimeout(
			() => {
				const result = exec(...args);
				resolves.forEach((resolve) => resolve(result));
				resolves = [];
			},
			interval,
		);

		return new Promise((resolve) => resolves.push(resolve));
	};
}

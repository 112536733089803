import {
	Vue, Component, Watch,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from '../../utils/http';
import BadgeRow from './badgeRow.vue';

@Component({
	components: {
		BadgeRow,
	},
})
export default class Badges extends Vue {
	private badges: DB.BadgeModel[] = [];

	private checkbadge: DB.BadgeOfferingModel[] = [];

	private selected = false;

	private spinner = false;

	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	mounted(): void {
		this.getBadges();
		this.getBadgeOff();
	}

	// get all badges
	@Watch('loggedIn')
	async getBadges(): Promise<void> {
		try {
			this.spinner = true;
			const badge = await httpClient.get<DB.BadgeModel[]>('/api/badge');
			this.badges = badge.data;
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		}
	}

	async getBadgeOff(): Promise<void> {
		try {
			this.spinner = true;
			const badgeoffering = await httpClient.get<DB.BadgeOfferingModel[]>(`/api/badgeoffering?where={"offeringid": ${this.$route.params.id} }`);
			this.checkbadge = badgeoffering.data;
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.spinner = false;
		}
	}
}

import { Vue, Component } from 'vue-property-decorator';
import AppSettingTable from 'components/AppSettingTable/';
import Template from './template.vue';

@Component({
	components: {
		AppSettingTable,
	},
})
export default class AppSetting extends Vue.extend(Template) {
	protected showModal(): void {
		this.$root.$emit(
			'bv::show::modal',
			'appsetting-modal',
			'#showBtn',
		);
	}
}

import { Vue, Component } from 'vue-property-decorator';
import PdpTable from 'components/PdpTable/template.vue';

@Component({
	components: {
		PdpTable,
	},
})
export default class Pdp extends Vue {
	showModal(): void {
		this.$root.$emit(
			'bv::show::modal',
			'pdp-modal',
			'#pdpShow',
		);
	}

	routePdp(id: string): void {
		this.$router.push(`/pdp/${id}`);
	}
}

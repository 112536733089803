import {
	Vue, Component, Watch, Prop, Ref,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import CountryFlagGlob from 'components/Tabulator/CountryFlag';
import { PickerOptions, PickerResponse } from 'filestack-js';
import mitt from 'mitt';
import { httpClient } from 'utils/http';
import { TabEvent } from 'interfaces/app';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import OfferingTable from '../OfferingTable';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.CurrencyModel>>();
@Component({
	components: {
		CountryFlagGlob,
		TabulatorSwitchBox,
		OfferingTable,
	},
})
export default class EditRegionTable extends Vue.extend(Template) {
	@Ref('countryTable')
	private readonly countryTableReference!: HTMLDivElement;

	@Ref('currencyTable')
	private readonly currencyTableReference!: HTMLDivElement;

	private singleRegion = {} as DB.RegionModel;

	private region: DB.RegionCurrencyModel[] = [];

	private productCategory: DB.ProductCategoryModel[] = [];

	private language: DB.LanguageModel[] = [];

	private loaded = false;

	private placeHolders = {
		mobile: 'Choose a file',
		desktop: 'Choose a file',
	}

	private offeringDisplay = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private tabIndex = 0;

	private isLoading = false;

	private checkRegion = {} as Record<string, DB.RegionCurrencyModel>;

	private checkOffering = {} as Record<string, DB.RegionOfferingModel>;

	private rowData: DB.OfferingModel[] = []

	private countryGrid= {
		columnDefs: [] as ColumnDefinitionExtended[],
		table: null as unknown as Tabulator,
	}

	private currencyGrid = {
		columnDefs: [] as ColumnDefinitionExtended[],
		table: null as unknown as Tabulator,
	}

	@Prop({
		type: String,
		required: true,
	})
	private readonly routeId!: string;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.currencyGrid.columnDefs = [
			{
				field: 'name',
				title: 'Currency',
			},
			{
				title: 'ISO code',
				field: 'id',
			},
			{
				title: 'Available In region?',
				formatter: (cell: CellComponentExtended<DB.CurrencyModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							data,
							eventBus,
							checked: Boolean(data && this.checkRegion[data.id]),
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
		this.countryGrid.columnDefs = [
			{
				field: 'iso',
				title: 'ISO',
			},
			{
				title: 'Name',
				field: 'name',
				formatter: (cell: CellComponentExtended<DB.CountryModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: CountryFlagGlob,
						props: {
							data,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
				headerFilter: true,
			},
		];
	}

	protected mounted(): void {
		this.getSingleRegion();
		this.$nextTick(() => {
			this.getOfferingRegion();
		});
		eventBus.on(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		this.tableInitialization();
	}

	private switchBoxChanged(data: TabEvent<DB.CurrencyModel>['switchBoxChanged']): void {
		this.currencyGrid.table.alert('Loading...');
		if (data.event) {
			httpClient
				.post(
					'/api/regioncurrency',
					{
						regionid: parseInt(
							this.routeId,
							10,
						),
						currencyid: data.params.id,
					},
				)
				.then((response) => {
					this.checkRegion[response.data.currencyid] = response.data;
					return undefined;
				})
				.finally(() => {
					this.currencyGrid.table.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		} else {
			httpClient
				.delete(`/api/regioncurrency/${this.checkRegion[data.params.id].id}`)
				.then(() => {
					delete this.checkRegion[data.params.id];
					return undefined;
				})
				.finally(() => {
					this.currencyGrid.table.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
	}

	protected beforeDestroy() {
		eventBus.off(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		this.currencyGrid.table.destroy();
		this.countryGrid.table.destroy();
	}

	private tableInitialization(): void {
		this.countryGrid.table = new Tabulator(
			this.countryTableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.countryGrid.columnDefs,
			},
		);
		this.currencyGrid.table = new Tabulator(
			this.currencyTableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.currencyGrid.columnDefs,
			},
		);
	}

	protected async getCountryData(): Promise<void> {
		const route = this.routeId;
		const parameter = new URLSearchParams({
			where: JSON.stringify({ regionid: route }),
			limit: '0',
		});
		this.countryGrid.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.CountryModel[]>(`/api/country?${parameter}`);
			this.countryGrid.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.countryGrid.table?.clearAlert();
		}
	}

	protected async getCurrencyData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});
		this.currencyGrid.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.CurrencyModel[]>(`/api/currency?${parameter}`);
			this.currencyGrid.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.currencyGrid.table?.clearAlert();
		}
	}

	@Watch('loggedIn')
	private async getOfferingRegion(): Promise<void> {
		if (this.loggedIn) {
			const params = new URLSearchParams({
				where: JSON.stringify({ regionid: this.routeId }),
				limit: '0',
			});

			try {
				const resp = await httpClient.get<DB.RegionCurrencyModel[]>(`/api/regioncurrency?${params}`);
				this.checkRegion = resp.data.reduce(
					(obj, item) => ({
						...obj,
						[item.currencyid]: item,
					}),
					{},
				);
			} catch (error: any) {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			}
		}
		return undefined;
	}

	@Watch('loggedIn')
	private async getSingleRegion(): Promise<DB.RegionModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<DB.RegionModel>(`/api/region/${this.routeId}`);
				this.singleRegion = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singleRegion;
			} catch (error: any) {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected editRegion(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			httpClient.put<DB.RegionModel>(
				`/api/region/${this.routeId}`,
				{ ...this.singleRegion },
			).then(() => {
				this.$bvToast.toast(
					'Region Edited successfully',
					{
						solid: true,
						variant: 'success',
					},
				);
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$bvToast.toast(
						`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
		return undefined;
	}

	protected async updateLogoDesktop(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleRegion.logo_desktop = files.filesUploaded[0].url;
				this.placeHolders.desktop = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	protected async updateLogoMobile(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singleRegion.logo_mobile = files.filesUploaded[0].url;
				this.placeHolders.mobile = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	protected removeDesktop(): void {
		this.singleRegion.logo_desktop = '';
	}

	protected removeMobile(): void {
		this.singleRegion.logo_mobile = '';
	}
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6", lg: "6", sm: "12" } },
            [
              _c(
                "base-block",
                { attrs: { title: "3D Model properties", "header-bg": "" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-block text-center" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Name: ",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "control" },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.singleModel3d.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.singleModel3d, "name", $$v)
                                  },
                                  expression: "singleModel3d.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        { attrs: { label: "GLB file", "label-cols-lg": "4" } },
                        [
                          _c("b-form-file", {
                            ref: "glb",
                            attrs: {
                              id: "glb",
                              placeholder: _vm.placeHolders.glb,
                            },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.updateGlbPreview.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm.glb
                                ? _c(
                                    "b-alert",
                                    { attrs: { show: "", dismissible: "" } },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.glb) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "HDR texture file",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c("b-form-file", {
                            ref: "hdr",
                            attrs: {
                              id: "hdr",
                              placeholder: _vm.placeHolders.hdr,
                            },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.updateHdrPreview.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm.hdr
                                ? _c(
                                    "b-alert",
                                    { attrs: { show: "", dismissible: "" } },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.hdr) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Environmental intensity ",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              rules: "double",
                              name: "Environmental Intensity",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleModel3d.envIntensity,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singleModel3d,
                                                "envIntensity",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "singleModel3d.envIntensity",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Camera Alpha",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "double", name: "Camera Alpha" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleModel3d.cameraAlpha,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singleModel3d,
                                                "cameraAlpha",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "singleModel3d.cameraAlpha",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Automatic rotation:",
                            "label-class": "font-w600",
                            "label-cols-lg": "3",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "cameraCheckbox",
                            attrs: {
                              switch: "",
                              size: "lg",
                              checked:
                                _vm.singleModel3d.cameraAutoRotate === 1
                                  ? true
                                  : false,
                            },
                            on: {
                              change: function ($event) {
                                _vm.singleModel3d.cameraAutoRotate = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Camera Beta",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "double", name: "Camera Beta" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value: _vm.singleModel3d.cameraBeta,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singleModel3d,
                                                "cameraBeta",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "singleModel3d.cameraBeta",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Camera Radius",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "double", name: "Camera Radius" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleModel3d.cameraRadius,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singleModel3d,
                                                "cameraRadius",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "singleModel3d.cameraRadius",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Camera Rotation Speed",
                            "label-for": "example-select",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-2",
                                              name: "checkbox-1",
                                              checked:
                                                _vm.singleModel3d
                                                  .cameraRotationSpeed == null
                                                  ? true
                                                  : false,
                                              "unchecked-value": "not_accepted",
                                            },
                                            on: {
                                              change: () =>
                                                (_vm.singleModel3d.cameraRotationSpeed =
                                                  null),
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  rules: "double",
                                  name: "Camera Rotation Speed",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ classes, errors }) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "control",
                                            class: classes,
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: { type: "text" },
                                              model: {
                                                value:
                                                  _vm.singleModel3d
                                                    .cameraRotationSpeed,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.singleModel3d,
                                                    "cameraRotationSpeed",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "singleModel3d.cameraRotationSpeed",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(errors[0])),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Camera Zoom Limit Down",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              rules: "double",
                              name: "Camera Zoom Limit Down",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleModel3d
                                                .cameraZoomLimitDown,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singleModel3d,
                                                "cameraZoomLimitDown",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "singleModel3d.cameraZoomLimitDown",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Camera Zoom Limit Up",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: {
                              rules: "double",
                              name: "Camera Zoom Limit Up",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.singleModel3d
                                                .cameraZoomLimitUp,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.singleModel3d,
                                                "cameraZoomLimitUp",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "singleModel3d.cameraZoomLimitUp",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Print Material Name",
                            "label-for": "example-select",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-2",
                                              name: "checkbox-1",
                                              checked:
                                                _vm.singleModel3d
                                                  .printMaterialName == null
                                                  ? true
                                                  : false,
                                              "unchecked-value": "not_accepted",
                                            },
                                            on: {
                                              change: () =>
                                                (_vm.singleModel3d.printMaterialName =
                                                  null),
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("ValidationProvider", {
                                attrs: {
                                  rules: "alpha",
                                  name: "Print Material Name",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function ({ classes, errors }) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "control",
                                            class: classes,
                                          },
                                          [
                                            _c("b-form-input", {
                                              attrs: { type: "text" },
                                              model: {
                                                value:
                                                  _vm.singleModel3d
                                                    .printMaterialName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.singleModel3d,
                                                    "printMaterialName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "singleModel3d.printMaterialName",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("span", [
                                              _vm._v(_vm._s(errors[0])),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Texture Material Name",
                            "label-for": "example-select",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-textureMaterialName",
                                              name: "checkbox-textureMaterialName",
                                              checked:
                                                _vm.singleModel3d
                                                  .textureMaterialName == null
                                                  ? true
                                                  : false,
                                              "unchecked-value": "not_accepted",
                                            },
                                            on: {
                                              change: () =>
                                                (_vm.singleModel3d.textureMaterialName =
                                                  null),
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.singleModel3d.textureMaterialName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.singleModel3d,
                                      "textureMaterialName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "singleModel3d.textureMaterialName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Transparency Mode",
                            "label-for": "example-select",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            {
                              scopedSlots: _vm._u([
                                {
                                  key: "append",
                                  fn: function () {
                                    return [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass: "input-group-text-alt",
                                          staticStyle: { cursor: "pointer" },
                                        },
                                        [
                                          _c("b-form-checkbox", {
                                            attrs: {
                                              id: "checkbox-transparencyMode",
                                              name: "checkbox-transparencyMode",
                                              checked:
                                                _vm.singleModel3d
                                                  .transparencyMode == null
                                                  ? true
                                                  : false,
                                              "unchecked-value": "not_accepted",
                                            },
                                            on: {
                                              change: () =>
                                                (_vm.singleModel3d.transparencyMode =
                                                  null),
                                            },
                                          }),
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.singleModel3d.transparencyMode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.singleModel3d,
                                      "transparencyMode",
                                      $$v
                                    )
                                  },
                                  expression: "singleModel3d.transparencyMode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: { label: "Texture Url", "label-cols-lg": "4" },
                        },
                        [
                          _c("b-form-file", {
                            ref: "texture",
                            attrs: {
                              id: "texture",
                              placeholder: _vm.placeHolders.texture,
                            },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.updateTexturePreview.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm.texture
                                ? _c(
                                    "b-alert",
                                    { attrs: { show: "", dismissible: "" } },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.texture) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
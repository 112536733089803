import { Vue, Component, Ref } from 'vue-property-decorator';
import EditFlexGroupTable from 'components/FlexGroupTable/EditFlexGroupTable';
import Template from './template.vue';

@Component({
	components: {
		EditFlexGroupTable,
	},
})
export default class EditFlexGroup extends Vue.extend(Template) {
	@Ref('editflexgroup') readonly flexGroupModal!: HTMLFormElement;

	private routeId = this.$route.params.id;

	private isLoading = false;

	protected updateFlexGroupProperties(): void {
		this.flexGroupModal.editFlexGroup();
	}
}

import {
	Vue, Component, Ref,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import ShoppingCartOfferingRenderer from 'components/Tabulator/ShoppingCartOfferingRenderer';
import { httpClient, creatorDomain } from 'utils/http';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import Template from './template.vue';

function convertArrayToObject(array: (DB.OfferingModel)[]): Record<string, DB.OfferingModel[]> {
	return array.reduce(
		(obj, item) => ({
			...obj,
			[item.id]: item,
		}),
		{},
	);
}

@Component({
	components: {
		ShoppingCartOfferingRenderer,
	},
})
export default class ShoppingCartItems extends Vue.extend(Template) {
	@Ref('shoppingCartItemsTable')
	private allTableReference!: HTMLDivElement;

	private shoppingCartItem: DB.ShoppingCartItemModel[] = [];

	private offerings: Record<string, DB.OfferingModel[]> = {};

	private perPage = 20;

	private isLoading = false;

	private table?: Tabulator;

	private domain = creatorDomain;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private rowData = null

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected mounted(): void {
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeDestroy() {
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this?.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.allTableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'productid',
				title: 'Product ID',
				formatter: (cell) => {
					const rowData = cell.getData();
					const link = document.createElement('a');
					link.href = `${this.domain}/app/showcase/${cell.getValue()}/${rowData.read_token}`;
					link.innerText = cell.getValue();
					link.addEventListener(
						'click',
						(e) => {
							e.preventDefault();
							window.location.href = `${this.domain}/app/showcase/${cell.getValue()}/${rowData.read_token}`;
						},
					);
					return link;
				},
			},
			{
				title: 'Offering',
				field: 'id',
				formatter: (cell: CellComponentExtended<DB.ShoppingCartItemModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: ShoppingCartOfferingRenderer,
						props: {
							data,
							offerings: this.offerings,
						},
					});
					instance.$mount();
					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});

		this.table?.alert('Loading');
		try {
			const [shoppingData, offeringData] = await Promise.all([
				httpClient.get<DB.ShoppingCartItemModel[]>(`/api/user/${this.$route.params.id}/shoppingcartitems?${parameter}`),
				httpClient.get<DB.OfferingModel[]>('/api/offering?limit=0'),
			]);
			this.offerings = convertArrayToObject(offeringData.data);
			this.table?.setData(shoppingData.data);
		} catch (err: any) {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.table?.clearAlert();
		}
	}
}

/* eslint-disable no-script-url */
<template>
	<!-- Header -->
	<header id="page-header">
		<slot>
			<!-- Header Content -->
			<div class="content-header">
				<!-- Left Section -->
				<div class="d-flex align-items-center">
					<!-- Toggle Sidebar -->
					<base-layout-modifier
						action="sidebarToggle"
						size="sm"
						variant="dual"
						class="mr-2 d-lg-none"
					>
						<i class="fa fa-fw fa-bars" />
					</base-layout-modifier>
					<!-- END Toggle Sidebar -->

					<!-- Toggle Mini Sidebar -->
					<base-layout-modifier
						action="sidebarMiniToggle"
						size="sm"
						variant="dual"
						class="mr-2 d-none d-lg-inline-block"
					>
						<i class="fa fa-fw fa-ellipsis-v" />
					</base-layout-modifier>
					<!-- END Toggle Mini Sidebar -->

					<!-- Apps Modal Toggle Button -->
					<!-- <b-button
						v-b-modal.one-modal-apps
						variant="dual"
						size="sm"
						class="mr-2"
					>
						<i class="fa fa-fw fa-cubes" />
					</b-button> -->
					<!-- END Apps Modal Toggle Button -->

					<!-- Apps Modal -->

					<!-- END Apps Modal -->
				</div>
				<!-- END Left Section -->

				<!-- Right Section -->
				<div class="d-flex align-items-center">
					<!-- User Dropdown -->
					<b-dropdown
						ref="oneDropdownDefaultUser"
						size="sm"
						variant="dual"
						class="d-inline-block ml-2"
						menu-class="p-0 border-0 dropdown-menu-md"
						right
						no-caret
					>
						<template #button-content>
							<div class="d-flex align-items-center">
								<img
									class="rounded-circle"
									:src="$auth.user.picture"
									alt="Header Avatar"
									style="width: 21px;"
								>
								<span class="d-none d-sm-inline-block ml-2">{{ $auth.user.name }}</span>
								<i
									class="fa fa-fw fa-angle-down d-none d-sm-inline-block ml-1 mt-1"
								/>
							</div>
						</template>
						<li @click="$refs.oneDropdownDefaultUser.hide(true)">
							<div class="p-3 text-center bg-primary-dark rounded-top">
								<img
									class="img-avatar img-avatar48 img-avatar-thumb"
									:src="$auth.user.picture"
									alt="Avatar"
								>
								<p class="mt-2 mb-0 text-white font-w500">
									{{ $auth.user.name }}
								</p>
							</div>
							<div class="p-2">
								<div
									class="dropdown-item d-flex align-items-center justify-content-between"
									style="cursor:pointer"
									@click="logout"
								>
									<span class="font-size-sm font-w500">Log Out</span>
								</div>
							</div>
						</li>
					</b-dropdown>
					<!-- END User Dropdown -->

					<!-- Toggle Side Overlay -->
					<!-- <base-layout-modifier
						action="sideOverlayToggle"
						variant="dual"
						size="sm"
						class="ml-2"
					>
						<i class="fa fa-fw fa-list-ul fa-flip-horizontal" />
					</base-layout-modifier> -->
					<!-- END Toggle Side Overlay -->
				</div>
				<!-- END Right Section -->
			</div>
			<!-- END Header Content -->

			<!-- Header Search -->
			<div
				id="page-header-search"
				class="overlay-header bg-white"
				:class="{ show: $store.state.settings.headerSearch }"
				@keydown.esc="() => $store.commit('headerSearch', { mode: 'off' })"
			>
				<div class="content-header">
					<b-form
						class="w-100"
						@submit.stop.prevent="onSubmit"
					>
						<b-input-group>
							<b-input-group-prepend>
								<base-layout-modifier
									action="headerSearchOff"
									variant="alt-danger"
								>
									<i class="fa fa-fw fa-times-circle" />
								</base-layout-modifier>
							</b-input-group-prepend>
							<b-form-input
								v-model="baseSearchTerm"
								placeholder="Search or hit ESC.."
							/>
						</b-input-group>
					</b-form>
				</div>
			</div>
			<!-- END Header Search -->

			<!-- Header Loader -->
			<div
				id="page-header-loader"
				class="overlay-header bg-white"
				:class="{ show: $store.state.settings.headerLoader }"
			>
				<div class="content-header">
					<div class="w-100 text-center">
						<i class="fa fa-fw fa-circle-notch fa-spin" />
					</div>
				</div>
			</div>
			<!-- END Header Loader -->
		</slot>
	</header>
	<!-- END Header -->
</template>

<script>
export default {
	name: 'BaseHeader',
	props: {
		classes: String,
	},
	data() {
		return {
			baseSearchTerm: '',
			notifications: [
				{
					href: '',
					icon: 'fa fa-fw fa-check-circle text-success',
					title: 'You have a new follower',
					time: '15 min ago',
				},
				{
					href: '',
					icon: 'fa fa-fw fa-plus-circle text-primary',
					title: '1 new sale, keep it up',
					time: '22 min ago',
				},
				{
					href: '',
					icon: 'fa fa-fw fa-times-circle text-danger',
					title: 'Update failed, restart server',
					time: '26 min ago',
				},
				{
					href: '',
					icon: 'fa fa-fw fa-plus-circle text-primary',
					title: '2 new sales, keep it up',
					time: '33 min ago',
				},
				{
					href: '',
					icon: 'fa fa-fw fa-user-plus text-success',
					title: 'You have a new subscriber',
					time: '41 min ago',
				},
				{
					href: '',
					icon: 'fa fa-fw fa-check-circle text-success',
					title: 'You have a new follower',
					time: '42 min ago',
				},
			],
		};
	},
	mounted() {
		document.addEventListener(
			'keydown',
			this.eventHeaderSearch,
		);
	},
	destroyed() {
		document.removeEventListener(
			'keydown',
			this.eventHeaderSearch,
		);
	},
	methods: {
		onSubmit() {
			this.$router.push(`/backend/pages/generic/search?${this.baseSearchTerm}`);
		},
		eventHeaderSearch(event) {
			// When ESCAPE key is hit close the header search section
			if (event.which === 27) {
				event.preventDefault();
				this.$store.commit(
					'headerSearch',
					{ mode: 'off' },
				);
			}
		},
		login() {
			this.$auth.loginWithRedirect();
		},

		logout() {
			this.$auth.logout();
			this.$router.push({ path: '/' });
		},
	},
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {},
      [
        _c("base-page-heading", {
          staticClass: "d-print-none",
          attrs: { title: "API Permissions", subtitle: "" },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row content" },
          [
            _c("ApiPermissionsTable", {
              on: { routeApiPermission: _vm.routeApiPermission },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
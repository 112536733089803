var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoaded, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "addcrossSell-modal",
          attrs: { id: "crossSell-modal", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [_vm._v("\n\t\t\tCreate New Cross Sell\n\t\t")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-block text-center" },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: { label: "Name: ", "label-for": "block-form1-name" },
                },
                [
                  _c("ValidationProvider", {
                    attrs: { rules: "required", name: "name" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ classes, errors }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "control", class: classes },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.name,
                                    callback: function ($$v) {
                                      _vm.name = $$v
                                    },
                                    expression: "name",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(errors[0]))]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Cross Sell ID:",
                    "label-for": "example-select",
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c(
                        "b-form-select",
                        {
                          attrs: { id: "example-select", plain: "" },
                          model: {
                            value: _vm.crosssellid,
                            callback: function ($$v) {
                              _vm.crosssellid = $$v
                            },
                            expression: "crosssellid",
                          },
                        },
                        [
                          _c(
                            "b-form-select-option",
                            { attrs: { value: undefined } },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tPlease select an Option\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.offerings, function (off, id) {
                            return _c(
                              "b-form-select-option",
                              { key: id, attrs: { value: off.id } },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(off.id) +
                                    " | " +
                                    _vm._s(off.size) +
                                    " - " +
                                    _vm._s(off.description) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { block: "" },
              on: {
                click: function ($event) {
                  return _vm.addCrossSell()
                },
              },
            },
            [_vm._v("\n\t\t\tSave\n\t\t")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "d-flex justify-content-end mb-4" },
        [
          _c(
            "b-col",
            {
              staticClass: "my-1",
              attrs: { sm: "5", md: "3", "align-self": "center" },
            },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Language:",
                    "label-for": "search-group-select",
                    "label-cols-sm": "6",
                    "label-cols-md": "4",
                    "label-cols-lg": "4",
                    "label-align-sm": "right",
                    "label-size": "sm",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "search-group-select",
                      size: "sm",
                      options: _vm.languageOptions,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.translateTableData(_vm.language)
                      },
                    },
                    model: {
                      value: _vm.language,
                      callback: function ($$v) {
                        _vm.language = $$v
                      },
                      expression: "language",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "base-block",
        { attrs: { title: "Existing Cross Sell", "header-bg": "" } },
        [_c("div", { ref: "crossSellTable" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
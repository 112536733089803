/* eslint-disable implicit-arrow-linebreak */
import { Vue, Component, Watch } from 'vue-property-decorator';
import { httpClient } from 'utils/http';

@Component({})
export default class Crosssell extends Vue {
	private Offerings = []

	private crossItemHidden = false;

	// getter for authenticated user
	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	@Watch('loggedIn')
	async getOffering(): Promise<void> {
		if (this.loggedIn) {
			// get all offerings from the server
			httpClient
				.get('/api/offering?limit=0')
				.then((response) => {
					this.Offerings = response.data;
					return null;
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
	}
}

import { Vue, Component, Watch } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { httpClient } from 'utils/http';
import { PlaceHolderModel } from 'interfaces/app';
import PageTemplate from '../PageTemplate';
import PageObjectTable from '../PageObject';
import Template from './template.vue';

@Component({
	components: {
		PageTemplate,
		PageObjectTable,
	},
})
export default class ThemeEditPageTable extends Vue.extend(Template) {
	private singlePage = {} as Record<string, DB.PageModel | number | string | null>;

	private layout: DB.LayoutModel[] = [];

	private routeId = this.$route.params.id;

	private pageId = this.$route.params.pageid;

	private loaded = false;

	private placeHolders: Pick<PlaceHolderModel, 'icon'> = {
		icon: 'Choose a file',
	}

	private visible = false;

	private spinner = false;

	private display = {
		pagetemplate: false,
		pageobject: false,
	} as Record<string, boolean>

	private index = 0;

	private rowHeight = 60;

	private isLoading = false;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected mounted(): void {
		this.getSingleCategory();
		this.getLayout();
	}

	@Watch('loggedIn')
	private async getSingleCategory(): Promise<Record<string, DB.PageModel | number | string | null> | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<Record<string, DB.PageModel>>(`/api/page/${this.pageId}`);
				this.singlePage = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singlePage;
			} catch (error: any) {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	@Watch('loggedIn')
	private async getLayout(): Promise<DB.LayoutModel[] | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			try {
				const resp = await httpClient.get<DB.LayoutModel[]>(`/api/theme/${this.routeId}/layouts`);
				this.layout = resp.data;
				// eslint-disable-next-line consistent-return
				return this.layout;
			} catch (error: any) {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	protected displayPages(property: string): void {
		this.display[property] = true;
	}

	protected setPropertyNull(property: string): void {
		this.singlePage[property] = null;
	}

	protected checkPropertyNull(property: string): boolean {
		return this.singlePage[property] === null;
	}

	protected checkPropertyTrue(property: string): boolean {
		return this.singlePage[property] === 1;
	}

	protected updateProperty(property: string, event: number): void {
		this.singlePage[property] = event;
	}

	protected editTheme(): Promise<void> | undefined {
		if (this.loggedIn) {
			this.isLoading = true;
			return httpClient.put<DB.ThemeModel>(`/api/page/${this.pageId}`,
				{ ...this.singlePage }).then(() => {
				this.$bvToast.toast('Theme Edited successfully',
					{
						solid: true,
						variant: 'success',
					});
				return undefined;
			})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((error) => {
					this.$bvToast.toast(
						`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
		return Promise.resolve(undefined);
	}

	protected routeLayout(id: string): void {
		this.$emit(
			'routeLayout',
			`${id}`,
		);
	}

	protected routeTemplate(id: string): void {
		this.$emit(
			'routeTemplate',
			`${id}`,
		);
	}

	protected routePageObject(id: string): void {
		this.$emit(
			'routePageObject',
			`${id}`,
		);
	}

	protected updateIcon(): void {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.singlePage.icon = files.filesUploaded[0].url;
				this.placeHolders.icon = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	protected removeIcon(): void {
		this.singlePage.icon = '';
	}
}

import {
	Vue, Component, Watch, Prop, Ref,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { namespace } from 'vuex-class';
import { getModule } from 'vuex-module-decorators';
import mitt from 'mitt';
import OffTable from 'store/offerings/offeringTable';
import TabulatorSwitchBox from 'components/Tabulator/TabulatorSwitchBox';
import { httpClient } from 'utils/http';
import { TabEvent } from 'interfaces/app';
import {
	CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import Template from './template.vue';

const OfferingsTable = namespace('OfferingTable');

interface OfferingWithGroupName extends DB.OfferingModel {
	groupname: string;
}

const eventBus = mitt<TabEvent<DB.OfferingModel>>();
@Component({
	components: {
		TabulatorSwitchBox,
	},
})
export default class OfferingTable extends Vue.extend(Template) {
	@Ref('badgeoffering')
	private readonly tableReference!: HTMLDivElement;

	private isLoaded = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100, 200, 300, 400, 500];

	private checkOffering: Record<string, DB.BadgeOfferingModel> = {};

	private columnDefs: ColumnDefinitionExtended[] = [];

	private table?: Tabulator;

	private perPage = 20;

	private rowData: DB.OfferingModel[] | OfferingWithGroupName[] = [];

	private search = null;

	@Prop({
		type: String,
	})
	public readonly routeId!: string;

	@OfferingsTable.Getter
	private getGroupName!: Array<DB.ProductGroupModel>;

	@OfferingsTable.Getter
	protected getJustGroupName!: Array<string>;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	public beforeMount(): void {
		this.columnDefs = [
			{ field: 'id', title: 'Offering ID' },
			{
				title: 'External ID',
				field: 'externalid',
			},
			{
				title: 'Name',
				formatter: (cell: CellComponentExtended<DB.OfferingModel>) => {
					const data = cell.getData();
					const name = document.createElement('div');
					name.textContent = `${data.name} ${data.size}`;

					const description = document.createElement('div');
					description.textContent = data.description ?? null;

					const container = document.createElement('div');
					container.appendChild(name);
					container.appendChild(description);

					return container;
				},
			},
			{
				title: 'Available',
				field: 'url',
				titleFormatter: () => {
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							checked: false,
							text: 'Available in Region',
							eventName: 'headerSwitchBoxChanged',
							eventBus,
						},
					});

					instance.$mount();
					return (instance.$el as HTMLElement);
				},
				formatter: (cell: CellComponentExtended<DB.OfferingModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorSwitchBox,
						props: {
							checked: Boolean(data && this.checkOffering[data.id]),
							data,
							eventBus,
						},
					});

					instance.$mount();
					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	public mounted(): void {
		eventBus.on(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		eventBus.on(
			'headerSwitchBoxChanged',
			this.headerSwitchBoxChanged,
		);
		getModule(
			OffTable,
			this.$store,
		);
		this.$nextTick(() => {
			this.$store.dispatch('OfferingTable/getGroupNameAction');
		});
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
		this.table.on(
			'tableBuilt',
			() => {
				this.getData();
			},
		);
	}

	private switchBoxChanged(data: TabEvent<DB.OfferingModel>['switchBoxChanged']): void {
		this.table?.alert('Loading');
		if (data.event) {
			httpClient
				.post(
					'/api/badgeoffering',
					{
						offeringid: data.params.id,
						badgeid: parseInt(
							this.routeId,
							10,
						),
					},
				)
				.then((response) => {
					this.checkOffering[response.data.offeringid] = response.data;
					const allChecked = this.table?.getData().every((item) => this.checkOffering[item.id]);
					this.updateHeaderBox(allChecked as boolean);
					this.table?.scrollToRow(response.data.offeringid);
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		} else {
			httpClient
				.delete(`/api/badgeoffering/${this.checkOffering[data.params.id].id}`)
				.then(() => {
					delete this.checkOffering[data.params.id];
					const allChecked = this.table?.getData().every((item) => this.checkOffering[item.id]);
					this.updateHeaderBox(allChecked as boolean);
					this.table?.scrollToRow(data.params.id);
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
	}

	// This function checks/unchecks all of the switchboxes in the grid row and updates the checkOffering object
	private headerSwitchBoxChanged(data: TabEvent<DB.OfferingModel>['headerSwitchBoxChanged']): void {
		this.isLoaded = true;
		const allOfferingid: number[] = [];
		this.table?.getData().map((node) => {
			if (node) {
				allOfferingid.push(node.id);
			}
			return undefined;
		});

		const offeringid = Object.values(this.checkOffering)
			.filter((item) => allOfferingid.includes(item.offeringid))
			.map((item) => item.id);

		const parameter = new URLSearchParams({
			where: JSON.stringify({
				id: offeringid,
			}),
		});

		// Create postData array, but only include offering IDs that are not checked
		const postData = allOfferingid
			.filter((offeringId) => !Object.keys(this.checkOffering).includes(offeringId.toString()))
			.map((offeringId) => ({
				offeringid: offeringId,
				badgeid: parseInt(
					this.routeId,
					10,
				),
			}));

		const url = data.event ? '/api/badgeoffering' : `/api/badgeoffering?${parameter}`;
		const method = data.event ? 'post' : 'delete';

		httpClient[method](
			url,
			data.event ? postData : undefined,
		)
			.then((response) => {
				if (data.event) {
					response.data.forEach((item: DB.BadgeOfferingModel) => {
						this.checkOffering[item.offeringid] = item;
						this.table?.redraw(true);
					});
				} else {
					allOfferingid.forEach((id) => {
						delete this.checkOffering[id];
						this.table?.redraw(true);
					});
				}
				return undefined;
			})
			.finally(() => {
				this.isLoaded = false;
			})
			.catch((err) => {
				this.$bvToast.toast(
					`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});
	}

	// This function updates the header formatter switchbox anytime the table renders or redrawn
	private updateHeaderBox(updateCellRenderer: boolean): void {
		const columnDef = this.table?.getColumnDefinitions().map((column) => {
			if (column.field === 'url') {
				return {
					...column,
					title: 'Available',
					field: 'url',
					titleFormatter: updateCellRenderer ? () => {
						const allChecked = this.table?.getData().every((item) => this.table?.getData().length !== 0 && this.checkOffering[item.id]);
						const instance = createInstance({
							component: TabulatorSwitchBox,
							props: {
								checked: Boolean(allChecked && this.table?.getData().length !== 0),
								text: 'Available in Region',
								eventName: 'headerSwitchBoxChanged',
								eventBus,
							},
						});

						instance.$mount();
						return (instance.$el as HTMLElement);
					} : column.titleFormatter,
					formatter: (cell: CellComponentExtended<DB.OfferingModel>) => {
						const data = cell.getData();
						const instance = createInstance({
							component: TabulatorSwitchBox,
							props: {
								checked: Boolean(data && this.checkOffering[data.id]),
								data,
								eventBus,
							},
						});

						instance.$mount();
						return (instance.$el as HTMLElement);
					},
				};
			}
			return column;
		}) as ColumnDefinitionExtended[];

		// update the column definitions
		this.table?.setColumns(columnDef);
	}

	public beforeDestroy() {
		eventBus.off(
			'switchBoxChanged',
			this.switchBoxChanged,
		);
		eventBus.off(
			'headerSwitchBoxChanged',
			this.headerSwitchBoxChanged,
		);
		this.table?.destroy();
	}

	protected groupIdFilterFunction(): void {
		const Allfiltered = this.rowData.filter((data) => {
			if (data.groupid !== undefined) {
				return data.groupid === this.search;
			}
			return data;
		});
		this.table?.setData(Allfiltered);
		const allChecked = this.table?.getData().every((item) => this.checkOffering[item.id]);
		if (allChecked) {
			this.updateHeaderBox(true);
		} else {
			this.updateHeaderBox(false);
		}
	}

	@Watch('loggedIn')
	private async getData(): Promise<void> {
		if (this.table) {
			const offeringParameter = new URLSearchParams({
				limit: '0',
			});

			const badgeOfferingParameter = new URLSearchParams({
				where: JSON.stringify({ badgeid: this.routeId }),
				limit: '0',
			});

			this.table.alert('Loading');
			try {
				const [offeringData, badgeOfferingData] = await Promise.all([
					httpClient.get<DB.OfferingModel[]>(`/api/offering?${offeringParameter}`),
					httpClient.get<DB.BadgeOfferingModel[]>(`/api/badgeoffering?${badgeOfferingParameter}`),
				]);

				this.rowData = offeringData.data;
				this.checkOffering = Object.fromEntries(
					badgeOfferingData.data.map((item) => [item.offeringid, item]),
				);

				this.table.setData(this.rowData);
			} catch (error: any) {
				this.$bvToast.toast(
					error.message,
					{
						solid: true,
						variant: 'danger',
					},
				);
			} finally {
				this.table.clearAlert();
			}
		}
	}
}

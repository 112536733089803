import { Vue, Component } from 'vue-property-decorator';
import EditApiPermissionTable from 'components/ApiPermissionsTable/EditApiPermission/';
import Template from './template.vue';

@Component({
	components: {
		EditApiPermissionTable,
	},
})
export default class EditApiPermission extends Vue.extend(Template) {
	private routeId = this.$route.params.id;

	protected showModal(): void {
		this.$root.$emit(
			'bv::show::modal',
			'editapipermission-modal',
			'#showBtn',
		);
	}
}

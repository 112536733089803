var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%", height: "100%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12", lg: "12", sm: "12" } },
            [
              _c(
                "b-tabs",
                { attrs: { card: "", "content-class": "mt-3", fill: "" } },
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Page properties", active: "" } },
                    [
                      _c(
                        "base-block",
                        {
                          attrs: { title: "Page properties", "header-bg": "" },
                        },
                        [
                          _vm.spinner
                            ? _c("div", { staticClass: "text-center" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spinner-grow text-primary m-5",
                                    attrs: { role: "status" },
                                  },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Loading..."),
                                    ]),
                                  ]
                                ),
                              ])
                            : _c(
                                "div",
                                { staticClass: "d-block text-center" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tname\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "name" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "name",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe name of the page for internal references\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value: _vm.singlePage.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.singlePage,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "singlePage.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tWidth\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "width" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "width",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe width of the page in pixels at @configdpi\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "text",
                                              disabled: "",
                                            },
                                            model: {
                                              value: _vm.singlePage.width,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.singlePage,
                                                  "width",
                                                  $$v
                                                )
                                              },
                                              expression: "singlePage.width",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tHeight\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "height" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "height",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe height of the page in pixel at @configdpi\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "text",
                                              disabled: "",
                                            },
                                            model: {
                                              value: _vm.singlePage.height,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.singlePage,
                                                  "height",
                                                  $$v
                                                )
                                              },
                                              expression: "singlePage.height",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tBleed\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "bleed" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "bleed",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe bleed of the page in pixels at @configdpi\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "text",
                                              disabled: "",
                                            },
                                            model: {
                                              value: _vm.singlePage.offset,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.singlePage,
                                                  "offset",
                                                  $$v
                                                )
                                              },
                                              expression: "singlePage.offset",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tBackground color\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "bgcolor" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "bgcolor",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe background color of the page(in hex or as smartTag)\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-color",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.checkPropertyNull(
                                                              "color"
                                                            ),
                                                          "unchecked-value":
                                                            "not_accepted",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.setPropertyNull(
                                                              "color"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm.singlePage.backgroundcolor,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.singlePage,
                                                  "backgroundcolor",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singlePage.backgroundcolor",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: { "label-cols-lg": "4" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tBackground image\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "bgimage" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "bgimage",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe background image of the page\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-file", {
                                        ref: "logodesktop",
                                        attrs: {
                                          id: "thumbnail",
                                          placeholder: _vm.placeHolders.icon,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.updateIcon.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.singlePage.icon
                                        ? _c(
                                            "div",
                                            { staticClass: "thumbnail" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.singlePage.icon,
                                                  alt: "",
                                                  srcset: "",
                                                  height: "100",
                                                  width: "100",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: { click: _vm.removeIcon },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: { "label-cols-lg": "4" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tBackground Pattern\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "bgpattern" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "bgpattern",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe background image that will be tiled across the page\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-file", {
                                        ref: "logodesktop",
                                        attrs: {
                                          id: "thumbnail",
                                          placeholder:
                                            _vm.placeHolders.backgroundpattern,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.updateBackgroundPattern.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.singlePage.backgroundpattern
                                        ? _c(
                                            "div",
                                            { staticClass: "thumbnail" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.singlePage
                                                    .backgroundpattern,
                                                  alt: "",
                                                  srcset: "",
                                                  height: "100",
                                                  width: "100",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.removeBackgroundPattern,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tSerial number\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "serialnumber",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "serialnumber",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe serialnumber defines the position of the page in the product\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value:
                                                _vm.singlePage.serialnumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.singlePage,
                                                  "serialnumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singlePage.serialnumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-md": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tEditable\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "editable" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "editable",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tIs the page editable by the user\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.checkPropertyTrue("editable"),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateProperty(
                                              "editable",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-md": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tMovable\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "movable" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.checkPropertyTrue("movable"),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateProperty(
                                              "movable",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Page Template" },
                      on: {
                        click: function ($event) {
                          return _vm.displayPages("pagetemplate")
                        },
                      },
                    },
                    [
                      _vm.display.pagetemplate
                        ? _c("PageTemplate", {
                            attrs: {
                              "route-id": _vm.routeId,
                              "page-id": _vm.pageId,
                              "single-page-template": _vm.singlePage,
                            },
                            on: { routeTemplate: _vm.routeTemplate },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Page Object" },
                      on: {
                        click: function ($event) {
                          return _vm.displayPages("pageobject")
                        },
                      },
                    },
                    [
                      _vm.display.pageobject
                        ? _c("PageObjectTable", {
                            attrs: {
                              "route-id": _vm.routeId,
                              "page-id": _vm.pageId,
                            },
                            on: { routePageObject: _vm.routePageObject },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { Vue, Component } from 'vue-property-decorator';
import ShippingTable from 'components/ShippingTable';
import Template from './template.vue';

@Component({
	components: {
		ShippingTable,
	},
})
export default class Shipping extends Vue.extend(Template) {
	showModal(): void {
		this.$root.$emit(
			'bv::show::modal',
			'shipping-modal',
			'#shippingShow',
		);
	}
}

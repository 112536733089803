import {
	VuexModule, Module, Mutation, Action,
} from 'vuex-module-decorators';
import Vue from 'vue';
import * as DB from 'interfaces/database';
import { httpClient } from '../../utils/http';

@Module({ namespaced: true, name: 'OfferingTable' })
class OfferingTable extends VuexModule {
	private groupName: DB.ProductGroupModel[] = [];

	@Mutation
	setGroupName(payload: DB.ProductGroupModel[]): void {
		this.groupName = payload;
	}

	@Action
	async getGroupNameAction(): Promise<void> {
		if (Vue.prototype.$auth.isAuthenticated) {
			return httpClient.get('/api/productgroup?limit=0').then((res) => {
				this.context.commit(
					'OfferingTable/setGroupName',
					res.data,
					{ root: true },
				);
				return undefined;
			}).catch((err) => {
				Vue.prototype.$app.$bvToast.toast(
					`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});
		}
		return undefined;
	}

	get getGroupName(): Array<DB.ProductGroupModel> {
		return this.groupName;
	}

	get getJustGroupName(): string[] {
		return this.groupName.map((item) => item.name);
	}
}

export default OfferingTable;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c(
        "base-block",
        { attrs: { title: "Order Info", "header-bg": "" } },
        [
          _vm.spinner
            ? _c("div", { staticClass: "text-center" }, [
                _c("div", {
                  staticClass: "spinner-grow text-primary m-5",
                  attrs: { role: "status" },
                }),
              ])
            : _c(
                "b-table-simple",
                { attrs: { responsive: "", "table-class": "table-vcenter" } },
                [
                  _c("b-thead", { attrs: { "head-variant": "light" } }),
                  _vm._v(" "),
                  _c(
                    "b-tbody",
                    [
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Order ID")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(" " + _vm._s(_vm.orders.id) + " "),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Order ID")]),
                          _vm._v(" "),
                          _vm._l(_vm.orderItems, function (order) {
                            return _c(
                              "b-td",
                              {
                                key: order.id,
                                staticStyle: { display: "flex" },
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "orderItem",
                                        params: { orderItemId: order.id },
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\tView OrderItem " +
                                        _vm._s(order.id) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" User ID")]),
                          _vm._v(" "),
                          _c(
                            "b-td",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "UserProperties",
                                      params: { id: _vm.orders.userid },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.orders.userid) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Order Date")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(" " + _vm._s(_vm.orders.dateadded) + " "),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Currency")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(" " + _vm._s(_vm.orders.currency) + " "),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Paid")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.paid ? "Yes" : "No") + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Payment Method")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.paymentmethod) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Shipping Price")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.price_shipping) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Handling Price")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.price_handling) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Product Price")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.price_product) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Total tax")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(" " + _vm._s(_vm.orders.total_tax) + " "),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Voucher ID")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(" " + _vm._s(_vm.orders.voucherid) + " "),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Voucher discount")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.discount_voucher) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Total Price")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(" " + _vm._s(_vm.orders.salesvalue) + " "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "base-block",
        { attrs: { title: "Billing Address", "header-bg": "" } },
        [
          _vm.spinner
            ? _c("div", { staticClass: "text-center" }, [
                _c("div", {
                  staticClass: "spinner-grow text-primary m-5",
                  attrs: { role: "status" },
                }),
              ])
            : _c(
                "b-table-simple",
                { attrs: { responsive: "", "table-class": "table-vcenter" } },
                [
                  _c("b-thead", { attrs: { "head-variant": "light" } }),
                  _vm._v(" "),
                  _c(
                    "b-tbody",
                    [
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Name")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(" " + _vm._s(_vm.orders.billing_name) + " "),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Address ")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.billing_address1) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Zipcode")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.billing_zipcode) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" City")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(" " + _vm._s(_vm.orders.billing_city) + " "),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" State ")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.billing_state) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Country ")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.billing_country) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Email ")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.billing_email) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Phone number")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.billing_phone) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "base-block",
        { attrs: { title: "Shipping Address", "header-bg": "" } },
        [
          _vm.spinner
            ? _c("div", { staticClass: "text-center" }, [
                _c("div", {
                  staticClass: "spinner-grow text-primary m-5",
                  attrs: { role: "status" },
                }),
              ])
            : _c(
                "b-table-simple",
                { attrs: { responsive: "", "table-class": "table-vcenter" } },
                [
                  _c("b-thead", { attrs: { "head-variant": "light" } }),
                  _vm._v(" "),
                  _c(
                    "b-tbody",
                    [
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Name")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.shipping_name) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Address ")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.shipping_address1) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Zipcode")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.shipping_zipcode) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" City")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.shipping_city) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" State ")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.shipping_state) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Country ")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orders.shipping_country) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { Vue, Component, Watch } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';

type commonObjectType = {
	[key: string]: DB.CountryModel[] | DB.AffiliateModel[] | DB.CurrencyModel[];
};

function convertArrayToObject(array: (DB.CountryModel | DB.AffiliateModel | DB.CurrencyModel)[]): commonObjectType {
	return array.reduce(
		(obj, item) => ({
			...obj,
			[item.id]: item,
		}),
		{},
	);
}

@Component({})
export default class UserPropertiesBox extends Vue {
	private user: DB.UserModel[] = [];

	private tracker = {};

	private currencies = {};

	private countries = {};

	private spinner = false;

	created(): void {
		this.fetchData();
	}

	get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	@Watch('loggedIn')
	async fetchData(): Promise<void> {
		if (this.loggedIn) {
			this.spinner = true;
			try {
				const response = await httpClient.get<DB.UserModel[]>(`/api/user/${this.$route.params.id}`);
				this.user = response.data;
				const country = await httpClient.get<DB.CountryModel[]>('/api/country?limit=0');
				this.countries = convertArrayToObject(country.data);
				const trackers = await httpClient.get<DB.AffiliateModel[]>('/api/affiliate?limit=0');
				this.tracker = convertArrayToObject(trackers.data);
				const currency = await httpClient.get<DB.CurrencyModel[]>('/api/currency?limit=0');
				this.currencies = convertArrayToObject(currency.data);
			} catch (e: any) {
				this.$bvToast.toast(
					`${e.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			} finally {
				this.spinner = false;
			}
		}
	}
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2 mb-2" },
    [
      _c("img", { attrs: { src: _vm.url, height: _vm.size, width: _vm.size } }),
      _vm._v(" "),
      _vm.isEditable
        ? _c(
            "b-button",
            { staticClass: "mt-3", on: { click: _vm.editImage } },
            [_c("i", { staticClass: "fa fa-file-upload" })]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "base-block",
    {
      attrs: { rounded: "", title: "Cross Sell items", "header-bg": "" },
      scopedSlots: _vm._u([
        {
          key: "options",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  attrs: { type: "submit", size: "sm", variant: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.crossItemHidden = !_vm.crossItemHidden
                    },
                  },
                },
                [_vm._v("\n\t\t\tAdd cross-sell\n\t\t")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.crossItemHidden,
              expression: "crossItemHidden",
            },
          ],
          attrs: { lg: "12" },
        },
        [
          _c(
            "b-form-group",
            { attrs: { label: "Name", "label-for": "example-text-input" } },
            [
              _c(
                "b-form-select",
                { attrs: { id: "example-select", plain: "" } },
                _vm._l(_vm.Offerings, function (Off) {
                  return _c(
                    "b-form-select-option",
                    { key: Off.id, attrs: { value: Off.id } },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(Off.id) +
                          " - " +
                          _vm._s(Off.name) +
                          " - " +
                          _vm._s(Off.description) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-button", { attrs: { type: "submit", variant: "primary" } }, [
            _vm._v("\n\t\t\tSave\n\t\t"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "b-table-simple",
        { attrs: { responsive: "", "table-class": "table-vcenter" } },
        [
          _c(
            "b-thead",
            { attrs: { "head-variant": "dark" } },
            [
              _c(
                "b-tr",
                [
                  _c(
                    "b-th",
                    {
                      staticClass: "text-center",
                      staticStyle: { width: "50px" },
                    },
                    [_vm._v("\n\t\t\t\t\t#\n\t\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c("b-th", [_vm._v("Offering ID")]),
                  _vm._v(" "),
                  _c("b-th", [_vm._v("Theme ID")]),
                  _vm._v(" "),
                  _c("b-th", [_vm._v("Actions")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-tbody"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
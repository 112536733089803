import { Vue, Component } from 'vue-property-decorator';
import OfferingsTable from 'components/OfferingsTable';
import Template from './template.vue';

@Component({
	components: {
		OfferingsTable,
	},
})
export default class Offerings extends Vue.extend(Template) {
	private name = 'Offerings'

	protected routeOffering(id: string): void {
		this.$router.push(`/offerings/${id}`);
	}

	protected openTab(id: string): void {
		const route = this.$router.resolve({
			name: 'editOffering',
			params: {
				id,
			},
		});
		window.open(
			route.href,
			'_blank',
		);
	}
}

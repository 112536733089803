var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%", height: "100%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12", lg: "12", sm: "12" } },
            [
              _c(
                "b-tabs",
                { attrs: { card: "", "content-class": "mt-3", fill: "" } },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Themecategory properties", active: "" },
                    },
                    [
                      _c(
                        "base-block",
                        {
                          attrs: {
                            title: "Themecategory properties",
                            "header-bg": "",
                          },
                        },
                        [
                          _vm.spinner
                            ? _c("div", { staticClass: "text-center" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "spinner-grow text-primary m-5",
                                    attrs: { role: "status" },
                                  },
                                  [
                                    _c("span", { staticClass: "sr-only" }, [
                                      _vm._v("Loading..."),
                                    ]),
                                  ]
                                ),
                              ])
                            : _c(
                                "div",
                                { staticClass: "d-block text-center" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tLayout ID\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "layoutid" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "layoutid",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe Layout ID that will be selected by default if the user create product with this theme.\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-1",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.checkPropertyNull(
                                                              "layoutid"
                                                            ),
                                                          "unchecked-value":
                                                            "not_accepted",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.setPropertyNull(
                                                              "layoutid"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "b-form-select",
                                            {
                                              attrs: {
                                                id: "block-form-layoutid",
                                              },
                                              model: {
                                                value: _vm.singleTheme.layoutid,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.singleTheme,
                                                    "layoutid",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "singleTheme.layoutid",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-form-select-option",
                                                { attrs: { value: null } },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.layout,
                                                function (lay, id) {
                                                  return _c(
                                                    "b-form-select-option",
                                                    {
                                                      key: id,
                                                      attrs: {
                                                        value: lay.layoutid,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(lay.name) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tTheme name\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "themename" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "themename",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tShow below icon in app\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value: _vm.singleTheme.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.singleTheme,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "singleTheme.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tTheme description\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "themedescription",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "themedescription",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tShow below theme name in app\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-1",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.checkPropertyNull(
                                                              "description"
                                                            ),
                                                          "unchecked-value":
                                                            "not_accepted",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.setPropertyNull(
                                                              "description"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c("b-form-textarea", {
                                            attrs: {
                                              id: "textarea-small",
                                              size: "sm",
                                            },
                                            model: {
                                              value:
                                                _vm.singleTheme.description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.singleTheme,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singleTheme.description",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: { "label-cols-lg": "4" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tTheme Icon\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "icon" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "icon",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tShown in the app when selecting theme. Should br=e 140x100 pixels in size.\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-file", {
                                        ref: "logodesktop",
                                        attrs: {
                                          id: "thumbnail",
                                          placeholder: _vm.placeHolders.icon,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.updateIcon.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.singleTheme.icon
                                        ? _c(
                                            "div",
                                            { staticClass: "thumbnail" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.singleTheme.icon,
                                                  alt: "",
                                                  srcset: "",
                                                  height: "100",
                                                  width: "100",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: { click: _vm.removeIcon },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t✖ Remove\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tDefault page color\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "DefaultPageColor",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "DefaultPageColor",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tset to default page background color. Set to NULL if product color should not be editable by user.\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-color",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.checkPropertyNull(
                                                              "color"
                                                            ),
                                                          "unchecked-value":
                                                            "not_accepted",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.setPropertyNull(
                                                              "color"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value: _vm.singleTheme.color,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.singleTheme,
                                                  "color",
                                                  $$v
                                                )
                                              },
                                              expression: "singleTheme.color",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tMin. photos\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "minphotos" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "minphotos",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tMinimum amount of photos the user needs to select for this theme.\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-minphotos",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.checkPropertyNull(
                                                              "minphotos"
                                                            ),
                                                          "unchecked-value":
                                                            "not_accepted",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.setPropertyNull(
                                                              "minphotos"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value: _vm.singleTheme.minphotos,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.singleTheme,
                                                  "minphotos",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singleTheme.minphotos",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        "label-for": "block-form1-name",
                                        "label-cols-lg": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tMax. photos\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "maxphotos" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "maxphotos",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tThe Maximum amount of photos the user needs to select for this theme.\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "b-input-group-text",
                                                    {
                                                      staticClass:
                                                        "input-group-text-alt",
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-checkbox", {
                                                        attrs: {
                                                          id: "checkbox-maxphotos",
                                                          name: "checkbox-1",
                                                          checked:
                                                            _vm.checkPropertyNull(
                                                              "maxphotos"
                                                            ),
                                                          "unchecked-value":
                                                            "not_accepted",
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            return _vm.setPropertyNull(
                                                              "maxphotos"
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tNull\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "text" },
                                            model: {
                                              value: _vm.singleTheme.maxphotos,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.singleTheme,
                                                  "maxphotos",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "singleTheme.maxphotos",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-md": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\toffline\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: { id: "offline" },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "offline",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tSet theme to available offline\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.checkPropertyTrue("offline"),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateProperty(
                                              "offline",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-md": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tphotoselect\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "photoselect",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "photoselect",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tShow photo selection step when building product\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.checkPropertyTrue(
                                              "photoselect"
                                            ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateProperty(
                                              "photoselect",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-md": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tlayoutselect\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "layoutselect",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "layoutselect",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tShow layout selection step when building product?\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.checkPropertyTrue(
                                              "layoutselect"
                                            ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateProperty(
                                              "layoutselect",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        "label-class": "font-w600",
                                        "label-cols-md": "4",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-2" },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\tDynamic Layout\n\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "tip",
                                                    attrs: {
                                                      id: "dynamicLayout",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "dynamicLayout",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tShow dynamic layout?\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-form-checkbox", {
                                        attrs: {
                                          switch: "",
                                          size: "lg",
                                          checked:
                                            _vm.checkPropertyTrue(
                                              "dynamicLayout"
                                            ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateProperty(
                                              "dynamicLayout",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Themes Pages Table" },
                      on: {
                        click: function ($event) {
                          return _vm.displayPages("themepage")
                        },
                      },
                    },
                    [
                      _vm.display.themepage
                        ? _c("ThemesPagesTable", {
                            attrs: {
                              "route-id": _vm.routeId,
                              "page-id": _vm.pageId,
                            },
                            on: { routeThemePages: _vm.routeThemePages },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Theme Categories" },
                      on: {
                        click: function ($event) {
                          return _vm.displayPages("themecategory")
                        },
                      },
                    },
                    [
                      _vm.display.themecategory
                        ? _c("ThemesCategories", {
                            attrs: { "single-theme": _vm.singleTheme },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Theme BorderImage" },
                      on: {
                        click: function ($event) {
                          return _vm.displayPages("themeborderimage")
                        },
                      },
                    },
                    [
                      _vm.display.themeborderimage
                        ? _c("ThemeBorderImage", {
                            attrs: { "single-theme": _vm.singleTheme },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Theme Layouts" },
                      on: {
                        click: function ($event) {
                          return _vm.displayPages("themelayout")
                        },
                      },
                    },
                    [
                      _vm.display.themelayout
                        ? _c("ThemeLayout", {
                            attrs: { "route-id": _vm.routeId },
                            on: { routeLayout: _vm.routeLayout },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "Theme Variant" },
                      on: {
                        click: function ($event) {
                          return _vm.displayPages("themevariant")
                        },
                      },
                    },
                    [
                      _vm.display.themevariant
                        ? _c("ThemesVariant", {
                            attrs: { "route-id": _vm.routeId },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-block",
    { attrs: { title: "Photos", "header-bg": "" } },
    [
      _c(
        "b-row",
        { staticClass: "d-flex justify-content-end" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-end mb-2",
              attrs: { md: "6" },
            },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-form-select",
                    {
                      attrs: { id: "per-page-select", size: "sm" },
                      on: {
                        change: function ($event) {
                          return _vm.getData()
                        },
                      },
                      model: {
                        value: _vm.photosourcesInput,
                        callback: function ($$v) {
                          _vm.photosourcesInput = $$v
                        },
                        expression: "photosourcesInput",
                      },
                    },
                    [
                      _c("b-form-select-option", { attrs: { value: "" } }, [
                        _vm._v("\n\t\t\t\t\t\t--Select source --\n\t\t\t\t\t"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.photosources, function (photo, index) {
                        return _c(
                          "b-form-select-option",
                          { key: index, attrs: { value: photo.id } },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(photo.name) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { ref: "photosTable" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {
	Vue, Component, Ref,
} from 'vue-property-decorator';
import Swal from 'sweetalert2';
import { BModal } from 'bootstrap-vue';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import mitt from 'mitt';
import { TabEvent } from 'interfaces/app';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import { httpClient } from 'utils/http';
import * as DB from 'interfaces/database';
import { createInstance } from 'utils/vue';

const eventBus = mitt<TabEvent<DB.BadgeModel>>();
@Component({
	components: {
		TabulatorBtn,
	},
})
export default class BadgesTable extends Vue {
	@Ref('my-modal')
	private readonly modal!: BModal;

	@Ref('table')
	private readonly tableReference!: HTMLDivElement;

	private name = '';

	private isLoaded = false;

	private table?: Tabulator;

	private perPage = 20;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private isLoading = false;

	private pageOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	private beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'ID',
			},
			{
				title: 'Name',
				field: 'name',
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.BadgeModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	private mounted(): void {
		eventBus.on(
			'deleteItem',
			this.deleteBadge,
		);
		eventBus.on(
			'editItem',
			this.editBadge,
		);
		this.table = new Tabulator(
			this.tableReference,
			{
				layout: 'fitColumns',
				columns: this.columnDefs,
				ajaxURL: '/api/badge',
				ajaxParams: {
					limit: this.perPage,
				},
				pagination: true,
				paginationSize: 20,
				paginationSizeSelector: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
				paginationMode: 'remote',
				ajaxRequestFunc: (url, _config, params) => {
					const { page, size } = params;
					return httpClient.get(`${url}?limit=${size}&offset=${(page - 1) * size}`);
				},
				ajaxResponse: (_url, _params, response) => {
					const last_page = response.headers['x-total-records'];
					return {
						data: response.data,
						last_page,
					};
				},
			},
		);
	}

	private beforeDestroy(): void {
		eventBus.off(
			'deleteItem',
			this.deleteBadge,
		);
		eventBus.off(
			'editItem',
			this.editBadge,
		);
		this.table?.destroy();
	}

	protected addBadge(): void {
		// add a badge
		this.isLoaded = true;
		httpClient.post(
			'/api/badge',
			{ name: this.name },
		).then((res) => {
			this.modal.hide();

			this.table?.addData(
				[{
					id: res.data.id,
					name: res.data.name,
				}],
				true,
			);
			this.name = '';
			this.$bvToast.toast(
				'Badge added successfully',
				{
					solid: true,
					variant: 'success',
				},
			);
			this.$emit(
				'routeBadge',
				res.data.id,
			);
			return undefined;
		})
			.finally(() => {
				this.isLoaded = false;
			})
			.catch((err) => {
				this.$bvToast.toast(
					`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});
	}

	private editBadge(data: TabEvent<DB.BadgeModel>['editItem']): void {
		this.$emit(
			'routeBadge',
			data.id,
		);
	}

	private deleteBadge(data: TabEvent<DB.BadgeModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete(`/api/badge/${data.id}`).then(() => {
					this.$bvToast.toast(
						'Item Deleted',
						{
							solid: true,
							variant: 'success',
						},
					);
					this.table?.replaceData();
					return null;
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {},
    [
      _c("base-page-heading", {
        staticClass: "d-print-none",
        attrs: { title: "Model2d", subtitle: "" },
        scopedSlots: _vm._u([
          {
            key: "extra",
            fn: function () {
              return [
                _c(
                  "b-button",
                  {
                    ref: "model2dShow",
                    attrs: { type: "submit", size: "md", variant: "secondary" },
                    on: { click: _vm.showModal },
                  },
                  [
                    _c("i", { staticClass: "fa fa-fw fa-plus" }),
                    _vm._v(" \tCreate new\n\t\t\t"),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [_c("Model2dTable", { on: { routeModel2d: _vm.routeModel2d } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "div",
        { staticClass: "back-arrow pl-6 pt-2" },
        [
          _c("router-link", { attrs: { to: "/branch" } }, [
            _c("i", { staticClass: "fa fa-fw fa-arrow-left" }),
            _vm._v(" "),
            _c("span", [_vm._v(" Back to stores overview ")]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("base-page-heading", {
        staticClass: "d-print-none",
        attrs: { title: "Branch Edit", subtitle: "" },
        scopedSlots: _vm._u([
          {
            key: "extra",
            fn: function () {
              return [
                _c(
                  "b-button",
                  {
                    ref: "btnShow",
                    attrs: { type: "submit", size: "lg", variant: "secondary" },
                    on: { click: _vm.updatePickupStoresProperties },
                  },
                  [_vm._v("\n\t\t\t\tSave changes\n\t\t\t")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row content" },
        [
          _c("EditPickupStoresTable", {
            ref: "editpickupstores",
            attrs: { "route-id": _vm.routeId },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue, { VueConstructor } from 'vue';

// eslint-disable-next-line import/prefer-default-export
export function createInstance<
	Component extends VueConstructor<Vue>,
	Props extends PublicOptionalNonFunctionProps<InstanceType<Component>>,
>(
	options: {
		component: Component,
		props?: Props,
		parent?: Vue,
	},
): InstanceType<Component> {
	// eslint-disable-next-line new-cap
	return new options.component({
		propsData: options.props,
		parent: options.parent,
	});
}

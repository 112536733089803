import { Vue, Component, Prop } from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from '../../utils/http';

@Component({})
export default class SalesTable extends Vue {
	private isLoading = false;

	private editing = false;

	@Prop({
		type: Object,
	})
	salesP!: DB.PricingModel;

	@Prop()
	editedOffering!: DB.OfferingModel

	private data = {} as DB.PricingModel;

	mounted(): void {
		this.data = this.salesP;
	}

	edit(): void {
		this.editing = true;
	}

	editSalesP(): Promise<void> | void {
		const h = this.$createElement;
		this.isLoading = true;
		httpClient.put(
			`/api/price/${this.data.id}`,
			{
				price_base_from: this.data.price_base_from,
				price_base: this.data.price_base,
				price_page_from: this.data.price_page_from,
				price_page: this.data.price_page,
			},
		)
			.then(() => setTimeout(
				() => {
					this.$bvToast.toast(
						h(
							'p',
							{ class: ['text-center', 'mb-0'] },
							[
								h(
									'b-spinner',
									{ props: { type: 'grow', small: true } },
								),
								h(
									'strong',
									'Price Edited',
								),
								h(
									'b-spinner',
									{ props: { type: 'grow', small: true } },
								),
							],
						),
						{
							solid: true,
							variant: 'success',
						},
					);
				},
				2000,
			))
			.finally(() => {
				this.isLoading = false;
			})
			.catch((err) => {
				this.$bvToast.toast(
					`${err.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});
	}
}

import { Vue, Component, Ref } from 'vue-property-decorator';
import PriceInput from 'components/Tabulator/PriceInput/';
import PriceDetails from 'components/Tabulator/PriceDetails/';
import { httpClient } from 'utils/http';
import { createInstance } from 'utils/vue';
import * as DB from 'interfaces/database';
import {
	CellComponent,
	ColumnComponent,
	RowComponent,
	TabulatorFull as Tabulator,
} from 'tabulator-tables';
import debounce from 'utils/debounce';
import { formatCellsToClipboard, formatClipboardToCells } from 'utils/tabulator-copy-paste';
import {
	singleSelectCell, multiSelectCell, drawAllBorders, removeAllBorders,
} from 'utils/tabulator-select';
import { autofillStart, autofillStop } from 'utils/tabulator-autofill';

interface RowData extends DB.PricingModel {
	offeringModel: DB.OfferingModel;
	currencyModel: DB.CurrencyModel;
	thumbnail: DB.OfferingModel['thumbnail'];
	error: Record<keyof DB.PricingModel, boolean>;
	loading: Record<keyof DB.PricingModel, boolean>;
	success: Record<keyof DB.PricingModel, boolean>;
}

@Component({
	components: {
		PriceInput,
		PriceDetails,
	},
})

export default class PricingTable extends Vue {
	@Ref('tableContainer')
	private tableContainer!: HTMLDivElement;

	private searchFilters: {
		productGroup: string,
		priceType: 'all' | 'discounted' | 'missing',
	} = {
		productGroup: '0',
		priceType: 'all',
	};

	private table?: Tabulator;

	private selectedRows: Array<RowComponent> = [];

	private autofillRows: Array<RowComponent> = [];

	private selectedColumns: Array<ColumnComponent> = [];

	private autofillColumns: Array<ColumnComponent> = [];

	private selectedCell: CellComponent | null = null;

	private editingCell: CellComponent | null = null;

	private rowData: Omit<RowData, 'offeringModel' | 'currencyModel' | 'thumbnail'>[] = [];

	private currencyModels: DB.CurrencyModel[] = [];

	private offeringModels: DB.OfferingModel[] = [];

	private productGroupModels: DB.ProductGroupModel[] = [];

	private isUpdatingCells = false;

	private isDragging = false;

	private cellsToUpdate: Map<CellComponent, number> = new Map()

	private editableColumns = new Set(['price_base', 'price_base_from', 'price_page', 'price_page_from']);

	private mounted(): void {
		this.getData().then(() => {
			this.buildTable();
			return true;
		}).catch((error) => {
			throw error;
		});
		document.addEventListener(
			'keydown',
			this.handleKeyDown,
		);
		document.addEventListener(
			'copy',
			this.handleCopyEvent,
		);
		document.addEventListener(
			'paste',
			this.handlePasteEvent,
		);
	}

	// Destroy the table when the component is destroyed
	private beforeDestroy() {
		document.removeEventListener(
			'keydown',
			this.handleKeyDown,
		);
		document.removeEventListener(
			'copy',
			this.handleCopyEvent,
		);
		document.removeEventListener(
			'paste',
			this.handlePasteEvent,
		);
		this?.table?.destroy();
	}

	private handlePasteEvent(event: ClipboardEvent): void {
		const text = event.clipboardData?.getData('text/plain');
		if (text) {
			this.updateSelectedCells(formatClipboardToCells(text));
		}
	}

	private handleCopyEvent(event: ClipboardEvent): void {
		event.preventDefault();
		const text = formatCellsToClipboard(
			this.selectedRows,
			this.selectedColumns,
		);
		event.clipboardData?.setData(
			'text/plain',
			text,
		);
	}

	private handleMultiSelectEvent(event: KeyboardEvent): void {
		if (!this.selectedCell) return;

		const cellToSelect = multiSelectCell(
			event.key,
			this.selectedCell,
			this.selectedRows,
			this.selectedColumns,
			this.editableColumns,
		);

		if (cellToSelect) this.selectedCell = cellToSelect;
	}

	private handleSingleSelectEvent(event: KeyboardEvent): void {
		if (!this.selectedCell) return;
		const cellToSelect = singleSelectCell(
			event.key,
			this.selectedCell,
			this.selectedRows,
			this.selectedColumns,
			this.editableColumns,
		);

		if (cellToSelect) {
			this.selectedCell = cellToSelect;
			this.selectedRows = [cellToSelect.getRow()];
			this.selectedColumns = [cellToSelect.getColumn()];
		}
	}

	private handleKeyDown(event: KeyboardEvent): void {
		if (event.shiftKey) {
			return this.handleMultiSelectEvent(event);
		}

		if (event.key === 'Delete') {
			// Make sure the user is not editing the text for one of the header filter values
			const inFocus = document.querySelector('textarea:focus') ?? document.querySelector('input:focus');
			if (!inFocus) {
				this.updateSelectedCells(
					this.selectedRows.map(
						() => this.selectedColumns.map(() => '0'),
					),
				);
			}
		}

		return this.handleSingleSelectEvent(event);
	}

	private buildTable() {
		const groupNameFilterValues: Record<
		DB.ProductGroupModel['name'] | '',
		DB.ProductGroupModel['name'] | ''
		> = {
			'': '',
		};
		this.productGroupModels.forEach(
			(model) => {
				groupNameFilterValues[model.name] = model.name;
			},
		);

		this.table = new Tabulator(
			this.tableContainer,
			{
				data: this.rowData,
				height: '80vh',
				rowHeight: 40,
				layout: 'fitColumns',
				columns: [
					{
						title: 'currencyModel',
						field: 'currencyModel',
						visible: false,
						mutatorData: (val, data) => this.currencyModels.find(
							(model) => model.id === data.currency,
						),
					},
					{
						title: 'offeringModel',
						field: 'offeringModel',
						visible: false,
						mutatorData: (val, data) => this.offeringModels.find(
							(model) => model.id === data.offeringid,
						),
					},
					{
						title: 'Offering',
						columns: [
							{
								title: 'id',
								field: 'offeringid',
								cssClass: 'excelStyle',
								formatter: 'plaintext',
								headerFilter: 'input',
								width: 110,
							},
							{
								title: 'External id',
								field: 'offeringModel.externalid',
								cssClass: 'excelStyle',
								formatter: 'plaintext',
								headerFilter: 'input',
							},
							{
								title: 'Description',
								field: 'offeringDescription',
								formatter: 'plaintext',
								mutatorData: (val, data) => `${data.offeringModel?.name} - ${data.offeringModel?.description} - ${data.offeringModel?.size} - ${data.offeringModel?.variantname}`,
								headerFilter: 'input',
							},
							{
								title: 'Image',
								field: 'thumbnail',
								formatter: 'image',
								formatterParams: {
									height: '30px',
								},
								width: 100,
								mutatorData: (val, data) => data.offeringModel?.thumbnail,
							},
						],
					},
					{
						title: 'Currency',
						columns: [
							{
								title: 'Description',
								field: 'currencyName',
								formatter: 'plaintext',
								mutatorData: (val, data) => `${data.currencyModel?.iso} (${data.currencyModel?.name})`,
								headerFilter: 'input',
								width: 175,
							},
						],
					},
					{
						title: 'Catalogue Prices',
						width: 300,
						resizable: false,
						columns: [
							{
								title: 'Base Price',
								field: 'price_base_from',
								width: 150,
								resizable: false,
								cssClass: 'excelColumn',
								headerFilter: 'input',
								editable: () => !!this.editingCell,
								cellClick: (e, cell) => this.selectCell(cell),
								cellDblClick: (e, cell) => this.editCell(cell),
								cellMouseEnter: this.onCellMouseEnter,
								cellMouseUp: this.onCellMouseUp,
								formatter: this.catalogueFormatter,
								editor: (
									cell,
									onRendered,
									success,
								) => {
									const rowData = cell.getData() as RowData;
									const rowValue = cell.getValue();
									let caretPosition = 0;
									let saving = false;

									const instance = createInstance({
										component: PriceInput,
										props: {
											value: rowValue,
										},
										parent: this,
									});

									instance.$on(
										'change',
										(value: number) => {
											saving = true;

											if (value !== rowValue) {
												if (rowData.price_base === rowData.price_base_from) {
													const updatePromises = [
														this.updateRowDataValue(
															cell,
															'price_base',
															value,
														),
														this.updateRowDataValue(
															cell,
															'price_base_from',
															value,
														),
													];

													return Promise.all(updatePromises)
														.then(([succeedValue1, succeedValue2]) => {
															success(succeedValue1);
															success(succeedValue2);
															return null;
														})
														.catch((error) => {
															throw error;
														});
												}
												return this.updateRowDataValue(
													cell,
													'price_base_from',
													value,
												)
													.then((succeedValue) => success(succeedValue));
											}
											return undefined;
										},
									);
									instance.$on(
										'blur',
										() => {
											cell.cancelEdit();
											this.editingCell = null;
											if (!saving) {
												success(instance.value);
											}
										},
									);
									instance.$on(
										'keydown',
										(event: KeyboardEvent) => {
											const input = instance.inputElement as HTMLInputElement;

											// eslint-disable-next-line default-case
											switch (event.key) {
												case 'ArrowLeft':
													if (caretPosition > 0) {
														caretPosition -= 1;
														event.stopPropagation();
													}
													break;
												case 'ArrowRight':
													if (caretPosition < input.value.length) {
														caretPosition += 1;
														event.stopPropagation();
													}
													break;
											}
										},
									);
									instance.$mount();
									onRendered(() => {
										if (this.editingCell === cell) {
											instance.inputElement.focus();
											// set cursor position to the beginning of the input field
											instance.inputElement.setSelectionRange(
												0,
												0,
											);
										}
									});

									return (instance.$el as HTMLElement);
								},
							},
							{
								title: 'Extra Pages',
								field: 'price_page_from',
								width: 150,
								resizable: false,
								cssClass: 'excelColumn',
								headerFilter: 'input',
								editable: () => !!this.editingCell,
								cellClick: (e, cell) => this.selectCell(cell),
								cellDblClick: (e, cell) => this.editCell(cell),
								cellMouseEnter: this.onCellMouseEnter,
								cellMouseUp: this.onCellMouseUp,
								formatter: this.catalogueFormatter,
								editor: (
									cell,
									onRendered,
									success,
								) => {
									const rowValue = cell.getValue();
									const rowData = cell.getData() as RowData;
									const isReadonly = Boolean(rowData.offeringModel?.maxpages === rowData.offeringModel.minpages);
									let caretPosition = 0;
									let saving = false;

									const instance = createInstance({
										component: PriceInput,
										props: {
											value: rowValue,
											readonly: isReadonly,
										},
										parent: this,
									});
									instance.$on(
										'change',
										(value: number) => {
											saving = true;

											if (value !== rowValue) {
												if (rowData.price_page === rowData.price_page_from) {
													const updatePromises = [
														this.updateRowDataValue(
															cell,
															'price_page',
															value,
														),
														this.updateRowDataValue(
															cell,
															'price_page_from',
															value,
														),
													];

													return Promise.all(updatePromises)
														.then(([succeedValue1, succeedValue2]) => {
															success(succeedValue1);
															success(succeedValue2);
															return null;
														})
														.catch((error) => {
															throw error;
														});
												}
												return this.updateRowDataValue(
													cell,
													'price_page_from',
													value,
												);
											}
											return undefined;
										},
									);
									instance.$on(
										'blur',
										() => {
											cell.cancelEdit();
											this.editingCell = null;
											if (!saving) {
												success(instance.value);
											}
										},
									);
									instance.$on(
										'keydown',
										(event: KeyboardEvent) => {
											const input = instance.inputElement as HTMLInputElement;

											// eslint-disable-next-line default-case
											switch (event.key) {
												case 'ArrowLeft':
													if (caretPosition > 0) {
														caretPosition -= 1;
														event.stopPropagation();
													}
													break;
												case 'ArrowRight':
													if (caretPosition < input.value.length) {
														caretPosition += 1;
														event.stopPropagation();
													}
													break;
											}
										},
									);
									instance.$mount();
									onRendered(() => {
										if (this.editingCell === cell) {
											instance.inputElement.focus();
											// set cursor position to the beginning of the input field
											instance.inputElement.setSelectionRange(
												0,
												0,
											);
										}
									});

									return (instance.$el as HTMLElement);
								},
							},
						],
					},
					{
						title: 'Discount Prices',
						width: 300,
						resizable: false,
						columns: [
							{
								title: 'Base Price',
								field: 'price_base',
								width: 150,
								resizable: false,
								cssClass: 'excelColumn',
								headerFilter: 'input',
								editable: () => !!this.editingCell,
								cellClick: (e, cell) => this.selectCell(cell),
								cellDblClick: (e, cell) => this.editCell(cell),
								cellMouseEnter: this.onCellMouseEnter,
								cellMouseUp: this.onCellMouseUp,
								formatter: (cell: CellComponent) => {
									const rowData = cell.getData() as RowData;
									const field = cell.getField() as keyof DB.PricingModel;
									const sameAsCatalogueBase = Boolean(rowData.price_base_from === rowData.price_base);

									const instance = createInstance({
										component: PriceInput,
										props: {
											prefix: rowData.currencyModel?.prefix,
											sameAsCatalogueBase,
											readonly: true,
											value: cell.getValue(),
											error: rowData.error[field],
											loading: rowData.loading[field],
											success: rowData.success[field],
										},
										parent: this,
									});
									instance.$on(
										'focus',
										() => {
											this.selectCell(cell);
										},
									);
									instance.$on(
										'mousedown',
										this.onMouseDown,
									);
									instance.$mount();

									return (instance.$el as HTMLElement);
								},
								editor: (
									cell,
									onRendered,
									success,
								) => {
									const rowValue = cell.getValue();
									let caretPosition = 0;
									let saving = false;

									const instance = createInstance({
										component: PriceInput,
										props: {
											value: rowValue,
										},
										parent: this,
									});

									instance.$on(
										'change',
										(value: number) => {
											saving = true;

											if (value !== rowValue) {
												return this
													.updateRowDataValue(
														cell,
														'price_base',
														value,
													)
													.then((succeedValue) => success(succeedValue));
											}
											return undefined;
										},
									);
									instance.$on(
										'blur',
										() => {
											cell.cancelEdit();
											this.editingCell = null;
											if (!saving) {
												success(instance.value);
											}
										},
									);
									instance.$on(
										'keydown',
										(event: KeyboardEvent) => {
											const input = instance.inputElement as HTMLInputElement;

											// eslint-disable-next-line default-case
											switch (event.key) {
												case 'ArrowLeft':
													if (caretPosition > 0) {
														caretPosition -= 1;
														event.stopPropagation();
													}
													break;
												case 'ArrowRight':
													if (caretPosition < input.value.length) {
														caretPosition += 1;
														event.stopPropagation();
													}
													break;
											}
										},
									);
									instance.$mount();
									onRendered(() => {
										if (this.editingCell === cell) {
											instance.inputElement.focus();
											// set cursor position to the beginning of the input field
											instance.inputElement.setSelectionRange(
												0,
												0,
											);
										}
									});

									return (instance.$el as HTMLElement);
								},
							},
							{
								title: 'Extra Pages',
								field: 'price_page',
								width: 150,
								resizable: false,
								cssClass: 'excelColumn',
								headerFilter: 'input',
								editable: () => !!this.editingCell,
								cellClick: (e, cell) => this.selectCell(cell),
								cellDblClick: (e, cell) => this.editCell(cell),
								cellMouseEnter: this.onCellMouseEnter,
								cellMouseUp: this.onCellMouseUp,
								formatter: (cell: CellComponent) => {
									const rowData = cell.getData() as RowData;
									const field = cell.getField() as keyof DB.PricingModel;
									const sameAsCataloguePage = Boolean(rowData.price_page_from === rowData.price_page);

									const instance = createInstance({
										component: PriceInput,
										props: {
											prefix: rowData.currencyModel?.prefix,
											sameAsCataloguePage,
											readonly: true,
											value: cell.getValue(),
											error: rowData.error[field],
											loading: rowData.loading[field],
											success: rowData.success[field],
										},
										parent: this,
									});
									instance.$on(
										'focus',
										() => {
											this.selectCell(cell);
										},
									);

									instance.$on(
										'mousedown',
										this.onMouseDown,
									);
									instance.$mount();

									return (instance.$el as HTMLElement);
								},
								editor: (
									cell,
									onRendered,
									success,
								) => {
									const rowValue = cell.getValue();
									const rowData = cell.getData() as RowData;
									let caretPosition = 0;
									const isReadonly = Boolean(rowData.offeringModel?.maxpages === rowData.offeringModel.minpages);
									let saving = false;

									const instance = createInstance({
										component: PriceInput,
										props: {
											value: rowValue,
											readonly: isReadonly,
										},
										parent: this,
									});
									instance.$on(
										'change',
										(value: number) => {
											saving = true;

											if (value !== rowValue) {
												return this
													.updateRowDataValue(
														cell,
														'price_page',
														value,
													)
													.then((succeedValue) => success(succeedValue));
											}
											return undefined;
										},
									);
									instance.$on(
										'blur',
										() => {
											cell.cancelEdit();
											this.editingCell = null;
											if (!saving) {
												success(instance.value);
											}
										},
									);
									instance.$on(
										'keydown',
										(event: KeyboardEvent) => {
											const input = instance.inputElement as HTMLInputElement;

											// eslint-disable-next-line default-case
											switch (event.key) {
												case 'ArrowLeft':
													if (caretPosition > 0) {
														caretPosition -= 1;
														event.stopPropagation();
													}
													break;
												case 'ArrowRight':
													if (caretPosition < input.value.length) {
														caretPosition += 1;
														event.stopPropagation();
													}
													break;
											}
										},
									);
									instance.$mount();
									onRendered(() => {
										if (this.editingCell === cell) {
											instance.inputElement.focus();
											// set cursor position to the beginning of the input field
											instance.inputElement.setSelectionRange(
												0,
												0,
											);
										}
									});

									return (instance.$el as HTMLElement);
								},
							},
						],
					},
					{
						title: 'loading',
						field: 'loading',
						visible: false,
					},
					{
						title: 'success',
						field: 'success',
						visible: false,
					},
					{
						title: 'error',
						field: 'error',
						visible: false,
					},
				],
			},
		);
	}

	private updateCellsDebounce = debounce(
		(): Promise<void> => this.updateCells(),
		100,
	);

	private async updateCells() {
		if (this.isUpdatingCells) {
			return this.updateCellsDebounce();
		}
		this.isUpdatingCells = true;

		const currentCellsToUpdate = new Map(this.cellsToUpdate);
		this.cellsToUpdate = new Map();

		const rowDataToUpdate: Array<any> = [];

		currentCellsToUpdate.forEach((newValue, cell) => {
			const rowData = cell.getData() as RowData;
			const oldValue = cell.getValue();
			const field = cell.getField();

			if (oldValue !== newValue) {
				rowDataToUpdate.push(
					{
						[field]: newValue,
						offeringid: rowData.offeringid,
						currency: rowData.currency,
					},
				);

				if (this.table) {
					this.table.updateData([
						{
							id: rowData.id,
							loading: {
								...rowData.loading,
								[field]: true,
							},
							success: {
								...rowData.success,
								[field]: false,
							},
							error: {
								...rowData.error,
								[field]: false,
							},
						},
					]);
				}
			}
		});

		/* eslint-disable @typescript-eslint/indent */
		return httpClient
			.post(
				'api/price/import',
				rowDataToUpdate,
			)
			.then(() => currentCellsToUpdate.forEach((newValue, cell) => {
				const rowData = cell.getData() as RowData;
				const field = cell.getField();

				return this.table?.updateData([{
					id: rowData.id,
					[field]: newValue,
					loading: {
						...rowData.loading,
						[field]: false,
					},
					success: {
						...rowData.success,
						[field]: true,
					},
				}]);
			}))
			.catch(() => {
				currentCellsToUpdate.forEach((newValue, cell) => {
					const rowData = cell.getData() as RowData;
					const field = cell.getField();

					// set the error prop to true if the API call fails
					return this.table?.updateData([{
						id: rowData.id,
						loading: {
							...rowData.loading,
							[field]: false,
						},
						success: {
							...rowData.success,
							[field]: false,
						},
						error: {
							...rowData.error,
							[field]: true,
						},
					}]);
				});
			})
			.finally(() => {
				this.isUpdatingCells = false;
			});
	}

	private async updateRowDataValue<
		K extends keyof DB.PricingModel,
		T extends DB.PricingModel[K]
	>(
		cell: CellComponent,
		field: K,
		value: T,
	): Promise<T> {
		let rowData = cell.getData() as RowData;
		const oldValue = cell.getValue();
		let succeedValue: T;

		if (this.table) {
			await this.table.updateData([
				{
					id: rowData.id,
					loading: {
						...rowData.loading,
						[field]: true,
					},
					success: {
						...rowData.success,
						[field]: false,
					},
					error: {
						...rowData.error,
						[field]: false,
					},
				},
			]);
		}

		/* eslint-disable @typescript-eslint/indent */
		return httpClient
			.put<DB.PricingModel>(
				`api/price/${rowData.id}`,
				{
					[field]: value,
				},
			)
			.then((res) => {
				rowData = cell.getData() as RowData;
				succeedValue = value;

				return this.table?.updateData([{
					id: res.data.id,
					[field]: res.data[field],
					loading: {
						...rowData.loading,
						[field]: false,
					},
					success: {
						...rowData.success,
						[field]: true,
					},
				}]);
			})
			.catch(() => {
				rowData = cell.getData() as RowData;
				succeedValue = oldValue;

				// set the error prop to true if the API call fails
				return this.table?.updateData([{
					id: rowData.id,
					loading: {
						...rowData.loading,
						[field]: false,
					},
					success: {
						...rowData.success,
						[field]: false,
					},
					error: {
						...rowData.error,
						[field]: true,
					},
				}]);
			})
			.then(() => succeedValue);
	}

	private getData(): Promise<DB.PricingModel[]> {
		const pricingParameters = new URLSearchParams({
			limit: '0',
			orderby: 'offeringid, currency',
		});
		const offeringParameters = new URLSearchParams({
			limit: '0',
			fields: 'id,name,description,size,variantname,thumbnail,groupid,minpages,maxpages,externalid',
		});

		return Promise.all([
			httpClient.get<DB.OfferingModel[]>(`/api/offering?${offeringParameters}`),
			httpClient.get<DB.CurrencyModel[]>('/api/currency?limit=0'),
			httpClient.get<DB.ProductGroupModel[]>('/api/productgroup?limit=0'),
			httpClient.get<DB.PricingModel[]>(`/api/price?${pricingParameters}`),
		]).then(([
			offeringResponse,
			currencyResponse,
			productGroupResponse,
			pricingResponse,
		]) => {
			this.productGroupModels = productGroupResponse.data;
			this.currencyModels = currencyResponse.data;
			this.offeringModels = offeringResponse.data;
			this.rowData = pricingResponse.data
				.map((data) => ({
					...data,
					error: {} as Record<keyof DB.PricingModel, boolean>,
					loading: {} as Record<keyof DB.PricingModel, boolean>,
					success: {} as Record<keyof DB.PricingModel, boolean>,
				}));

			return this.rowData;
		}).catch((error: any) => {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);

			return [];
		});
	}

	private catalogueFormatter(cell: CellComponent): HTMLElement {
		const rowData = cell.getData() as RowData;
		const field = cell.getField() as keyof DB.PricingModel;

		const instance = createInstance({
			component: PriceInput,
			props: {
				prefix: rowData.currencyModel?.prefix,
				value: cell.getValue(),
				readonly: true,
				error: rowData.error[field],
				loading: rowData.loading[field],
				success: rowData.success[field],
			},
			parent: this,
		});
		instance.$on(
			'focus',
			() => {
				this.selectCell(cell);
			},
		);
		instance.$on(
			'mousedown',
			this.onMouseDown,
		);
		instance.$mount();

		return (instance.$el as HTMLElement);
	}

	private selectCell(cell: CellComponent) {
		this.deSelectCell();
		this.selectedRows = [cell.getRow()];
		this.selectedColumns = [cell.getColumn()];

		drawAllBorders(cell);
		this.selectedCell = cell;
	}

	private deSelectCell() {
		this.selectedRows.forEach((row) => {
			this.selectedColumns.forEach((col) => {
				removeAllBorders(row.getCell(col));
			});
		});
		this.selectedRows = [];
		this.selectedColumns = [];
		this.selectedCell = null;
	}

	private editCell(cell: CellComponent) {
		this.deSelectCell();
		this.editingCell = cell;
		cell.edit(true);
	}

	private async updateSelectedCells(values: string[][]) {
		let i = 0;
		this.selectedRows.forEach((row) => {
			let j = 0;
			this.selectedColumns.forEach((col) => {
				const newValue = Number(values[i][j]);
				if (Number.isNaN(newValue)) {
					return;
				}
				j += 1;
				const cell = row.getCell(col);
				const field = cell.getField();
				const rowData = cell.getData() as RowData;
				const rowComponent = cell.getRow();
				this.cellsToUpdate.set(
					cell,
					newValue,
				);
				switch (field) {
					case 'price_base_from':
						if (rowData.price_base === rowData.price_base_from) {
							this.cellsToUpdate.set(
								rowComponent.getCell('price_base'),
								newValue,
							);
						}
						break;
					case 'price_page_from':
						if (rowData.price_page === rowData.price_page_from) {
							this.cellsToUpdate.set(
								rowComponent.getCell('price_page'),
								newValue,
							);
						}
						break;
					default:
						break;
				}
			});
			i += 1;
		});

		this.updateCells();
	}

	private onMouseDown() {
		this.isDragging = true;
	}

	private onCellMouseEnter(event: UIEvent, cell: CellComponent) {
		if (!this.isDragging || !this.selectedCell) return;
		autofillStart(cell,
			this.selectedCell,
			this.selectedColumns,
			this.autofillRows);
	}

	private onCellMouseUp() {
		if (!this.isDragging || !this.selectedCell) return;
		this.isDragging = false;

		const values = this.selectedColumns.map((col) => this.selectedRows[0].getCell(col).getValue());

		this.selectedRows = autofillStop(this.selectedRows,
			this.selectedColumns,
			this.autofillRows);

		this.autofillRows = [];
		this.selectedCell = this.selectedRows[this.selectedRows.length - 1].getCell(
			this.selectedColumns[this.selectedColumns.length - 1],
		);

		this.updateSelectedCells(this.selectedRows.map(() => [...values]));
	}

	private applyFilters() {
		const groupId = parseInt(
			this.searchFilters.productGroup,
			10,
		);
		const filteredByProductGroup = (this.rowData).filter((data) => {
			if (groupId === 0) {
				return true;
			}

			const offeringModel = this.offeringModels.find(
				(o) => o.id === data.offeringid,
			);
			if (!offeringModel) {
				return false;
			}

			return offeringModel.groupid === groupId;
		});
		const filteredByPriceType = filteredByProductGroup.filter((rowData) => {
			if (this.searchFilters.priceType === 'discounted') {
				return (rowData.price_base !== null || rowData.price_page !== null)
					&& rowData.price_base_from !== rowData.price_base && rowData.price_page_from !== rowData.price_page;
			}

			if (this.searchFilters.priceType === 'missing') {
				return (rowData.price_base_from === null || rowData.price_base_from === 0)
					&& (rowData.price_page_from === null || rowData.price_page_from === 0);
			}

			return true;
		});

		this.table?.setData(filteredByPriceType);
	}
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-block",
    {
      attrs: { title: "Themes Variant Table", "header-bg": "" },
      scopedSlots: _vm._u([
        {
          key: "options",
          fn: function () {
            return [
              _c(
                "b-button",
                {
                  attrs: { type: "submit", size: "sm", variant: "primary" },
                  on: { click: _vm.toggleShowNewPage },
                },
                [_vm._v("\n\t\t\tAdd variant\n\t\t")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showNewPage,
                  expression: "showNewPage",
                },
              ],
              attrs: { lg: "12" },
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "User Input?",
                    "label-class": "font-w600",
                    "label-cols-md": "4",
                  },
                },
                [
                  _c("b-form-checkbox", {
                    attrs: {
                      switch: "",
                      size: "lg",
                      checked: _vm.isCheckedTrue,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.setChangeEvent($event)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-block text-left" },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "form-group",
                      attrs: { label: "Tag", "label-for": "block-form1-name" },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.themevariant.tag,
                          callback: function ($$v) {
                            _vm.$set(_vm.themevariant, "tag", $$v)
                          },
                          expression: "themevariant.tag",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "form-group",
                      attrs: {
                        label: "Value",
                        "label-for": "block-form1-name",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.themevariant.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.themevariant, "value", $$v)
                          },
                          expression: "themevariant.value",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "form-group",
                      attrs: {
                        label: "Label",
                        "label-for": "block-form1-name",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.themevariant.label,
                          callback: function ($$v) {
                            _vm.$set(_vm.themevariant, "label", $$v)
                          },
                          expression: "themevariant.label",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-3 mb-2",
                      on: {
                        click: function ($event) {
                          return _vm.addThemeVariant()
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\tAdd Variant\n\t\t\t\t")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { ref: "themevariantTable" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {
	Vue, Component, Ref, Prop, Watch,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { BModal } from 'bootstrap-vue';
import mitt from 'mitt';
import Swal from 'sweetalert2';
import {
	TabEvent, TranslationsResponse, LanguageOptionsModel, Translation, Translations,
} from 'interfaces/app';
import {
	CellComponent,
	CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator,
} from 'tabulator-tables';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import TabulatorImage from 'components/Tabulator/TabulatorImage';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { createInstance } from 'utils/vue';
import { namespace } from 'vuex-class';
import { getModule } from 'vuex-module-decorators';
import ProductCategoryStore from 'store/offerings/productCategory';
import { httpClient } from '../../utils/http';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.ProductCategoryModel>>();
const ProductCategory = namespace('ProductCategory');
let ProductCategoryVuexStore: ReturnType<typeof getModule<ProductCategoryStore>>;
@Component({
	components: {
		TabulatorImage,
	},
})
export default class ProductCategoriesTable extends Vue.extend(Template) {
	@Ref('addproductcategory-modal')
	private readonly modal!: BModal;

	@Ref('productCategory')
	private readonly tableReference!: HTMLDivElement;

	private name = '';

	private table?: Tabulator;

	private isLoaded = false;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private rowData: DB.ProductCategoryModel[] = [];

	private translations = {} as Record<number, Translation>;

	private language = '';

	private languageOptions: LanguageOptionsModel[] = [];

	private image = '';

	@ProductCategory.Getter
	private getProductCategories!: DB.ProductCategoryModel[]

	@Prop({
		type: Boolean,
	})
	private readonly subcategory!: boolean;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	private newLineFormatter = (cell: CellComponent) => {
		if (cell.getValue()) {
			return cell.getValue().split('\n').join('<br/>');
		}
		return '';
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				title: '',
				rowHandle: true,
				formatter: 'handle',
				headerSort: false,
				frozen: true,
				width: 40,
			},
			{
				field: 'name',
				title: 'Details',
				headerFilter: true,
			},
			{
				title: 'Header*',
				field: 'header',
				editor: 'input',
				cellEdited: this.handleCellEdit,
			},
			{
				title: 'Description*',
				field: 'description',
				formatter: this.newLineFormatter,
				editor: 'textarea',
				cellEdited: this.handleCellEdit,
			},
			{
				title: 'Image*',
				field: 'thumbnail',
				formatter: 'image',
				formatterParams: {
					height: '70px',
				},
				cellDblClick: (_, cell) => this.editImageCell(cell),
			},
			{
				title: 'Actions',
				formatter: (cell: CellComponentExtended<DB.ProductCategoryModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'edit',
									eventName: 'editItem',
									className: 'fa-edit',
								},
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	protected mounted(): void {
		ProductCategoryVuexStore = getModule(
			ProductCategoryStore,
			this.$store,
		);
		eventBus.on(
			'deleteItem',
			this.deleteProductCategory,
		);
		eventBus.on(
			'editItem',
			this.editProductCategory,
		);
		eventBus.on(
			'editImage',
			this.editImageButton,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			() => {
				this.getData();
			},
		);
		this.table?.on(
			'rowMoved',
			this.onRowDragEnd,
		);
	}

	protected beforeDestroy(): void {
		eventBus.off(
			'deleteItem',
			this.deleteProductCategory,
		);
		eventBus.off(
			'editItem',
			this.editProductCategory,
		);
		eventBus.off(
			'editImage',
			this.editImageButton,
		);
		this.table?.off(
			'rowMoved',
			this.onRowDragEnd,
		);
		this.table?.off(
			'tableBuilt',
			() => {
				this.getData();
			},
		);
		this.table?.destroy();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '100%',
				layout: 'fitColumns',
				columns: this.columnDefs,
				movableRows: true,
			},
		);
	}

	private onRowDragEnd(): void {
		try {
			const arr = this.table?.getData().map((element, index) => ({ id: element.id, serialnumber: index }));
			this?.table?.alert('Loading');
			httpClient.put<void>(
				'/api/productcategory',
				arr,
			);
			this.showToast(
				'Lister Items have been updated',
				'success',
			);
		} catch (err: any) {
			this.showToast(
				err.message,
				'danger',
			);
		} finally {
			this.table?.clearAlert();
		}
	}

	private handleCellEdit(cell: CellComponent): void {
		const row = cell.getRow().getData();
		const field = cell.getField();
		const newValue = cell.getValue();

		row[field] = newValue;

		//  Find and Update 'rowData' state
		const foundRow = this.rowData.find((r) => r.id === row.id);

		if (foundRow) {
			(foundRow as unknown as Record<string, string | number>)[field] = newValue;
		}
		this.saveUpdatedData([cell]).catch((error) => {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}

	// eslint-disable-next-line class-methods-use-this
	private formatCellsAsTranslations(cells: CellComponent[]): Record<number, Translation> {
		return cells.reduce((result, cell) => {
			const row = cell.getRow().getData();
			return {
				...result,
				[row.id]: {
					name: row.header ? row.header : '',
					lines: row.description ? row.description.split('\n') : null,
				},
			};
		},
		{});
	}

	private async saveUpdatedData(cells: CellComponent[]): Promise<void> {
		const bundle: Record<number, Translation> = this.formatCellsAsTranslations(cells);
		try {
			await httpClient.put('/api/translation',
				{
					namespace: 'productcategories',
					language: this.language,
					bundle,
				});
			this.$bvToast.toast(
				'Successfully updated the data for the table',
				{
					solid: true,
					variant: 'success',
				},
			);
		} catch (error: any) {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		}
	}

	private async fetchTranslations(language: string): Promise<TranslationsResponse> {
		const parameter = new URLSearchParams({
			ns: 'productcategories',
			lng: language,
			limit: '0',
		});

		try {
			const { data } = await httpClient.get<TranslationsResponse>(`/api/translation/multi?${parameter}`);
			return data;
		} catch (error: any) {
			this.showToast(
				`${error.message}`,
				'danger',
			);
			return Promise.reject(error);
		}
	}

	private setRowData(): void {
		this.rowData = [...this.getProductCategories.filter((i) => (this.subcategory ? i.parentid !== null : i.parentid === null))];
		// set the translations for the default language
		this.rowData = this.rowData.map((row) => {
			if (typeof this.translations?.[row.id] !== 'undefined') {
				return {
					...row,
					header: this.translations[row.id].name,
					description: this.translations[row.id].lines?.join('\n'),
				};
			}

			return row;
		});

		this.table?.setData(this.rowData);
	}

	private async getData(): Promise<void> {
		this.table?.alert('Loading');
		try {
			const languageResponse = await httpClient.get<DB.LanguageModel[]>('/api/language?limit=0');
			this.languageOptions = languageResponse.data.map((language) => ({ value: language.id, text: language.name }));
			// get the default language
			const defaultLang = languageResponse.data.find((language: DB.LanguageModel) => language.default);
			// set the language to the default language or the first language in the list
			this.language = defaultLang ? defaultLang.id : languageResponse.data[0].id;
			// get the translations for the default language
			const translationsData = await this.fetchTranslations(this.language);
			this.translations = translationsData[this.language]?.productcategories as Translations;

			const id = this.subcategory && this.$route.params.id ? Number(this.$route.params.id) : null;
			await ProductCategoryVuexStore.getProductCategoriesAction(id);
		} catch (err: any) {
			this.showToast(
				`${err.message}`,
				'danger',
			);
		} finally {
			this.table?.clearAlert();
		}
	}

	protected async translateTableData(data: string): Promise<void> {
		const translations = await this.fetchTranslations(data);
		this.translations = translations[this.language]?.productcategories as Translations;

		this.rowData = this.rowData.map((row) => {
			if (typeof this.translations?.[row.id] !== 'undefined') {
				return {
					...row,
					header: this.translations[row.id].name,
					description: this.translations[row.id].lines?.join('\n'),
				};
			}
			return row;
		});

		this.table?.updateData(this.rowData);
	}

	protected async addProductCategory(): Promise<void> {
		this.isLoaded = true;
		try {
			const response = await httpClient.post(
				'/api/productcategory',
				{ name: this.name },
			);
			this.hideModal();
			this.name = '';
			this.showToast(
				'Product Category Added',
				'success',
			);
			this.$emit(
				'routeProductCategory',
				response.data.id,
			);
		} catch (err: any) {
			this.showToast(
				`${err.message}`,
				'danger',
			);
		} finally {
			this.isLoaded = false;
		}
	}

	private hideModal(): void {
		this.modal.hide();
	}

	private showToast(message: string, variant: 'success' | 'danger'): void {
		this.$bvToast.toast(
			message,
			{ solid: true, variant },
		);
	}

	private editImage(id: DB.ProductCategoryModel['id']): void {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				// send an edit request to the API
				this.table?.alert('Loading');
				httpClient.put<DB.ProductCategoryModel>(
					`/api/productcategory/${id}`,
					{
						thumbnail: files.filesUploaded[0].url,
					},
				)
					.then((res) => {
						this.table?.updateData([res.data]);
						return null;
					}).finally(() => this.table?.clearAlert())
					.catch((err) => {
						this.showToast(
							`${err.message}`,
							'danger',
						);
					});
			},
		};
		this.$client.picker(options).open();
	}

	private editImageButton(data: TabEvent<DB.ProductCategoryModel>['editImage']): void {
		this.editImage(data.id);
	}

	private editImageCell(cell: CellComponent): void {
		const data = cell.getData() as DB.ProductCategoryModel;
		this.editImage(data.id);
	}

	private editProductCategory(data: TabEvent<DB.ProductCategoryModel>['editItem']): void {
		this.$router.push(`/productcategory/${data.id}`);
	}

	private deleteProductCategory(data: TabEvent<DB.ProductCategoryModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading');
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete<DB.ProductCategoryModel>(`/api/productcategory/${data.id}`).then(() => {
					this.showToast(
						'Item Deleted',
						'success',
					);
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.showToast(
						`${err.message}`,
						'danger',
					);
				});
			}
			return undefined;
		}).catch((err) => {
			this.showToast(
				`${err.message}`,
				'danger',
			);
		});
	}

	@Watch(
		'getProductCategories',
	)
	onProductCategoriesChange(): void {
		this.setRowData();
	}
}

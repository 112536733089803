var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.editing === false
    ? _c(
        "b-tr",
        [
          _c("b-td", { staticClass: "text-center" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.salesP.currency) + "\n\t"),
          ]),
          _vm._v(" "),
          _c(
            "b-td",
            { staticClass: "font-w600 font-size-sm" },
            [
              _c(
                "b-form-group",
                { attrs: { "label-for": "example-text-input" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: "",
                      value: _vm.data.price_base_from
                        ? parseFloat(_vm.data.price_base_from).toFixed(2)
                        : null,
                      readonly: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-td",
            { staticClass: "font-w600 font-size-sm" },
            [
              _c(
                "b-form-group",
                { attrs: { "label-for": "example-text-input" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      value: parseFloat(_vm.data.price_base).toFixed(2),
                      placeholder: "",
                      readonly: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.editedOffering.maxpages !== _vm.editedOffering.minpages
            ? _c(
                "b-td",
                { staticClass: "font-w600 font-size-sm" },
                [
                  _c(
                    "b-form-group",
                    { attrs: { "label-for": "example-text-input" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          placeholder: "",
                          value: _vm.data.price_page_from
                            ? parseFloat(_vm.data.price_page_from).toFixed(2)
                            : null,
                          readonly: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.editedOffering.maxpages !== _vm.editedOffering.minpages
            ? _c(
                "b-td",
                { staticClass: "font-w600 font-size-sm" },
                [
                  _c(
                    "b-form-group",
                    { attrs: { "label-for": "example-text-input" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          value: parseFloat(_vm.data.price_page).toFixed(2),
                          placeholder: "",
                          readonly: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-td",
            { staticClass: "text-center" },
            [
              _c(
                "b-btn-group",
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "alt-primary" },
                      on: { click: _vm.edit },
                    },
                    [_c("i", { staticClass: "fa fa-fw fa-pencil-alt" })]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "b-tr",
        [
          _c("loading-overlay", {
            attrs: { active: _vm.isLoading, "can-cancel": true },
          }),
          _vm._v(" "),
          _c("b-td", { staticClass: "text-center" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.data.currency) + "\n\t"),
          ]),
          _vm._v(" "),
          _c(
            "b-td",
            { staticClass: "font-w600 font-size-sm" },
            [
              _c(
                "b-form-group",
                { attrs: { "label-for": "example-text-input" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: "",
                      value: _vm.data.price_base_from
                        ? parseFloat(_vm.data.price_base_from).toFixed(2)
                        : null,
                    },
                    on: {
                      input: function ($event) {
                        _vm.data.price_base_from = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-td",
            { staticClass: "font-w600 font-size-sm" },
            [
              _c(
                "b-form-group",
                { attrs: { "label-for": "example-text-input" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      value: parseFloat(_vm.data.price_base).toFixed(2),
                      placeholder: "",
                    },
                    on: {
                      input: function ($event) {
                        _vm.data.price_base = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-td",
            { staticClass: "font-w600 font-size-sm" },
            [
              _c(
                "b-form-group",
                { attrs: { "label-for": "example-text-input" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      placeholder: "",
                      value: _vm.data.price_page_from
                        ? parseFloat(_vm.data.price_page_from).toFixed(2)
                        : null,
                    },
                    on: {
                      input: function ($event) {
                        _vm.data.price_page_from = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-td",
            { staticClass: "font-w600 font-size-sm" },
            [
              _c(
                "b-form-group",
                { attrs: { "label-for": "example-text-input" } },
                [
                  _c("b-form-input", {
                    attrs: {
                      value: parseFloat(_vm.data.price_page).toFixed(2),
                      placeholder: "",
                    },
                    on: {
                      input: function ($event) {
                        _vm.data.price_page = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-td",
            { staticClass: "text-center" },
            [
              _c(
                "b-btn-group",
                [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "alt-primary" },
                      on: {
                        click: function ($event) {
                          return _vm.editSalesP()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-fw fa-save" })]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoaded, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "addhyperlinks-modal",
          attrs: { id: "hyperlinks-modal", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [_vm._v("\n\t\t\tCreate New Hyperlink\n\t\t")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-block text-left" },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Language", "label-for": "example-select" } },
                [
                  _c("ValidationProvider", {
                    attrs: { rules: "required", name: "Language" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ classes, errors }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "control", class: classes },
                              [
                                _c(
                                  "b-form-select",
                                  {
                                    staticClass: "mb-2",
                                    attrs: { id: "example-select", plain: "" },
                                    model: {
                                      value: _vm.hyperLinkdata.languageid,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.hyperLinkdata,
                                          "languageid",
                                          $$v
                                        )
                                      },
                                      expression: "hyperLinkdata.languageid",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: "" } },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.language, function (lang, id) {
                                      return _c(
                                        "b-form-select-option",
                                        { key: id, attrs: { value: lang.id } },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(lang.name) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(errors[0]))]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { attrs: { label: "PDP", "label-for": "example-select" } },
                [
                  _c(
                    "b-form-select",
                    {
                      staticClass: "mb-2",
                      attrs: { id: "example-select", plain: "" },
                      model: {
                        value: _vm.hyperLinkdata.pdpid,
                        callback: function ($$v) {
                          _vm.$set(_vm.hyperLinkdata, "pdpid", $$v)
                        },
                        expression: "hyperLinkdata.pdpid",
                      },
                    },
                    [
                      _c("b-form-select-option", { attrs: { value: null } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.pdp, function (pd, id) {
                        return _c(
                          "b-form-select-option",
                          { key: id, attrs: { value: pd.id } },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(pd.name) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { attrs: { label: "Region", "label-for": "example-select" } },
                [
                  _c("ValidationProvider", {
                    attrs: { rules: "required", name: "Region" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ classes, errors }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "control", class: classes },
                              [
                                _c(
                                  "b-form-select",
                                  {
                                    staticClass: "mb-2",
                                    attrs: { id: "example-select", plain: "" },
                                    model: {
                                      value: _vm.hyperLinkdata.regionid,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.hyperLinkdata,
                                          "regionid",
                                          $$v
                                        )
                                      },
                                      expression: "hyperLinkdata.regionid",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: null } },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.region, function (reg, id) {
                                      return _c(
                                        "b-form-select-option",
                                        { key: id, attrs: { value: reg.id } },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(reg.name) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(errors[0]))]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Product Category",
                    "label-for": "example-select",
                  },
                },
                [
                  _c(
                    "b-form-select",
                    {
                      staticClass: "mb-2",
                      attrs: { id: "example-select", plain: "" },
                      model: {
                        value: _vm.hyperLinkdata.productcategoryid,
                        callback: function ($$v) {
                          _vm.$set(_vm.hyperLinkdata, "productcategoryid", $$v)
                        },
                        expression: "hyperLinkdata.productcategoryid",
                      },
                    },
                    [
                      _c("b-form-select-option", { attrs: { value: null } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\tPlease select an option\n\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.productCategory, function (prodcat, id) {
                        return _c(
                          "b-form-select-option",
                          { key: id, attrs: { value: prodcat.id } },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(prodcat.name) +
                                "\n\t\t\t\t\t"
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: { label: "Tag: ", "label-for": "block-form1-name" },
                },
                [
                  _c("ValidationProvider", {
                    attrs: { rules: "alpha_dash", name: "Tag" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ classes, errors }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "control", class: classes },
                              [
                                _c("b-form-input", {
                                  attrs: { type: "text", required: "" },
                                  model: {
                                    value: _vm.hyperLinkdata.tag,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.hyperLinkdata, "tag", $$v)
                                    },
                                    expression: "hyperLinkdata.tag",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(errors[0]))]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                {
                  staticClass: "form-group",
                  attrs: { label: "URL: ", "label-for": "block-form1-name" },
                },
                [
                  _c("b-form-input", {
                    attrs: { type: "text", required: "" },
                    model: {
                      value: _vm.hyperLinkdata.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.hyperLinkdata, "url", $$v)
                      },
                      expression: "hyperLinkdata.url",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-form-group",
                { attrs: { label: "Delegate", "label-class": "font-w600" } },
                [
                  _c("b-form-checkbox", {
                    attrs: {
                      switch: "",
                      size: "lg",
                      checked: _vm.hyperLinkdata.delegate === 1 ? true : false,
                    },
                    on: {
                      change: function ($event) {
                        _vm.hyperLinkdata.delegate = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { block: "" },
              on: {
                click: function ($event) {
                  return _vm.addHyperlinks()
                },
              },
            },
            [_vm._v("\n\t\t\tSave\n\t\t")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "base-block",
        { attrs: { title: "Existing Hyperlinks", "header-bg": "" } },
        [_c("div", { ref: "HyperLinksTable" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
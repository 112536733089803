var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "d-flex justify-content-end mb-4" },
        [
          _c(
            "b-col",
            {
              staticClass: "my-1",
              attrs: { sm: "5", md: "3", "align-self": "center" },
            },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Product Group",
                    "label-for": "search-group-select",
                    "label-cols-sm": "6",
                    "label-cols-md": "4",
                    "label-cols-lg": "4",
                    "label-align-sm": "right",
                    "label-size": "sm",
                  },
                },
                [
                  _c(
                    "b-form-select",
                    {
                      attrs: { id: "search-group-select", size: "sm" },
                      on: { change: _vm.onSelectProductGroup },
                      model: {
                        value: _vm.selectedProductGroup,
                        callback: function ($$v) {
                          _vm.selectedProductGroup = $$v
                        },
                        expression: "selectedProductGroup",
                      },
                    },
                    _vm._l(_vm.productGroupModels, function (productGroup) {
                      return _c(
                        "b-form-select-option",
                        {
                          key: productGroup.id,
                          attrs: { value: productGroup },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(productGroup.name) +
                              "\n\t\t\t\t\t"
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              staticClass: "my-1",
              attrs: { sm: "5", md: "3", "align-self": "center" },
            },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Language:",
                    "label-for": "search-group-select",
                    "label-cols-sm": "6",
                    "label-cols-md": "4",
                    "label-cols-lg": "4",
                    "label-align-sm": "right",
                    "label-size": "sm",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "search-group-select",
                      size: "sm",
                      options: _vm.languageOptions,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.translateTableData(_vm.language)
                      },
                    },
                    model: {
                      value: _vm.language,
                      callback: function ($$v) {
                        _vm.language = $$v
                      },
                      expression: "language",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("base-block", { attrs: { title: "Offerings", "header-bg": "" } }, [
        _c("div", { ref: "offeringTable" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { httpClient } from 'utils/http';
import {
	Vue, Component, Ref, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { TabEvent } from 'interfaces/app';
import { BModal } from 'bootstrap-vue';
import Swal from 'sweetalert2';
import mitt from 'mitt';
import {
	CellComponent, CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorCheckBox from 'components/Tabulator/TabulatorCheckBox';
import TabulatorBtn from 'components/Tabulator/TabulatorBtn';
import Template from './template.vue';

const eventBus = mitt<TabEvent<DB.ApplicationRouteModel>>();
@Component({
	components: {
		TabulatorBtn,
		TabulatorCheckBox,
	},
})
export default class AppSettingTable extends Vue.extend(Template) {
	@Ref('editapipermission-modal') private readonly modal!: BModal;

	@Ref('editApiPermissionsTable')
	private readonly tableReference!: HTMLDivElement;

	@Prop({
		required: true,
		type: String,
	})
	public readonly routeId!: string

	private applicationedit = {} as DB.ApplicationRouteModel;

	private isLoading = false;

	private visible = false;

	private index = 0;

	private table?: Tabulator;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'route',
				title: 'Route',
				editor: 'input',
				cellEdited: this.cellValueChanged,
				headerFilter: true,
			},
			{
				field: 'method_get',
				title: 'GET',
				formatter: (cell: CellComponentExtended<DB.ApplicationRouteModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorCheckBox,
						props: {
							data,
							eventBus,
							field: 'method_get',
							checked: Boolean(data && data.method_get),
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
			{
				field: 'method_put',
				title: 'PUT',
				formatter: (cell: CellComponentExtended<DB.ApplicationRouteModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorCheckBox,
						props: {
							data,
							eventBus,
							field: 'method_put',
							checked: Boolean(data && data.method_put),
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
			{
				field: 'method_post',
				title: 'POST',
				formatter: (cell: CellComponentExtended<DB.ApplicationRouteModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorCheckBox,
						props: {
							data,
							eventBus,
							field: 'method_post',
							checked: Boolean(data && data.method_post),
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
			{
				field: 'method_delete',
				title: 'DELETE',
				formatter: (cell: CellComponentExtended<DB.ApplicationRouteModel>) => {
					const data = cell.getData();
					const instance = createInstance({
						component: TabulatorCheckBox,
						props: {
							data,
							eventBus,
							field: 'method_delete',
							checked: Boolean(data && data.method_delete),
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
			{
				field: 'authLevel',
				title: 'Auth Level',
				editor: 'input',
				cellEdited: this.cellValueChanged,
				headerFilter: true,
			},
			{
				title: 'action',
				formatter: (cell: CellComponentExtended<DB.ApplicationRouteModel>) => {
					const instance = createInstance({
						component: TabulatorBtn,
						props: {
							data: cell.getData(),
							buttons: [
								{
									id: 'delete',
									eventName: 'deleteItem',
									className: 'fa-times',
								},
							],
							eventBus,
						},
					});
					instance.$mount();

					return (instance.$el as HTMLElement);
				},
			},
		];
	}

	protected mounted(): void {
		eventBus.on(
			'deleteItem',
			this.deleteApplicationItem,
		);
		eventBus.on(
			'checkBoxChanged',
			this.checkBoxChanged,
		);
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeDestroy(): void {
		eventBus.off(
			'deleteItem',
			this.deleteApplicationItem,
		);
		eventBus.off(
			'checkBoxChanged',
			this.checkBoxChanged,
		);
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.tableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
			where: JSON.stringify({
				applicationid: this.routeId,
			}),
		});
		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.ApplicationRouteModel[]>(`/api/applicationroute?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.table?.clearAlert();
		}
	}

	private checkBoxChanged(data: TabEvent<DB.ApplicationRouteModel>['checkBoxChanged']): void {
		this.table?.alert('Loading..');
		if (data.field) {
			httpClient.put(
				`/api/applicationroute/${data.params.id}`,
				{
					[data.field]: data.event ? 1 : 0,
				},
			)
				.then(() => {
					this.$bvToast.toast(
						'Application Table has been updated',
						{
							solid: true,
							variant: 'success',
						},
					);
					return undefined;
				})
				.finally(() => {
					this.table?.clearAlert();
				})
				.catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
	}

	protected cellValueChanged(cell: CellComponent): void {
		this.table?.alert('Loading..');
		const rowData = cell.getData() as DB.ApplicationRouteModel;
		httpClient.put<DB.ApplicationRouteModel>(
			`/api/applicationroute/${rowData.id}`,
			{
				authLevel: rowData.authLevel,
				route: rowData.route,
			},
		).then(() => {
			this.$bvToast.toast(
				'Application Table has been updated',
				{
					solid: true,
					variant: 'success',
				},
			);
			return undefined;
		}).finally(() => {
			this.table?.clearAlert();
		})
			.catch((error) => {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			});
	}

	protected saveSetting(): void {
		this.table?.alert('Loading..');
		httpClient.post<DB.ApplicationRouteModel[]>(
			'/api/applicationroute',
			{
				...this.applicationedit,
				applicationid: this.routeId,
			},
		).then((res) => {
			this.modal.hide();
			// add data to the ag-grid
			this.table?.addData(
				res.data,
				true,
			);
			this.$bvToast.toast(
				'New Data has been added',
				{
					solid: true,
					variant: 'success',
				},
			);
			return undefined;
		}).finally(() => {
			this.table?.clearAlert();
		}).catch((error) => {
			this.$bvToast.toast(
				`${error.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}

	private deleteApplicationItem(data: TabEvent<DB.ApplicationRouteModel>['deleteItem']): void {
		Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			customClass: {
				confirmButton: 'btn btn-danger m-1',
				cancelButton: 'btn btn-secondary m-1',
			},
			confirmButtonText: 'Yes, delete it!',
			html: false,
		}).then((result) => {
			if (result.value) {
				this.table?.alert('Loading..');
				// eslint-disable-next-line promise/no-nesting
				httpClient.delete(`/api/applicationroute/${data.id}`).then(() => {
					this.$bvToast.toast(
						'Item Deleted',
						{
							solid: true,
							variant: 'success',
						},
					);
					this.table?.deleteRow(data.id);
					return null;
				}).finally(() => {
					this.table?.clearAlert();
				}).catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
			}
			return undefined;
		}).catch((err) => {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		});
	}
}

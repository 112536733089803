import {
	Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { PickerOptions, PickerResponse } from 'filestack-js';
import { httpClient } from 'utils/http';
import Template from './template.vue';

@Component({})
export default class Frame extends Vue.extend(Template) {
	@Prop({
		type: String,
		required: true,
	})
	public readonly routeId!: string;

	private imagePreview = '';

	private frame = {} as DB.OfferingFrameModel;

	private frameDataExist = false;

	private placeHolders = {
		thumbnailPlaceholder: 'No file chosen',
	}

	private isHidden = false;

	private isLoading = false;

	private spinner = false;

	protected mounted(): void {
		this.getFrameData();
	}

	// getter for authenticated user
	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	@Watch('loggedIn')
	private async getFrameData(): Promise<void> {
		if (this.loggedIn) {
			this.spinner = true;
			try {
				const response = await httpClient.get<DB.OfferingFrameModel>(`/api/offering/${this.routeId}/frame`);
				this.frame = response.data;
				this.imagePreview = response.data.url;
				if (Object.keys(this.frame).length !== 0) {
					this.isHidden = true;
					this.frameDataExist = true;
				}
			} catch (error: any) {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			} finally {
				this.spinner = false;
			}
		}
	}

	private addFrameData(): void {
		const h = this.$createElement;
		if (this.loggedIn) {
			this.isLoading = true;
			// Add  Frame Data
			httpClient.post<DB.OfferingFrameModel>(
				'/api/offeringframe',
				{
					...this.frame,
					url: this.imagePreview,
					offeringid: this.routeId,
				},
			)
				.then((res) => {
				// Custom toast with spinner
					this.frame = res.data;
					setTimeout(
						() => {
							this.$bvToast.toast(
								h(
									'p',
									{ class: ['text-center', 'mb-0'] },
									[
										h(
											'b-spinner',
											{ props: { type: 'grow', small: true } },
										),
										h(
											'strong',
											'Frame Added Successfully',
										),
										h(
											'b-spinner',
											{ props: { type: 'grow', small: true } },
										),
									],
								),
								{
									solid: true,
									variant: 'success',
								},
							);
						},
						2000,
					);
					return undefined;
				})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
	}

	private editFrameData(): void {
		const h = this.$createElement;
		if (this.loggedIn) {
			this.isLoading = true;
			// Add  Frame Data
			httpClient.put<DB.OfferingFrameModel>(
				`/api/offeringframe/${this.frame.id}`,
				{
					...this.frame,
					url: this.imagePreview,
					offeringid: this.routeId,

				},
			)
				.then((res) => {
				// Custom toast with spinner
					this.frame = res.data;
					setTimeout(
						() => {
							this.$bvToast.toast(
								h(
									'p',
									{ class: ['text-center', 'mb-0'] },
									[
										h(
											'b-spinner',
											{ props: { type: 'grow', small: true } },
										),
										h(
											'strong',
											'Frame Added Successfully',
										),
										h(
											'b-spinner',
											{ props: { type: 'grow', small: true } },
										),
									],
								),
								{
									solid: true,
									variant: 'success',
								},
							);
						},
						2000,
					);
					return undefined;
				})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
	}

	protected handleClick(): void {
		if (this.frameDataExist) {
			this.editFrameData();
		} else {
			this.addFrameData();
		}
	}

	protected async updateImage(): Promise<void> {
		const options: PickerOptions = {
			fromSources: ['local_file_system'],
			onUploadDone: async (files: PickerResponse) => {
				this.imagePreview = files.filesUploaded[0].url;
				this.placeHolders.thumbnailPlaceholder = files.filesUploaded[0].filename;
			},
		};
		this.$client.picker(options).open();
	}

	protected removeImage(): void {
		this.imagePreview = '';
		this.placeHolders.thumbnailPlaceholder = '';
	}
}

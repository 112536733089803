var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ml-5", staticStyle: { width: "95%" } },
    [
      _c("loading-overlay", {
        attrs: { active: _vm.isLoading, "can-cancel": true },
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "value-modal",
          attrs: { id: "bv-modal", "hide-footer": "" },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [_vm._v("\n\t\t\tCreate New Offering Option\n\t\t")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm.spinner
            ? _c("div", { staticClass: "text-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "spinner-grow text-primary m-5",
                    attrs: { role: "status" },
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                ),
              ])
            : _c(
                "div",
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "form-group",
                      attrs: {
                        label: "value ",
                        "label-for": "block-form1-name",
                        "label-cols-lg": "3",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "control" },
                        [
                          _c("b-form-input", {
                            ref: "optionValue",
                            attrs: { type: "text" },
                            model: {
                              value: _vm.optionValue,
                              callback: function ($$v) {
                                _vm.optionValue = $$v
                              },
                              expression: "optionValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-3",
                      attrs: { block: "" },
                      on: { click: _vm.addOptionValue },
                    },
                    [_vm._v("\n\t\t\t\tSave\n\t\t\t")]
                  ),
                ],
                1
              ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-row",
        {
          staticClass: "d-flex justify-content-end mb-4 ml-5",
          staticStyle: { width: "95%" },
        },
        [
          _c(
            "b-col",
            {
              staticClass: "my-1",
              attrs: { sm: "5", md: "3", "align-self": "center" },
            },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: {
                    label: "Language:",
                    "label-for": "search-group-select",
                    "label-cols-sm": "6",
                    "label-cols-md": "4",
                    "label-cols-lg": "4",
                    "label-align-sm": "right",
                    "label-size": "sm",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "search-group-select",
                      size: "sm",
                      options: _vm.languageOptions,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.translateTableData(_vm.language)
                      },
                    },
                    model: {
                      value: _vm.language,
                      callback: function ($$v) {
                        _vm.language = $$v
                      },
                      expression: "language",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "ml-5", staticStyle: { width: "95%" } },
        [
          _c(
            "b-col",
            { attrs: { md: "5", lg: "5", sm: "12" } },
            [
              _c(
                "base-block",
                {
                  attrs: {
                    title: "Offering Option properties",
                    "header-bg": "",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-block text-center" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Name: ",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "control" },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.offeringOption.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.offeringOption, "name", $$v)
                                  },
                                  expression: "offeringOption.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Description: ",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "control" },
                            [
                              _c("b-form-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.offeringOption.description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.offeringOption,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "offeringOption.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "form-group",
                          attrs: {
                            label: "Serial number",
                            "label-for": "block-form1-name",
                            "label-cols-lg": "4",
                          },
                        },
                        [
                          _c("ValidationProvider", {
                            attrs: { rules: "integer", name: "Serial number" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ classes, errors }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control",
                                        class: classes,
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: { type: "text" },
                                          model: {
                                            value:
                                              _vm.offeringOption.serialnumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.offeringOption,
                                                "serialnumber",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "offeringOption.serialnumber",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v(_vm._s(errors[0]))]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Show during edit",
                            "label-class": "font-w600",
                            "label-cols-lg": "3",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "pubCheckbox",
                            attrs: {
                              switch: "",
                              size: "lg",
                              checked:
                                _vm.offeringOption.showduringedit === 1
                                  ? true
                                  : false,
                            },
                            on: {
                              change: function ($event) {
                                _vm.offeringOption.showduringedit = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Show after edit",
                            "label-class": "font-w600",
                            "label-cols-lg": "3",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "pubCheckbox",
                            attrs: {
                              switch: "",
                              size: "lg",
                              checked:
                                _vm.offeringOption.showafteredit === 1
                                  ? true
                                  : false,
                            },
                            on: {
                              change: function ($event) {
                                _vm.offeringOption.showafteredit = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Show PDP",
                            "label-class": "font-w600",
                            "label-cols-lg": "3",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "pubCheckbox",
                            attrs: {
                              switch: "",
                              size: "lg",
                              checked:
                                _vm.offeringOption.showpdp === 1 ? true : false,
                            },
                            on: {
                              change: function ($event) {
                                _vm.offeringOption.showpdp = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Production",
                            "label-class": "font-w600",
                            "label-cols-lg": "3",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "pubCheckbox",
                            attrs: {
                              switch: "",
                              size: "lg",
                              checked:
                                _vm.offeringOption.production === 1
                                  ? true
                                  : false,
                            },
                            on: {
                              change: function ($event) {
                                _vm.offeringOption.production = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { md: "7", lg: "7", sm: "12" } },
            [
              _c(
                "base-block",
                { attrs: { title: "Offering Option Values", "header-bg": "" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex justify-content-end mb-2",
                          attrs: { md: "6" },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: { to: "#" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.showValueModal.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("\n\t\t\t\t\t\t\tAdd Value\n\t\t\t\t\t\t")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { ref: "offeringOptionValue" }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {
	Vue, Component, Ref,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { httpClient, creatorDomain } from 'utils/http';
import { CellComponentExtended, ColumnDefinitionExtended, TabulatorFull as Tabulator } from 'tabulator-tables';
import { createInstance } from 'utils/vue';
import TabulatorImage from 'components/Tabulator/TabulatorImage';
import Template from './template.vue';

@Component({
	components: {},
})
export default class Products extends Vue.extend(Template) {
	@Ref('productsTable')
	private allTableReference!: HTMLDivElement;

	private products: DB.ProductModel[] | Record<string, DB.ProductModel[]> = [];

	private isLoading = false;

	private index = 0;

	private visible = false;

	private domain = creatorDomain;

	private columnDefs: ColumnDefinitionExtended[] = [];

	private table?: Tabulator;

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected beforeMount(): void {
		this.columnDefs = [
			{
				field: 'id',
				title: 'Product ID',
				formatter: (cell) => {
					const cellValue = cell.getValue();
					const rowData = cell.getData();
					const link = document.createElement('a');
					link.href = `${this.domain}/app/showcase/${cellValue}/${rowData.read_token}`;
					const icon = document.createElement('i');
					icon.classList.add(
						'fas',
						'fa-external-link-alt',
					);
					link.appendChild(icon);
					link.innerText = cellValue;
					link.addEventListener(
						'click',
						(e) => {
							e.preventDefault();
							window.open(
								`${this.domain}/app/showcase/${cellValue}/${rowData.read_token}`,
								'_blank',
							);
						},
					);
					return link;
				},
			},
			{
				title: 'Preview',
				field: 'thumbnail',
				formatter: (cell: CellComponentExtended<DB.ProductModel>) => {
					const rowData = cell.getData();
					if (rowData.read_token) {
						const link = document.createElement('a');
						link.setAttribute(
							'target',
							'_blank',
						);
						link.href = `${this.domain}/app/showcase/${rowData.id}/${rowData.read_token}`;
						const icon = document.createElement('i');
						icon.classList.add(
							'fas',
							'fa-external-link-alt',
						);
						link.appendChild(icon);
						link.innerText = 'Open showcase';
						link.addEventListener(
							'click',
							(e) => {
								e.preventDefault();
								window.open(
									`${this.domain}/app/showcase/${rowData.id}/${rowData.read_token}`,
									'_blank',
								);
							},
						);
						return link;
					}
					const instance = createInstance({
						component: TabulatorImage,
						props: {
							size: 100,
							url: cell.getValue(),
							data: rowData,
						},
					});
					instance.$mount();
					return (instance.$el as HTMLElement);
				},
			},
			{
				title: 'Created Time',
				field: 'created_time',
			},
			{
				title: 'Deleted Time',
				field: 'deleted',
			},
		];
	}

	protected mounted(): void {
		this.tableInitialization();
		this.table?.on(
			'tableBuilt',
			this.onTableBuilt,
		);
	}

	protected beforeDestroy() {
		this.table?.off(
			'tableBuilt',
			this.onTableBuilt,
		);
		this?.table?.destroy();
	}

	private onTableBuilt(): void {
		this.getData();
	}

	private tableInitialization(): void {
		this.table = new Tabulator(
			this.allTableReference,
			{
				height: '60vh',
				layout: 'fitColumns',
				columns: this.columnDefs,
			},
		);
	}

	private async getData(): Promise<void> {
		const parameter = new URLSearchParams({
			limit: '0',
		});

		this.table?.alert('Loading');
		try {
			const { data } = await httpClient.get<DB.ProductModel[]>(`/api/product?${parameter}`);
			this.table?.setData(data);
		} catch (err: any) {
			this.$bvToast.toast(
				`${err.message}`,
				{
					solid: true,
					variant: 'danger',
				},
			);
		} finally {
			this.table?.clearAlert();
		}
	}
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data
    ? _c("div", { staticClass: "container" }, [
        _c("span", [_vm._v(_vm._s(_vm.data.externalid))]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.data.name))]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
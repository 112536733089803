import { Vue, Component } from 'vue-property-decorator';
import Model3dTable from 'components/Model3dTable/template.vue';

@Component({
	components: {
		Model3dTable,
	},
})
export default class Model3d extends Vue {
	showModal(): void {
		this.$root.$emit(
			'bv::show::modal',
			'model3d-modal',
			'#model3dShow',
		);
	}

	routeModel3d(id: string): void {
		this.$router.push(`/model3d/${id}`);
	}
}

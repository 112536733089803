var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bg-image",
      class: _vm.classContainer,
      style: _vm.image ? `background-image: url('${_vm.image}');` : false,
    },
    [
      _vm.innerClass
        ? _c("div", { class: _vm.classContainerInner }, [_vm._t("default")], 2)
        : _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {
	Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { httpClient } from 'utils/http';
import { getModule } from 'vuex-module-decorators';
import RegionsStore from 'store/offerings/region';
import * as DB from 'interfaces/database';

@Component({})
export default class RegionRow extends Vue {
	private data = {} as Record<number, DB.RegionOfferingModel>;

	private isLoading = false;

	private isActive = false;

	private selected = false;

	private vat = '';

	@Prop()
	reg!: DB.RegionModel;

	@Prop()
	checkregion!: Record<number, DB.RegionOfferingModel>;

	@Prop()
	edited!: DB.OfferingModel;

	mounted(): void {
		getModule(
			RegionsStore,
			this.$store,
		);
	}

	// watch the filteredRegion and set selected to true if the filtered region is true
	@Watch(
		'checkregion',
		{ immediate: true, deep: true },
	)
	checkRegionAndSwitch(): void {
		if (this.checkregion[this.reg.id]) {
			// set the switch box to checked
			this.selected = true;

			this.data[this.reg.id] = this.checkregion[this.reg.id];
			// make the Vat input visible
			this.isActive = true;
		} else {
			// uncheck the switch box
			this.selected = false;
			// hide the vat input
			this.isActive = false;
		}
	}

	editRegions(reg: DB.RegionModel): void {
		// Check if the switch box is checked
		if (this.selected) {
			this.isActive = true;
			this.isLoading = true;
			// Set this region to be available
			httpClient
				.post(
					'/api/regionoffering',
					{
						offeringid: this.edited.id,
						regionid: reg.id,
						typeid: this.edited.typeid,
						groupid: this.edited.groupid,
					},
				)
				.then((response) => {
					this.data[response.data.regionid] = response.data;
					this.$store.commit(
						'Regions/regionId',
						reg.id,
					);
					this.$store.commit('Regions/removeRegionCurrency');
					return undefined;
				})
				.then(() => {
					this.$store.dispatch('Regions/getRegionCurrency',
						this.$route.params.id);
					return undefined;
				})
				.finally(() => {
					this.isLoading = false;
				})
				.catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		} else {
			this.isActive = false;
			this.isLoading = true;
			// if a user unchecks it, send a delete request and remove it from list of available regions
			httpClient
				.delete(`/api/regionoffering/${this.data[reg.id].id}`)
				.then(() => {
					this.$store.commit(
						'Regions/removeRegionId',
						this.reg.id,
					);
					this.$store.commit('Regions/removeRegionCurrency');
					this.$store.commit('Regions/removeRegionCurrencySales');
					return undefined;
				})
				.then(() => {
					this.$nextTick(() => {
						this.$store.dispatch('Regions/getRegionCurrency',
							this.$route.params.id);
					});
					return undefined;
				}).finally(() => {
					this.isLoading = false;
				})
				.catch((err) => {
					this.$bvToast.toast(
						`${err.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
	}
}

import {
	Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import * as DB from 'interfaces/database';
import { httpClient } from 'utils/http';
import { TabEvent } from 'interfaces/app';
import Template from './template.vue';
import TemplatePosition from '../TemplatePosition';

@Component({
	components: {
		TemplatePosition,
	},
})
export default class EditTemplatePositionEdit extends Vue.extend(Template) {
	private singleTemplatePosition = {} as DB.TemplatePositionPhotoModel | DB.TemplatePositionTextModel;

	private loaded = false;

	private visible = false;

	private spinner = false;

	private index = 0;

	private rowHeight = 60;

	private isLoading = false;

	private borderImage: DB.BorderImageModel[] = [];

	private font: DB.FontModel[] = [];

	private options = ['photo', 'text'];

	private fillMethod = ['cover', 'contain'];

	private mask = ['circle', 'heart', 'diamond', 'brush', 'cloud', 'star', 'flower', 'stamp', 'octagon', 'clover']

	@Prop({
		type: String,
		required: true,
	})
	public readonly templatePositionId!: string

	private get loggedIn(): boolean {
		return this.$auth.isAuthenticated;
	}

	protected get isPhotoRoute(): boolean {
		return this.$route.query.type === 'photo';
	}

	protected mounted(): void {
		this.getSingleTemplate();
		this.getData();
	}

	@Watch('loggedIn')
	private async getSingleTemplate(): Promise<DB.TemplatePositionPhotoModel | DB.TemplatePositionTextModel | undefined> {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			const url = this.$route.query.type === 'photo' ? 'templatepositionphoto' : 'templatepositiontext';
			try {
				const resp = await httpClient.get<DB.TemplatePositionPhotoModel | DB.TemplatePositionTextModel>(`/api/${url}/${this.$route.params.positionid}`);
				this.singleTemplatePosition = resp.data;
				// eslint-disable-next-line consistent-return
				return this.singleTemplatePosition;
			} catch (error: any) {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			} finally {
				this.isLoading = false;
			}
		}
		return undefined;
	}

	@Watch('loggedIn')
	private getData(): void {
		if (this.loggedIn) {
			// set the loading state
			this.isLoading = true;
			const parameter = new URLSearchParams({
				limit: '0',
			});
			Promise
				.all([
					httpClient.get<DB.BorderImageModel[]>(`/api/borderimage?${parameter}`),
					httpClient.get<DB.FontModel[]>('/api/font?limit=0'),
				])
				.then(([borderResponse, fontResponse]) => {
					this.borderImage = borderResponse.data;
					this.font = fontResponse.data;
					return null;
				}).catch((error) => {
					this.$bvToast.toast(
						`${error.message}`,
						{
							solid: true,
							variant: 'danger',
						},
					);
				});
		}
		return undefined;
	}

	protected async editTemplate(): Promise<DB.TemplatePositionPhotoModel | DB.TemplatePositionTextModel | null> {
		if (this.loggedIn) {
			try {
				this.isLoading = true;
				const url = this.$route.query.type === 'photo' ? 'templatepositionphoto' : 'templatepositiontext';
				const { data } = await httpClient.put<DB.TemplatePositionPhotoModel | DB.TemplatePositionTextModel>(
					`/api/${url}/${this.templatePositionId}`,
					{ ...this.singleTemplatePosition },
				);
				this.$bvToast.toast(
					'Template Position Edited successfully',
					{
						solid: true,
						variant: 'success',
					},
				);
				return data;
			} catch (error: any) {
				this.$bvToast.toast(
					`${error.message}`,
					{
						solid: true,
						variant: 'danger',
					},
				);
			} finally {
				this.isLoading = false;
			}
		}
		return Promise.resolve(null);
	}

	protected routeTemplateState(data: TabEvent<DB.TemplateModel>['editItem']): void {
		this.$emit(
			'routeTemplateState',
			{
				data,
			},
		);
	}
}

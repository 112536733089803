var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { attrs: { id: "page-header" } },
    [
      _vm._t("default", function () {
        return [
          _c("div", { staticClass: "content-header" }, [
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c(
                  "base-layout-modifier",
                  {
                    staticClass: "mr-2 d-lg-none",
                    attrs: {
                      action: "sidebarToggle",
                      size: "sm",
                      variant: "dual",
                    },
                  },
                  [_c("i", { staticClass: "fa fa-fw fa-bars" })]
                ),
                _vm._v(" "),
                _c(
                  "base-layout-modifier",
                  {
                    staticClass: "mr-2 d-none d-lg-inline-block",
                    attrs: {
                      action: "sidebarMiniToggle",
                      size: "sm",
                      variant: "dual",
                    },
                  },
                  [_c("i", { staticClass: "fa fa-fw fa-ellipsis-v" })]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c(
                  "b-dropdown",
                  {
                    ref: "oneDropdownDefaultUser",
                    staticClass: "d-inline-block ml-2",
                    attrs: {
                      size: "sm",
                      variant: "dual",
                      "menu-class": "p-0 border-0 dropdown-menu-md",
                      right: "",
                      "no-caret": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center" },
                              [
                                _c("img", {
                                  staticClass: "rounded-circle",
                                  staticStyle: { width: "21px" },
                                  attrs: {
                                    src: _vm.$auth.user.picture,
                                    alt: "Header Avatar",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "d-none d-sm-inline-block ml-2",
                                  },
                                  [_vm._v(_vm._s(_vm.$auth.user.name))]
                                ),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass:
                                    "fa fa-fw fa-angle-down d-none d-sm-inline-block ml-1 mt-1",
                                }),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$refs.oneDropdownDefaultUser.hide(true)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "p-3 text-center bg-primary-dark rounded-top",
                          },
                          [
                            _c("img", {
                              staticClass:
                                "img-avatar img-avatar48 img-avatar-thumb",
                              attrs: {
                                src: _vm.$auth.user.picture,
                                alt: "Avatar",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "p",
                              { staticClass: "mt-2 mb-0 text-white font-w500" },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.$auth.user.name) +
                                    "\n\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "p-2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "dropdown-item d-flex align-items-center justify-content-between",
                              staticStyle: { cursor: "pointer" },
                              on: { click: _vm.logout },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "font-size-sm font-w500" },
                                [_vm._v("Log Out")]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "overlay-header bg-white",
              class: { show: _vm.$store.state.settings.headerSearch },
              attrs: { id: "page-header-search" },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return (() =>
                    _vm.$store.commit("headerSearch", { mode: "off" })).apply(
                    null,
                    arguments
                  )
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "content-header" },
                [
                  _c(
                    "b-form",
                    {
                      staticClass: "w-100",
                      on: {
                        submit: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.onSubmit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c(
                            "b-input-group-prepend",
                            [
                              _c(
                                "base-layout-modifier",
                                {
                                  attrs: {
                                    action: "headerSearchOff",
                                    variant: "alt-danger",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-fw fa-times-circle",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("b-form-input", {
                            attrs: { placeholder: "Search or hit ESC.." },
                            model: {
                              value: _vm.baseSearchTerm,
                              callback: function ($$v) {
                                _vm.baseSearchTerm = $$v
                              },
                              expression: "baseSearchTerm",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "overlay-header bg-white",
              class: { show: _vm.$store.state.settings.headerLoader },
              attrs: { id: "page-header-loader" },
            },
            [_vm._m(0)]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content-header" }, [
      _c("div", { staticClass: "w-100 text-center" }, [
        _c("i", { staticClass: "fa fa-fw fa-circle-notch fa-spin" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
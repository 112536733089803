var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ml-5 mt-4", staticStyle: { width: "95%" } },
    [
      _c(
        "base-block",
        { attrs: { title: "Order Info", "header-bg": "" } },
        [
          _vm.spinner
            ? _c("div", { staticClass: "text-center" }, [
                _c("div", {
                  staticClass: "spinner-grow text-primary m-5",
                  attrs: { role: "status" },
                }),
              ])
            : _c(
                "b-table-simple",
                { attrs: { responsive: "", "table-class": "table-vcenter" } },
                [
                  _c("b-thead", { attrs: { "head-variant": "light" } }),
                  _vm._v(" "),
                  _c(
                    "b-tbody",
                    [
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Order Item ID")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(" " + _vm._s(_vm.orderItem.id) + " "),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" External ID")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.orderItem.externalOrderId) +
                                "\n\t\t\t\t\t"
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Order ID")]),
                          _vm._v(" "),
                          _c(
                            "b-td",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "showOrder",
                                      params: {
                                        orderid: _vm.orderItem.orderid,
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.orderItem.orderid) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Offering ID")]),
                          _vm._v(" "),
                          _c(
                            "b-td",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "editOffering",
                                      params: { id: _vm.orderItem.offeringid },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.orderItem.offeringid) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Product ID")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href:
                                    _vm.domain +
                                    "/app/showcase/" +
                                    _vm.orderItem.productid +
                                    "/" +
                                    _vm.orderItem.read_token,
                                },
                              },
                              [_vm._v(_vm._s(_vm.orderItem.productid) + " ")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Offering name")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.offeringData.name) +
                                " " +
                                _vm._s(_vm.offeringData.description) +
                                " ( " +
                                _vm._s(_vm.offeringData.variantname) +
                                " ) "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Number of pages")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(" " + _vm._s(_vm.orderItem.pages) + " "),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Order quantity")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(" " + _vm._s(_vm.orderItem.quantity) + " "),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Shipping Price")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orderItem.price_shipping) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Handling Price")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orderItem.price_handling) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Product Price")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orderItem.price_product) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Voucher discount")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orderItem.discount_voucher) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Total Price")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orderItem.salesvalue) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Product VAT")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(" " + _vm._s(_vm.orderItem.vat) + " "),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Tracker ID")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _vm._v(
                              " " + _vm._s(_vm.orderItem.affiliateid) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-tr",
                        [
                          _c("b-td", [_vm._v(" Download Printfile")]),
                          _vm._v(" "),
                          _c("b-td", [
                            _c("i", { staticClass: "fa fa-fw fa-download" }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "base-block",
        { attrs: { title: "Orderitem Status Info", "header-bg": "" } },
        [_c("div", { ref: "orderitemStatusTable" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }